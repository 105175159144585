/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useCallback, useEffect, useState } from 'react';

import { nextTrpc, trpc } from '~/trpc';

const useMergeIntegrationPopup = (
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [linkToken, setLinkToken] = useState<string | null>(null);
  // Returning this value to the caller to monitor the state of the popup on it
  // and reload the connected integrations list accordingly.
  // We're using this instead of invalidate because the listMergeAccounts query
  // is wrapped in a useEffect to prevent it from being called on every render
  // (a known bug) and invalidate doesn't trigger a re-render due to that.

  const getMergeIntegrationToken = async (
    category: string,
    integration: string
  ) => {
    if (!category || !integration) {
      console.error(
        'Failed to get merge integration token: missing category or integration'
      );
      return;
    }
    const res = await trpc.integrations.getMergeLinkTokenByIntegration.query({
      category,
      integration
    });
    setLinkToken(res.token);
  };

  const putMergePublicTokenMutation =
    nextTrpc.integrations.putMergePublicToken.useMutation();

  const onMergeLinkSuccess = useCallback(
    async (publicToken: string) => {
      // Send public_token to server (Step 3)
      await putMergePublicTokenMutation.mutateAsync({
        publicToken
      });
      setLinkToken(null);
      setRefresh(false);
    },
    [putMergePublicTokenMutation, setRefresh]
  );

  const { open, isReady } = useMergeLink({
    linkToken: linkToken ?? '',
    onExit: () => {
      setLinkToken(null);
      setRefresh(false);
    },
    onSuccess: onMergeLinkSuccess
  });

  useEffect(() => {
    if (isReady && linkToken !== null) {
      open();
      setRefresh(true);
    }
  }, [isReady, open, linkToken, setRefresh]);

  const openMergePopUp = async (category: string, integration: string) => {
    setLinkToken(null);
    await getMergeIntegrationToken(category, integration);
  };

  return { openMergePopUp };
};

export default useMergeIntegrationPopup;
