import { v4 as uuid } from 'uuid';

import {
  Integration,
  TicketingPriority,
  TicketingStatus
} from '../../constants';

import {
  ContentWorkflowInputPayload,
  TextWorkflowInputPayload,
  TicketIntegrationInputPayload
} from './workflowInputPayload.types';

export const MOCK_TEXT_WORKFLOW_INPUT_PAYLOAD = {
  text: 'mock-text'
} as const satisfies TextWorkflowInputPayload;
export const MOCK_CONTENT_WORKFLOW_INPUT_PAYLOAD = {
  contentId: uuid()
} as const satisfies ContentWorkflowInputPayload;

export const MOCK_TICKETING_INTEGRATION_INPUT_PAYLOAD = {
  integrationData: {
    assignees: [
      {
        email: 'assignee@example.com',
        id: 'assignee-id',
        name: 'Assignee Name'
      }
    ],
    creator: {
      email: 'creator@example.com',
      id: 'creator-id',
      name: 'Creator Name'
    },
    description: 'Mock ticket description',
    integrationName: Integration.SERVICENOW,
    title: 'Mock Ticket Title',
    url: 'https://example.com/mock-ticket',
    mergeId: 'merge-id',
    priority: TicketingPriority.HIGH,
    status: TicketingStatus.OPEN,
    completed_at: new Date().toISOString(),
    created_at: new Date().toISOString(),
    due_date: new Date().toISOString(),
    modified_at: new Date().toISOString(),
    ticketId: 'mock-ticket-id'
  }
} as const satisfies TicketIntegrationInputPayload;

export const MOCK_SERVICE_NOW_INTEGRATION_INPUT_PAYLOAD = {
  integrationData: {
    assignees: [
      {
        email: 'service-now-mock-assignee@example.com',
        id: 'service-now-mock-assignee-id',
        name: 'Service Now Mock Assignee Name'
      }
    ],
    creator: {
      email: 'service-now-mock-creator@example.com',
      id: 'service-now-mock-creator-id',
      name: 'Service Now Mock Creator Name'
    },
    description: 'Service Now Mock ticket description',
    title: 'Service Now Mock Ticket Title',
    url: 'https://example.com/service-now-mock-ticket',
    integrationName: Integration.SERVICENOW,
    mergeId: 'service-now-mock-merge-id',
    status: TicketingStatus.OPEN,
    priority: TicketingPriority.HIGH,
    created_at: null,
    completed_at: null,
    due_date: null,
    modified_at: null,
    ticketId: 'service-now-mock-ticket-id'
  }
};

export const MOCK_LINEAR_INTEGRATION_INPUT_PAYLOAD = {
  integrationData: {
    assignees: [
      {
        email: 'linear-mock-assignee@example.com',
        id: 'linear-mock-assignee-id',
        name: 'Linear Mock Assignee Name'
      }
    ],
    creator: {
      email: 'linear-mock-creator@example.com',
      id: 'linear-mock-creator-id',
      name: 'Linear Mock Creator Name'
    },
    description: 'Linear Mock ticket description',
    title: 'Linear Mock Ticket Title',
    url: 'https://example.com/linear-mock-ticket',
    integrationName: Integration.LINEAR,
    mergeId: 'linear-mock-merge-id',
    status: TicketingStatus.OPEN,
    priority: TicketingPriority.HIGH,
    created_at: null,
    completed_at: null,
    due_date: null,
    modified_at: null,
    ticketId: 'linear-mock-ticket-id'
  }
};

export const MOCK_TEXT_INPUT_PAYLOAD = {
  text: 'Mock text input'
};
