import _styled from "styled-components";
import { useCallback, useEffect, useState } from 'react';
import SurveyStep from './components/SurveyStep';
import { ONBOARDING_STEPS } from './OnboardingModal.consts';
import { OnboardingFormData } from './OnboardingModal.types';
import { Modal, ModalWidth } from '~/components/core';
const OnboardingModalContainer = _styled.div.withConfig({
  displayName: "OnboardingModal__OnboardingModalContainer",
  componentId: "sc-1cwu338-0"
})({
  "position": "relative",
  "display": "flex",
  "maxHeight": "520px",
  "flexDirection": "column",
  "gap": "1.5rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))",
  "backgroundSize": "contain",
  "backgroundRepeat": "no-repeat"
});
interface OnboardingModalProps {
  onClose: () => void;
  onSubmit: (form: OnboardingFormData) => void;
  open: boolean;
  user: {
    name: string | null;
    onboardingQuestions: {
      answer: string[];
      question: string;
    }[] | null;
  };
}
const OnboardingModal = ({
  onSubmit,
  user,
  open,
  onClose
}: OnboardingModalProps) => {
  const firstIncompleteStep = ONBOARDING_STEPS.findIndex(s => user?.onboardingQuestions?.find(q => q.question === s.stepName) === undefined);
  const [stepIndex, setStepIndex] = useState(firstIncompleteStep);
  const step = ONBOARDING_STEPS[stepIndex] || null;
  const [formData, setFormData] = useState<OnboardingFormData>(
  // set initial values for all steps
  ONBOARDING_STEPS.reduce((model, currStep) => ({
    ...model,
    [currStep.stepName]: typeof currStep.initialValue === 'function' ? currStep.initialValue(user) : currStep.initialValue || ''
  }), {}));
  const goToNextStep = useCallback(() => {
    if (stepIndex >= ONBOARDING_STEPS.length) return;
    setStepIndex(index => index + 1);
  }, [stepIndex]);
  useEffect(() => {
    if (stepIndex >= ONBOARDING_STEPS.length) {
      onSubmit(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex, formData]);
  const handleSubmitStep = (value: string) => {
    setFormData(model => {
      if (!step?.stepName) return model;
      return {
        ...model,
        [step?.stepName]: value
      };
    });
    goToNextStep();
  };
  return <Modal onClose={() => onClose?.()} open={open} width={ModalWidth['2XL']}>
      <OnboardingModalContainer>
        <SurveyStep initialValue={typeof step?.initialValue === 'function' ? step?.initialValue(user) : step?.initialValue || ''} onSkip={() => onClose?.()} onSubmit={handleSubmitStep} stepData={step} stepNumber={stepIndex + 1} totalSteps={ONBOARDING_STEPS.length} />
      </OnboardingModalContainer>
    </Modal>;
};
export default OnboardingModal;