import { NonEmptyArray } from '../utils';

export enum Integration {
  ACCELO = 'ACCELO',
  ACTIVECAMPAIGN = 'ACTIVECAMPAIGN',
  ADP_WORKFORCE_NOW = 'ADP_WORKFORCE_NOW',
  AFFINITY = 'AFFINITY',
  ALEXISHR = 'ALEXISHR',
  ALTERA_PAYROLL = 'ALTERA_PAYROLL',
  APPLICANTSTACK = 'APPLICANTSTACK',
  ASANA = 'ASANA',
  ASHBY = 'ASHBY',
  AZURE_DEVOPS = 'AZURE_DEVOPS',
  BAMBOOHR = 'BAMBOOHR',
  BASECAMP = 'BASECAMP',
  BITBUCKET = 'BITBUCKET',
  BOX = 'BOX',
  BREATHE = 'BREATHE',
  BREEZY = 'BREEZY',
  CAPSULE = 'CAPSULE',
  CATS = 'CATS',
  CERIDIAN_DAYFORCE = 'CERIDIAN_DAYFORCE',
  CHARLIE = 'CHARLIE',
  CHARTHOP = 'CHARTHOP',
  CLAYHR = 'CLAYHR',
  CLEAR_BOOKS = 'CLEAR_BOOKS',
  CLICKUP = 'CLICKUP',
  CLOCKWORK = 'CLOCKWORK',
  CLOSE = 'CLOSE',
  COMEET = 'COMEET',
  COPPER = 'COPPER',
  CORNERSTONE_TALENTLINK = 'CORNERSTONE_TALENTLINK',
  CUSTOMERIO = 'CUSTOMERIO',
  DAYFORCE = 'DAYFORCE',
  DIXA = 'DIXA',
  DROPBOX = 'DROPBOX',
  ENGAGEATS = 'ENGAGEATS',
  EPLOY = 'EPLOY',
  FACTORIAL = 'FACTORIAL',
  FOUNTAIN = 'FOUNTAIN',
  FREEAGENT = 'FREEAGENT',
  FRESHBOOKS = 'FRESHBOOKS',
  FRESHDESK = 'FRESHDESK',
  FRESHSERVICE = 'FRESHSERVICE',
  FRESHTEAM = 'FRESHTEAM',
  FRONT = 'FRONT',
  GETRESPONSE = 'GETRESPONSE',
  GITHUB_ISSUES = 'GITHUB_ISSUES',
  GITLAB = 'GITLAB',
  GLADLY = 'GLADLY',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  GOOGLE_WORKSPACE = 'GOOGLE_WORKSPACE',
  GORGIAS = 'GORGIAS',
  GREENHOUSE = 'GREENHOUSE',
  GREENHOUSE_JOB_BOARDS_API = 'GREENHOUSE_JOB_BOARDS_API',
  GUSTO = 'GUSTO',
  HEIGHT = 'HEIGHT',
  HELP_SCOUT = 'HELP_SCOUT',
  HIBOB = 'HIBOB',
  HIVE = 'HIVE',
  HOMERUN = 'HOMERUN',
  HR_CLOUD = 'HR_CLOUD',
  HR_PARTNER = 'HR_PARTNER',
  HUBSPOT = 'HUBSPOT',
  HUBSPOT_MARKETING_HUB = 'HUBSPOT_MARKETING_HUB',
  HUMAANS = 'HUMAANS',
  INSIGHTLY = 'INSIGHTLY',
  INTELLIHR = 'INTELLIHR',
  INTERCOM = 'INTERCOM',
  IRONCLAD = 'IRONCLAD',
  JAZZHR = 'JAZZHR',
  JIRA = 'JIRA',
  JIRA_SERVICE_MANAGEMENT = 'JIRA_SERVICE_MANAGEMENT',
  JOBSCORE = 'JOBSCORE',
  JOBSOID = 'JOBSOID',
  JOBVITE = 'JOBVITE',
  JUMPCLOUD = 'JUMPCLOUD',
  JUSTWORKS = 'JUSTWORKS',
  KALLIDUS = 'KALLIDUS',
  KEAP = 'KEAP',
  KLAVIYO = 'KLAVIYO',
  KUSTOMER = 'KUSTOMER',
  LANO = 'LANO',
  LEVER = 'LEVER',
  LINEAR = 'LINEAR',
  LUCCA = 'LUCCA',
  MAILCHIMP = 'MAILCHIMP',
  MESSAGEBIRD = 'MESSAGEBIRD',
  MICROSOFT_DYNAMICS_365_SALES = 'MICROSOFT_DYNAMICS_365_SALES',
  MICROSOFT_ENTRA_ID = 'MICROSOFT_ENTRA_ID',
  MONEYBIRD = 'MONEYBIRD',
  NAMELY = 'NAMELY',
  NETSUITE = 'NETSUITE',
  NMBRS = 'NMBRS',
  NUTSHELL = 'NUTSHELL',
  OFFICIENT = 'OFFICIENT',
  OKTA = 'OKTA',
  ONEDRIVE = 'ONEDRIVE',
  ONELOGIN = 'ONELOGIN',
  ORACLE_TALEO = 'ORACLE_TALEO',
  PAYCHEX = 'PAYCHEX',
  PAYLOCITY = 'PAYLOCITY',
  PEOPLEHR = 'PEOPLEHR',
  PERSONIO = 'PERSONIO',
  PIPEDRIVE = 'PIPEDRIVE',
  PIPELINER = 'PIPELINER',
  PIVOTAL_TRACKER = 'PIVOTAL_TRACKER',
  PODIUM = 'PODIUM',
  POLYMER = 'POLYMER',
  PROLIANT = 'PROLIANT',
  QUICKBOOKS_ONLINE = 'QUICKBOOKS_ONLINE',
  REAMAZE = 'REAMAZE',
  RECRUITERFLOW = 'RECRUITERFLOW',
  SAGE_BUSINESS_CLOUD_ACCOUNTING = 'SAGE_BUSINESS_CLOUD_ACCOUNTING',
  SAGE_HR = 'SAGE_HR',
  SALESFLARE = 'SALESFLARE',
  SALESFORCE = 'SALESFORCE',
  SALESFORCE_SERVICE_CLOUD = 'SALESFORCE_SERVICE_CLOUD',
  SAP_SUCCESSFACTORS = 'SAP_SUCCESSFACTORS',
  SENDGRID = 'SENDGRID',
  SENDINBLUE = 'SENDINBLUE',
  SERVICENOW = 'SERVICENOW',
  SESAME = 'SESAME',
  SHAREPOINT = 'SHAREPOINT',
  SHORTCUT = 'SHORTCUT',
  SLACK = 'SLACK',
  SMARTRECRUITERS = 'SMARTRECRUITERS',
  SPOTDRAFT = 'SPOTDRAFT',
  SQUARE_PAYROLL = 'SQUARE_PAYROLL',
  SUGARCRM = 'SUGARCRM',
  TALENTLYFT = 'TALENTLYFT',
  TALENTREEF = 'TALENTREEF',
  TEAMLEADER = 'TEAMLEADER',
  TEAMTAILOR = 'TEAMTAILOR',
  TEAMWORK = 'TEAMWORK',
  TEAMWORK_CRM = 'TEAMWORK_CRM',
  TELLENT = 'TELLENT',
  TRELLO = 'TRELLO',
  TRINET = 'TRINET',
  TRINET_HR_PLATFORM = 'TRINET_HR_PLATFORM',
  UKG_PRO = 'UKG_PRO',
  UKG_PRO_RECRUITING = 'UKG_PRO_RECRUITING',
  UKG_PRO_WORKFORCE_MANAGEMENT = 'UKG_PRO_WORKFORCE_MANAGEMENT',
  UKG_READY = 'UKG_READY',
  VTIGER = 'VTIGER',
  WAVE_FINANCIAL = 'WAVE_FINANCIAL',
  WORKABLE = 'WORKABLE',
  WORKDAY = 'WORKDAY',
  WRIKE = 'WRIKE',
  XERO = 'XERO',
  ZENDESK = 'ZENDESK',
  ZENDESK_SELL = 'ZENDESK_SELL',
  ZOHO_BOOKS = 'ZOHO_BOOKS',
  ZOHO_BUGTRACKER = 'ZOHO_BUGTRACKER',
  ZOHO_CRM = 'ZOHO_CRM',
  ZOHO_DESK = 'ZOHO_DESK'
}

export function isIntegration(value: unknown): value is Integration {
  return Object.values(Integration).includes(value as Integration);
}

export const INTEGRATION_DISPLAY_NAMES = {
  [Integration.ACCELO]: 'Accelo',
  [Integration.ACTIVECAMPAIGN]: 'ActiveCampaign',
  [Integration.ADP_WORKFORCE_NOW]: 'ADP Workforce Now',
  [Integration.AFFINITY]: 'Affinity',
  [Integration.ALEXISHR]: 'AlexisHR',
  [Integration.ALTERA_PAYROLL]: 'Altera Payroll',
  [Integration.APPLICANTSTACK]: 'ApplicantStack',
  [Integration.ASANA]: 'Asana',
  [Integration.ASHBY]: 'Ashby',
  [Integration.AZURE_DEVOPS]: 'Azure DevOps',
  [Integration.BAMBOOHR]: 'BambooHR',
  [Integration.BASECAMP]: 'Basecamp',
  [Integration.BITBUCKET]: 'Bitbucket',
  [Integration.BOX]: 'Box',
  [Integration.BREATHE]: 'Breathe',
  [Integration.BREEZY]: 'Breezy',
  [Integration.CAPSULE]: 'Capsule',
  [Integration.CATS]: 'CATS',
  [Integration.CERIDIAN_DAYFORCE]: 'Ceridian Dayforce',
  [Integration.CHARLIE]: 'Charlie',
  [Integration.CHARTHOP]: 'ChartHop',
  [Integration.CLAYHR]: 'ClayHR',
  [Integration.CLEAR_BOOKS]: 'Clear Books',
  [Integration.CLICKUP]: 'ClickUp',
  [Integration.CLOCKWORK]: 'Clockwork',
  [Integration.CLOSE]: 'Close',
  [Integration.COMEET]: 'Comeet',
  [Integration.COPPER]: 'Copper',
  [Integration.CORNERSTONE_TALENTLINK]: 'Cornerstone TalentLink',
  [Integration.CUSTOMERIO]: 'Customer.io',
  [Integration.DAYFORCE]: 'Dayforce',
  [Integration.DIXA]: 'Dixa',
  [Integration.DROPBOX]: 'Dropbox',
  [Integration.ENGAGEATS]: 'EngageATS',
  [Integration.EPLOY]: 'Eploy',
  [Integration.FACTORIAL]: 'Factorial',
  [Integration.FOUNTAIN]: 'Fountain',
  [Integration.FREEAGENT]: 'FreeAgent',
  [Integration.FRESHBOOKS]: 'FreshBooks',
  [Integration.FRESHDESK]: 'Freshdesk',
  [Integration.FRESHSERVICE]: 'Freshservice',
  [Integration.FRESHTEAM]: 'Freshteam',
  [Integration.FRONT]: 'Front',
  [Integration.GETRESPONSE]: 'getResponse',
  [Integration.GITHUB_ISSUES]: 'GitHub Issues',
  [Integration.GITLAB]: 'GitLab',
  [Integration.GLADLY]: 'Gladly',
  [Integration.GOOGLE_DRIVE]: 'Google Drive',
  [Integration.GOOGLE_WORKSPACE]: 'Google Workspace',
  [Integration.GORGIAS]: 'Gorgias',
  [Integration.GREENHOUSE]: 'Greenhouse',
  [Integration.GREENHOUSE_JOB_BOARDS_API]: 'Greenhouse - Job Boards API',
  [Integration.GUSTO]: 'Gusto',
  [Integration.HEIGHT]: 'Height',
  [Integration.HELP_SCOUT]: 'Help Scout',
  [Integration.HIBOB]: 'Hibob',
  [Integration.HIVE]: 'Hive',
  [Integration.HOMERUN]: 'Homerun',
  [Integration.HR_CLOUD]: 'HR Cloud',
  [Integration.HR_PARTNER]: 'HR Partner',
  [Integration.HUBSPOT]: 'HubSpot',
  [Integration.HUBSPOT_MARKETING_HUB]: 'Hubspot Marketing Hub',
  [Integration.HUMAANS]: 'Humaans',
  [Integration.INSIGHTLY]: 'Insightly',
  [Integration.INTELLIHR]: 'IntelliHR',
  [Integration.INTERCOM]: 'Intercom',
  [Integration.IRONCLAD]: 'Ironclad',
  [Integration.JAZZHR]: 'JazzHR',
  [Integration.JIRA]: 'Jira',
  [Integration.JIRA_SERVICE_MANAGEMENT]: 'Jira Service Management',
  [Integration.JOBSCORE]: 'JobScore',
  [Integration.JOBSOID]: 'Jobsoid',
  [Integration.JOBVITE]: 'Jobvite',
  [Integration.JUMPCLOUD]: 'JumpCloud',
  [Integration.JUSTWORKS]: 'Justworks',
  [Integration.KALLIDUS]: 'Kallidus',
  [Integration.KEAP]: 'Keap',
  [Integration.KLAVIYO]: 'Klaviyo',
  [Integration.KUSTOMER]: 'Kustomer',
  [Integration.LANO]: 'Lano',
  [Integration.LEVER]: 'Lever',
  [Integration.LINEAR]: 'Linear',
  [Integration.LUCCA]: 'Lucca',
  [Integration.MAILCHIMP]: 'Mailchimp',
  [Integration.MESSAGEBIRD]: 'MessageBird',
  [Integration.MICROSOFT_DYNAMICS_365_SALES]: 'Microsoft Dynamics 365 Sales',
  [Integration.MICROSOFT_ENTRA_ID]: 'Microsoft Entra Id',
  [Integration.MONEYBIRD]: 'Moneybird',
  [Integration.NAMELY]: 'Namely',
  [Integration.NETSUITE]: 'NetSuite',
  [Integration.NMBRS]: 'Nmbrs',
  [Integration.NUTSHELL]: 'Nutshell',
  [Integration.OFFICIENT]: 'Officient',
  [Integration.OKTA]: 'Okta',
  [Integration.ONEDRIVE]: 'OneDrive',
  [Integration.ONELOGIN]: 'OneLogin',
  [Integration.ORACLE_TALEO]: 'Oracle Taleo',
  [Integration.PAYCHEX]: 'Paychex',
  [Integration.PAYLOCITY]: 'Paylocity',
  [Integration.PEOPLEHR]: 'PeopleHR',
  [Integration.PERSONIO]: 'Personio',
  [Integration.PIPEDRIVE]: 'Pipedrive',
  [Integration.PIPELINER]: 'Pipeliner',
  [Integration.PIVOTAL_TRACKER]: 'Pivotal Tracker',
  [Integration.PODIUM]: 'Podium',
  [Integration.POLYMER]: 'Polymer',
  [Integration.PROLIANT]: 'Proliant',
  [Integration.QUICKBOOKS_ONLINE]: 'QuickBooks Online',
  [Integration.REAMAZE]: 'Re:amaze',
  [Integration.RECRUITERFLOW]: 'Recruiterflow',
  [Integration.SAGE_BUSINESS_CLOUD_ACCOUNTING]:
    'Sage Business Cloud Accounting',
  [Integration.SAGE_HR]: 'Sage HR',
  [Integration.SALESFLARE]: 'Salesflare',
  [Integration.SALESFORCE]: 'Salesforce',
  [Integration.SALESFORCE_SERVICE_CLOUD]: 'Salesforce Service Cloud',
  [Integration.SAP_SUCCESSFACTORS]: 'SAP SuccessFactors',
  [Integration.SENDGRID]: 'SendGrid',
  [Integration.SENDINBLUE]: 'Sendinblue',
  [Integration.SERVICENOW]: 'ServiceNow',
  [Integration.SESAME]: 'Sesame',
  [Integration.SHAREPOINT]: 'SharePoint',
  [Integration.SHORTCUT]: 'Shortcut',
  [Integration.SLACK]: 'Slack',
  [Integration.SMARTRECRUITERS]: 'SmartRecruiters',
  [Integration.SPOTDRAFT]: 'SpotDraft',
  [Integration.SQUARE_PAYROLL]: 'Square Payroll',
  [Integration.SUGARCRM]: 'SugarCRM',
  [Integration.TALENTLYFT]: 'TalentLyft',
  [Integration.TALENTREEF]: 'TalentReef',
  [Integration.TEAMLEADER]: 'Teamleader',
  [Integration.TEAMTAILOR]: 'Teamtailor',
  [Integration.TEAMWORK]: 'Teamwork',
  [Integration.TEAMWORK_CRM]: 'Teamwork CRM',
  [Integration.TELLENT]: 'Tellent',
  [Integration.TRELLO]: 'Trello',
  [Integration.TRINET]: 'TriNet',
  [Integration.TRINET_HR_PLATFORM]: 'TriNet HR Platform',
  [Integration.UKG_PRO]: 'UKG Pro',
  [Integration.UKG_PRO_RECRUITING]: 'UKG Pro Recruiting',
  [Integration.UKG_PRO_WORKFORCE_MANAGEMENT]: 'UKG Pro Workforce Management',
  [Integration.UKG_READY]: 'UKG Ready',
  [Integration.VTIGER]: 'Vtiger',
  [Integration.WAVE_FINANCIAL]: 'Wave Financial',
  [Integration.WORKABLE]: 'Workable',
  [Integration.WORKDAY]: 'Workday',
  [Integration.WRIKE]: 'Wrike',
  [Integration.XERO]: 'Xero',
  [Integration.ZENDESK]: 'Zendesk',
  [Integration.ZENDESK_SELL]: 'Zendesk Sell',
  [Integration.ZOHO_BOOKS]: 'Zoho Books',
  [Integration.ZOHO_BUGTRACKER]: 'Zoho BugTracker',
  [Integration.ZOHO_CRM]: 'Zoho CRM',
  [Integration.ZOHO_DESK]: 'Zoho Desk'
} as const satisfies Record<Integration, string>;

const NON_MERGE_INTEGRATIONS = [
  Integration.SLACK
] as const satisfies readonly Integration[];

type NonMergeIntegration = (typeof NON_MERGE_INTEGRATIONS)[number];

export type MergeIntegration = Exclude<Integration, NonMergeIntegration>;

export const INTEGRATION_TO_MERGE_INTEGRATION_SLUG = {
  [Integration.ACCELO]: 'accelo',
  [Integration.ACTIVECAMPAIGN]: 'activecampaign',
  [Integration.ADP_WORKFORCE_NOW]: 'adp-workforce-now',
  [Integration.AFFINITY]: 'affinity',
  [Integration.ALEXISHR]: 'alexishr',
  [Integration.ALTERA_PAYROLL]: 'altera-payroll',
  [Integration.APPLICANTSTACK]: 'applicantstack',
  [Integration.ASANA]: 'asana',
  [Integration.ASHBY]: 'ashby',
  [Integration.AZURE_DEVOPS]: 'azure-devops',
  [Integration.BAMBOOHR]: 'bamboohr',
  [Integration.BASECAMP]: 'basecamp',
  [Integration.BITBUCKET]: 'bitbucket',
  [Integration.BOX]: 'box',
  [Integration.BREATHE]: 'breathe',
  [Integration.BREEZY]: 'breezy',
  [Integration.CAPSULE]: 'capsule',
  [Integration.CATS]: 'cats',
  [Integration.CERIDIAN_DAYFORCE]: 'ceridian-dayforce',
  [Integration.CHARLIE]: 'charlie',
  [Integration.CHARTHOP]: 'charthop',
  [Integration.CLAYHR]: 'clayhr',
  [Integration.CLEAR_BOOKS]: 'clear-books',
  [Integration.CLICKUP]: 'clickup',
  [Integration.CLOCKWORK]: 'clockwork',
  [Integration.CLOSE]: 'close',
  [Integration.COMEET]: 'comeet',
  [Integration.COPPER]: 'copper',
  [Integration.CORNERSTONE_TALENTLINK]: 'cornerstone-talentlink',
  [Integration.CUSTOMERIO]: 'customerio',
  [Integration.DAYFORCE]: 'dayforce',
  [Integration.DIXA]: 'dixa',
  [Integration.DROPBOX]: 'dropbox',
  [Integration.EPLOY]: 'eploy',
  [Integration.ENGAGEATS]: 'engageats',
  [Integration.FACTORIAL]: 'factorial',
  [Integration.FOUNTAIN]: 'fountain',
  [Integration.FREEAGENT]: 'freeagent',
  [Integration.FRESHBOOKS]: 'freshbooks',
  [Integration.FRESHDESK]: 'freshdesk',
  [Integration.FRESHSERVICE]: 'freshservice',
  [Integration.FRESHTEAM]: 'freshteam',
  [Integration.FRONT]: 'front',
  [Integration.GETRESPONSE]: 'getresponse',
  [Integration.GITHUB_ISSUES]: 'github-issues',
  [Integration.GITLAB]: 'gitlab',
  [Integration.GLADLY]: 'gladly',
  [Integration.GOOGLE_DRIVE]: 'google-drive',
  [Integration.GOOGLE_WORKSPACE]: 'google-workspace',
  [Integration.GORGIAS]: 'gorgias',
  [Integration.GREENHOUSE]: 'greenhouse',
  [Integration.GREENHOUSE_JOB_BOARDS_API]: 'greenhouse-job-boards-api',
  [Integration.GUSTO]: 'gusto',
  [Integration.HEIGHT]: 'height',
  [Integration.HELP_SCOUT]: 'help-scout',
  [Integration.HIBOB]: 'hibob',
  [Integration.HIVE]: 'hive',
  [Integration.HOMERUN]: 'homerun',
  [Integration.HR_CLOUD]: 'hr-cloud',
  [Integration.HR_PARTNER]: 'hr-partner',
  [Integration.HUBSPOT]: 'hubspot',
  [Integration.HUBSPOT_MARKETING_HUB]: 'hubspot-marketing-hub',
  [Integration.HUMAANS]: 'humaans',
  [Integration.INSIGHTLY]: 'insightly',
  [Integration.INTELLIHR]: 'intellihr',
  [Integration.INTERCOM]: 'intercom',
  [Integration.IRONCLAD]: 'ironclad',
  [Integration.JAZZHR]: 'jazzhr',
  [Integration.JIRA]: 'jira',
  [Integration.JIRA_SERVICE_MANAGEMENT]: 'jira-service-management',
  [Integration.JOBSCORE]: 'jobscore',
  [Integration.JOBSOID]: 'jobsoid',
  [Integration.JOBVITE]: 'jobvite',
  [Integration.JUMPCLOUD]: 'jumpcloud',
  [Integration.JUSTWORKS]: 'justworks',
  [Integration.KALLIDUS]: 'kallidus',
  [Integration.KEAP]: 'keap',
  [Integration.KLAVIYO]: 'klaviyo',
  [Integration.KUSTOMER]: 'kustomer',
  [Integration.LANO]: 'lano',
  [Integration.LEVER]: 'lever',
  [Integration.LINEAR]: 'linear',
  [Integration.LUCCA]: 'lucca',
  [Integration.MAILCHIMP]: 'mailchimp',
  [Integration.MESSAGEBIRD]: 'messagebird',
  [Integration.MICROSOFT_DYNAMICS_365_SALES]: 'microsoft-dynamics-365-sales',
  [Integration.MICROSOFT_ENTRA_ID]: 'microsoft-entra-id',
  [Integration.MONEYBIRD]: 'moneybird',
  [Integration.NAMELY]: 'namely',
  [Integration.NETSUITE]: 'netsuite',
  [Integration.NMBRS]: 'nmbrs',
  [Integration.NUTSHELL]: 'nutshell',
  [Integration.OFFICIENT]: 'officient',
  [Integration.OKTA]: 'okta',
  [Integration.ONEDRIVE]: 'onedrive',
  [Integration.ONELOGIN]: 'onelogin',
  [Integration.ORACLE_TALEO]: 'oracle-taleo',
  [Integration.PAYCHEX]: 'paychex',
  [Integration.PAYLOCITY]: 'paylocity',
  [Integration.PEOPLEHR]: 'peoplehr',
  [Integration.PERSONIO]: 'personio',
  [Integration.PIPEDRIVE]: 'pipedrive',
  [Integration.PIPELINER]: 'pipeliner',
  [Integration.PIVOTAL_TRACKER]: 'pivotal-tracker',
  [Integration.PODIUM]: 'podium',
  [Integration.POLYMER]: 'polymer',
  [Integration.PROLIANT]: 'proliant',
  [Integration.QUICKBOOKS_ONLINE]: 'quickbooks-online',
  [Integration.REAMAZE]: 'reamaze',
  [Integration.RECRUITERFLOW]: 'recruiterflow',
  [Integration.SAGE_BUSINESS_CLOUD_ACCOUNTING]:
    'sage-business-cloud-accounting',
  [Integration.SAGE_HR]: 'sage-hr',
  [Integration.SALESFLARE]: 'salesflare',
  [Integration.SALESFORCE]: 'salesforce',
  [Integration.SALESFORCE_SERVICE_CLOUD]: 'salesforce-service-cloud',
  [Integration.SAP_SUCCESSFACTORS]: 'sap-successfactors',
  [Integration.SENDGRID]: 'sendgrid',
  [Integration.SENDINBLUE]: 'sendinblue',
  [Integration.SERVICENOW]: 'servicenow',
  [Integration.SESAME]: 'sesame',
  [Integration.SHAREPOINT]: 'sharepoint',
  [Integration.SHORTCUT]: 'shortcut',
  [Integration.SMARTRECRUITERS]: 'smartrecruiters',
  [Integration.SPOTDRAFT]: 'spotdraft',
  [Integration.SQUARE_PAYROLL]: 'square-payroll',
  [Integration.SUGARCRM]: 'sugarcrm',
  [Integration.TALENTLYFT]: 'talentlyft',
  [Integration.TALENTREEF]: 'talentreef',
  [Integration.TEAMLEADER]: 'teamleader',
  [Integration.TEAMTAILOR]: 'teamtailor',
  [Integration.TEAMWORK]: 'teamwork',
  [Integration.TEAMWORK_CRM]: 'teamwork-crm',
  [Integration.TELLENT]: 'tellent',
  [Integration.TRELLO]: 'trello',
  [Integration.TRINET]: 'trinet',
  [Integration.TRINET_HR_PLATFORM]: 'trinet-hr-platform',
  [Integration.UKG_PRO]: 'ukg-pro',
  [Integration.UKG_PRO_RECRUITING]: 'ukg-pro-recruiting',
  [Integration.UKG_READY]: 'ukg-ready',
  [Integration.UKG_PRO_WORKFORCE_MANAGEMENT]: 'ukg-pro-workforce-management',
  [Integration.VTIGER]: 'vtiger',
  [Integration.WAVE_FINANCIAL]: 'wave-financial',
  [Integration.WORKABLE]: 'workable',
  [Integration.WORKDAY]: 'workday',
  [Integration.WRIKE]: 'wrike',
  [Integration.XERO]: 'xero',
  [Integration.ZENDESK]: 'zendesk',
  [Integration.ZENDESK_SELL]: 'zendesk-sell',
  [Integration.ZOHO_BOOKS]: 'zoho-books',
  [Integration.ZOHO_BUGTRACKER]: 'zoho-bugtracker',
  [Integration.ZOHO_CRM]: 'zoho-crm',
  [Integration.ZOHO_DESK]: 'zoho-desk'
} as const satisfies Record<MergeIntegration, string>;

// Based on Merge's categories, but decoupled
export enum IntegrationCategory {
  ACCOUNTING = 'ACCOUNTING',
  CUSTOMER_MANAGEMENT = 'CUSTOMER_MANAGEMENT',
  FILE_STORAGE = 'FILE_STORAGE',
  HIRING = 'HIRING',
  HR = 'HR',
  MARKETING = 'MARKETING',
  OTHER = 'OTHER',
  TICKETING = 'TICKETING'
}

export function isIntegrationCategory(
  category: string
): category is IntegrationCategory {
  return Object.values(IntegrationCategory).includes(
    category as IntegrationCategory
  );
}

// MergeIntegrationCategory is the subset of IntegrationCategory that Merge supports.
// It is NOT Merge's values for their categories.
export type MergeIntegrationCategory = Exclude<
  IntegrationCategory,
  IntegrationCategory.OTHER
>;

// Note: These values map to the values in MergeCategory in node-utils
// TODO: Consolidate and use that const/enum/type here
export const INTEGRATION_CATEGORY_TO_MERGE_CATEGORY = {
  [IntegrationCategory.CUSTOMER_MANAGEMENT]: 'crm',
  [IntegrationCategory.MARKETING]: 'mktg',
  [IntegrationCategory.HR]: 'hris',
  [IntegrationCategory.TICKETING]: 'ticketing',
  [IntegrationCategory.FILE_STORAGE]: 'filestorage',
  [IntegrationCategory.ACCOUNTING]: 'accounting',
  [IntegrationCategory.HIRING]: 'ats'
} as const satisfies Record<MergeIntegrationCategory, string>;

export const INTEGRATION_TO_CATEGORIES = {
  [Integration.ACCELO]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.ACTIVECAMPAIGN]: [
    IntegrationCategory.CUSTOMER_MANAGEMENT,
    IntegrationCategory.MARKETING
  ],
  [Integration.ADP_WORKFORCE_NOW]: [IntegrationCategory.HR],
  [Integration.AFFINITY]: [IntegrationCategory.HIRING],
  [Integration.ALEXISHR]: [IntegrationCategory.HR],
  [Integration.ALTERA_PAYROLL]: [IntegrationCategory.HR],
  [Integration.APPLICANTSTACK]: [IntegrationCategory.HIRING],
  [Integration.ASANA]: [IntegrationCategory.TICKETING],
  [Integration.ASHBY]: [IntegrationCategory.HIRING],
  [Integration.AZURE_DEVOPS]: [IntegrationCategory.TICKETING],
  [Integration.BAMBOOHR]: [IntegrationCategory.HR, IntegrationCategory.HIRING],
  [Integration.BASECAMP]: [IntegrationCategory.TICKETING],
  [Integration.BITBUCKET]: [IntegrationCategory.TICKETING],
  [Integration.BOX]: [IntegrationCategory.FILE_STORAGE],
  [Integration.BREATHE]: [IntegrationCategory.HR],
  [Integration.BREEZY]: [IntegrationCategory.HIRING],
  [Integration.CAPSULE]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.CATS]: [IntegrationCategory.HIRING],
  [Integration.CERIDIAN_DAYFORCE]: [IntegrationCategory.HR],
  [Integration.CHARLIE]: [IntegrationCategory.HR],
  [Integration.CHARTHOP]: [IntegrationCategory.HR],
  [Integration.CLAYHR]: [IntegrationCategory.HIRING],
  [Integration.CLEAR_BOOKS]: [IntegrationCategory.ACCOUNTING],
  [Integration.CLICKUP]: [IntegrationCategory.TICKETING],
  [Integration.CLOCKWORK]: [IntegrationCategory.HIRING],
  [Integration.CLOSE]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.COMEET]: [IntegrationCategory.HIRING],
  [Integration.COPPER]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.CORNERSTONE_TALENTLINK]: [IntegrationCategory.HIRING],
  [Integration.CUSTOMERIO]: [IntegrationCategory.MARKETING],
  [Integration.DAYFORCE]: [IntegrationCategory.HR],
  [Integration.DIXA]: [IntegrationCategory.TICKETING],
  [Integration.DROPBOX]: [IntegrationCategory.FILE_STORAGE],
  [Integration.ENGAGEATS]: [IntegrationCategory.HIRING],
  [Integration.EPLOY]: [IntegrationCategory.HIRING],
  [Integration.FACTORIAL]: [IntegrationCategory.HR],
  [Integration.FOUNTAIN]: [IntegrationCategory.HIRING],
  [Integration.FREEAGENT]: [IntegrationCategory.ACCOUNTING],
  [Integration.FRESHBOOKS]: [IntegrationCategory.ACCOUNTING],
  [Integration.FRESHDESK]: [IntegrationCategory.TICKETING],
  [Integration.FRESHSERVICE]: [IntegrationCategory.TICKETING],
  [Integration.FRESHTEAM]: [IntegrationCategory.HR, IntegrationCategory.HIRING],
  [Integration.FRONT]: [IntegrationCategory.TICKETING],
  [Integration.GETRESPONSE]: [IntegrationCategory.MARKETING],
  [Integration.GITHUB_ISSUES]: [IntegrationCategory.TICKETING],
  [Integration.GITLAB]: [IntegrationCategory.TICKETING],
  [Integration.GLADLY]: [IntegrationCategory.TICKETING],
  [Integration.GOOGLE_DRIVE]: [IntegrationCategory.FILE_STORAGE],
  [Integration.GOOGLE_WORKSPACE]: [IntegrationCategory.HR],
  [Integration.GORGIAS]: [IntegrationCategory.TICKETING],
  [Integration.GREENHOUSE]: [IntegrationCategory.HIRING],
  [Integration.GREENHOUSE_JOB_BOARDS_API]: [IntegrationCategory.HIRING],
  [Integration.GUSTO]: [IntegrationCategory.HR],
  [Integration.HEIGHT]: [IntegrationCategory.TICKETING],
  [Integration.HELP_SCOUT]: [IntegrationCategory.TICKETING],
  [Integration.HIBOB]: [IntegrationCategory.HR],
  [Integration.HIVE]: [IntegrationCategory.TICKETING],
  [Integration.HOMERUN]: [IntegrationCategory.HIRING],
  [Integration.HR_CLOUD]: [IntegrationCategory.HR, IntegrationCategory.HIRING],
  [Integration.HR_PARTNER]: [IntegrationCategory.HR],
  [Integration.HUBSPOT]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.HUBSPOT_MARKETING_HUB]: [IntegrationCategory.MARKETING],
  [Integration.HUMAANS]: [IntegrationCategory.HR],
  [Integration.INSIGHTLY]: [IntegrationCategory.HIRING],
  [Integration.INTELLIHR]: [IntegrationCategory.HR],
  [Integration.INTERCOM]: [IntegrationCategory.TICKETING],
  [Integration.IRONCLAD]: [IntegrationCategory.TICKETING],
  [Integration.JAZZHR]: [IntegrationCategory.HIRING],
  [Integration.JIRA]: [IntegrationCategory.TICKETING],
  [Integration.JIRA_SERVICE_MANAGEMENT]: [IntegrationCategory.TICKETING],
  [Integration.JOBSCORE]: [IntegrationCategory.HIRING],
  [Integration.JOBSOID]: [IntegrationCategory.HIRING],
  [Integration.JOBVITE]: [IntegrationCategory.HIRING],
  [Integration.JUMPCLOUD]: [IntegrationCategory.HR],
  [Integration.JUSTWORKS]: [IntegrationCategory.HR],
  [Integration.KALLIDUS]: [IntegrationCategory.HR],
  [Integration.KEAP]: [
    IntegrationCategory.CUSTOMER_MANAGEMENT,
    IntegrationCategory.MARKETING
  ],
  [Integration.KLAVIYO]: [IntegrationCategory.MARKETING],
  [Integration.KUSTOMER]: [IntegrationCategory.TICKETING],
  [Integration.LANO]: [IntegrationCategory.HR, IntegrationCategory.HIRING],
  [Integration.LEVER]: [IntegrationCategory.HIRING],
  [Integration.LINEAR]: [IntegrationCategory.TICKETING],
  [Integration.LUCCA]: [IntegrationCategory.HR],
  [Integration.MAILCHIMP]: [IntegrationCategory.MARKETING],
  [Integration.MESSAGEBIRD]: [IntegrationCategory.MARKETING],
  [Integration.MICROSOFT_DYNAMICS_365_SALES]: [
    IntegrationCategory.CUSTOMER_MANAGEMENT
  ],
  [Integration.MICROSOFT_ENTRA_ID]: [IntegrationCategory.HR],
  [Integration.MONEYBIRD]: [IntegrationCategory.ACCOUNTING],
  [Integration.NAMELY]: [IntegrationCategory.HR],
  [Integration.NETSUITE]: [IntegrationCategory.ACCOUNTING],
  [Integration.NMBRS]: [IntegrationCategory.HR],
  [Integration.NUTSHELL]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.OFFICIENT]: [IntegrationCategory.HR],
  [Integration.OKTA]: [IntegrationCategory.HR],
  [Integration.ONEDRIVE]: [IntegrationCategory.FILE_STORAGE],
  [Integration.ONELOGIN]: [IntegrationCategory.HR],
  [Integration.ORACLE_TALEO]: [IntegrationCategory.HIRING],
  [Integration.PAYCHEX]: [IntegrationCategory.HR],
  [Integration.PAYLOCITY]: [IntegrationCategory.HR],
  [Integration.PEOPLEHR]: [IntegrationCategory.HR],
  [Integration.PERSONIO]: [IntegrationCategory.HR],
  [Integration.PIPEDRIVE]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.PIPELINER]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.PIVOTAL_TRACKER]: [IntegrationCategory.TICKETING],
  [Integration.PODIUM]: [IntegrationCategory.MARKETING],
  [Integration.POLYMER]: [IntegrationCategory.HIRING],
  [Integration.PROLIANT]: [IntegrationCategory.HR],
  [Integration.QUICKBOOKS_ONLINE]: [IntegrationCategory.ACCOUNTING],
  [Integration.REAMAZE]: [IntegrationCategory.TICKETING],
  [Integration.RECRUITERFLOW]: [IntegrationCategory.HIRING],
  [Integration.SAGE_BUSINESS_CLOUD_ACCOUNTING]: [
    IntegrationCategory.ACCOUNTING
  ],
  [Integration.SAGE_HR]: [IntegrationCategory.HR, IntegrationCategory.HIRING],
  [Integration.SALESFLARE]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.SALESFORCE]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.SALESFORCE_SERVICE_CLOUD]: [IntegrationCategory.TICKETING],
  [Integration.SAP_SUCCESSFACTORS]: [
    IntegrationCategory.HR,
    IntegrationCategory.HIRING
  ],
  [Integration.SENDGRID]: [IntegrationCategory.MARKETING],
  [Integration.SENDINBLUE]: [IntegrationCategory.MARKETING],
  [Integration.SERVICENOW]: [IntegrationCategory.TICKETING],
  [Integration.SESAME]: [IntegrationCategory.HR],
  [Integration.SHAREPOINT]: [IntegrationCategory.FILE_STORAGE],
  [Integration.SHORTCUT]: [IntegrationCategory.TICKETING],
  [Integration.SLACK]: [IntegrationCategory.OTHER],
  [Integration.SMARTRECRUITERS]: [IntegrationCategory.HIRING],
  [Integration.SPOTDRAFT]: [IntegrationCategory.TICKETING],
  [Integration.SQUARE_PAYROLL]: [IntegrationCategory.HR],
  [Integration.SUGARCRM]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.TALENTLYFT]: [IntegrationCategory.HIRING],
  [Integration.TALENTREEF]: [IntegrationCategory.HIRING],
  [Integration.TEAMLEADER]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.TEAMTAILOR]: [IntegrationCategory.HIRING],
  [Integration.TEAMWORK]: [IntegrationCategory.TICKETING],
  [Integration.TEAMWORK_CRM]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.TELLENT]: [IntegrationCategory.HIRING],
  [Integration.TRELLO]: [IntegrationCategory.TICKETING],
  [Integration.TRINET]: [IntegrationCategory.HR],
  [Integration.TRINET_HR_PLATFORM]: [IntegrationCategory.HR],
  [Integration.UKG_PRO]: [IntegrationCategory.HR],
  [Integration.UKG_PRO_RECRUITING]: [IntegrationCategory.HIRING],
  [Integration.UKG_PRO_WORKFORCE_MANAGEMENT]: [IntegrationCategory.HR],
  [Integration.UKG_READY]: [IntegrationCategory.HR],
  [Integration.VTIGER]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.WAVE_FINANCIAL]: [IntegrationCategory.ACCOUNTING],
  [Integration.WORKABLE]: [IntegrationCategory.HIRING],
  [Integration.WORKDAY]: [IntegrationCategory.HR, IntegrationCategory.HIRING],
  [Integration.WRIKE]: [IntegrationCategory.TICKETING],
  [Integration.XERO]: [IntegrationCategory.ACCOUNTING],
  [Integration.ZENDESK]: [IntegrationCategory.TICKETING],
  [Integration.ZENDESK_SELL]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.ZOHO_BOOKS]: [IntegrationCategory.ACCOUNTING],
  [Integration.ZOHO_BUGTRACKER]: [IntegrationCategory.TICKETING],
  [Integration.ZOHO_CRM]: [IntegrationCategory.CUSTOMER_MANAGEMENT],
  [Integration.ZOHO_DESK]: [IntegrationCategory.TICKETING]
} as const satisfies Record<Integration, NonEmptyArray<IntegrationCategory>>;

export const INTEGRATION_TO_SQUARE_IMAGE = {
  [Integration.ACCELO]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformaccelo_square.png',
  [Integration.ACTIVECAMPAIGN]:
    'https://merge-api-production.s3.amazonaws.com/media/ActiveCampaign_square.jpg',
  [Integration.ADP_WORKFORCE_NOW]:
    'https://merge-api-production.s3.amazonaws.com/media/ADP_Square_Logo_GXCPXY1.jpg',
  [Integration.AFFINITY]:
    'https://merge-api-production.s3.amazonaws.com/media/Affinity_square.png',
  [Integration.ALEXISHR]:
    'https://merge-api-production.s3.amazonaws.com/media/AlexisHR_Square_Logo.png',
  [Integration.ALTERA_PAYROLL]:
    'https://merge-api-production.s3.amazonaws.com/media/Altera_Square_Logo.png',
  [Integration.APPLICANTSTACK]:
    'https://merge-api-production.s3.amazonaws.com/media/ApplicantStack_Square_Logo_wBHYVRL.jpg',
  [Integration.ASANA]:
    'https://merge-api-production.s3.amazonaws.com/media/asana-square-logo2.jpeg',
  [Integration.ASHBY]:
    'https://merge-api-production.s3.amazonaws.com/media/Ashby_Square_Logo_3uQWavw.png',
  [Integration.AZURE_DEVOPS]:
    'https://merge-api-production.s3.amazonaws.com/media/Azure_DevOps_Square_Logo.png',
  [Integration.BAMBOOHR]:
    'https://merge-api-production.s3.amazonaws.com/media/BambooHR_Square_Logo.jpg',
  [Integration.BASECAMP]:
    'https://merge-api-production.s3.amazonaws.com/media/Basecamp_Square_Logo.png',
  [Integration.BITBUCKET]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformbitbucket_square.png',
  [Integration.BOX]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformBox_square.png',
  [Integration.BREATHE]:
    'https://merge-api-production.s3.amazonaws.com/media/Breathe_Square_Logo_FY9Zgjm.jpg',
  [Integration.BREEZY]:
    'https://merge-api-production.s3.amazonaws.com/media/Breezy_Square_Logo.jpg',
  [Integration.CAPSULE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformCapsule.png',
  [Integration.CATS]:
    'https://merge-api-production.s3.amazonaws.com/media/CATS_Square_Logo_J4NkqoE.jpg',
  [Integration.CERIDIAN_DAYFORCE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformDayforce.png',
  [Integration.CHARLIE]:
    'https://merge-api-production.s3.amazonaws.com/media/CharlieHR_Square_Logo.png',
  [Integration.CHARTHOP]:
    'https://merge-api-production.s3.amazonaws.com/media/ChartHop2_square.png',
  [Integration.CLAYHR]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformClayHR_square_0Hwn5DL.png',
  [Integration.CLEAR_BOOKS]:
    'https://merge-api-production.s3.amazonaws.com/media/Clear_Books_Square_Logo.jpg',
  [Integration.CLICKUP]:
    'https://merge-api-production.s3.amazonaws.com/media/ClickUp_Square_Logo.png',
  [Integration.CLOCKWORK]:
    'https://merge-api-production.s3.amazonaws.com/media/Clockwork_Square_Logo.jpg',
  [Integration.CLOSE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformClose.jpg',
  [Integration.COMEET]:
    'https://merge-api-production.s3.amazonaws.com/media/Comeet_Square_Logo.jpg',
  [Integration.COPPER]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformCopper.jpg',
  [Integration.CORNERSTONE_TALENTLINK]:
    'https://merge-api-production.s3.amazonaws.com/media/Cornerstone_Square_Logo.jpg',
  [Integration.CUSTOMERIO]:
    'https://merge-api-production.s3.amazonaws.com/media/customer_io_square.png',
  [Integration.DAYFORCE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformDayforce.png',
  [Integration.DIXA]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformDixa_square.png',
  [Integration.DROPBOX]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformDropbox_square_mLnVhny.png',
  [Integration.ENGAGEATS]:
    'https://merge-api-production.s3.amazonaws.com/media/EngageATS_square.png',
  [Integration.EPLOY]:
    'https://merge-api-production.s3.amazonaws.com/media/Eploy_square.png',
  [Integration.FACTORIAL]:
    'https://merge-api-production.s3.amazonaws.com/media/Factorial_Square_Logo.png',
  [Integration.FOUNTAIN]:
    'https://merge-api-production.s3.amazonaws.com/media/Fountain_Square_Logo.png',
  [Integration.FREEAGENT]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformfreeagent_square_kRtyDG2.png',
  [Integration.FRESHBOOKS]:
    'https://merge-api-production.s3.amazonaws.com/media/FreshBooks_Square_Logo.jpg',
  [Integration.FRESHDESK]:
    'https://merge-api-production.s3.amazonaws.com/media/Freshdesk_Square_Logo.jpeg',
  [Integration.FRESHSERVICE]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformfreshservice_square.png',
  [Integration.FRESHTEAM]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformFreshteam_square.png',
  [Integration.FRONT]:
    'https://merge-api-production.s3.amazonaws.com/media/Front_square.png',
  [Integration.GETRESPONSE]:
    'https://merge-api-production.s3.amazonaws.com/media/getresponse_square.png',
  [Integration.GITHUB_ISSUES]:
    'https://merge-api-production.s3.amazonaws.com/media/Github_Issues_Square_Logo.png',
  [Integration.GITLAB]:
    'https://merge-api-production.s3.amazonaws.com/media/Gitlab_Square_Logo.png',
  [Integration.GLADLY]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformGladly.png',
  [Integration.GOOGLE_DRIVE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformGoogle_Drive_square.png',
  [Integration.GOOGLE_WORKSPACE]:
    'https://merge-api-production.s3.amazonaws.com/media/Google_Workspaces_square.png',
  [Integration.GORGIAS]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformGorgias_square.png',
  [Integration.GREENHOUSE]:
    'https://merge-api-production.s3.amazonaws.com/media/Greenhouse_Square_Logo.jpg',
  [Integration.GREENHOUSE_JOB_BOARDS_API]:
    'https://merge-api-production.s3.amazonaws.com/media/Greenhouse_Square_Logo.jpeg',
  [Integration.GUSTO]:
    'https://merge-api-production.s3.amazonaws.com/media/Gusto_Square_Logo.jpg',
  [Integration.HEIGHT]:
    'https://merge-api-production.s3.amazonaws.com/media/Height_square.png',
  [Integration.HELP_SCOUT]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformHelpscout_square.png',
  [Integration.HIBOB]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformHibob.png',
  [Integration.HIVE]:
    'https://merge-api-production.s3.amazonaws.com/media/Hive_Square_Logo.png',
  [Integration.HOMERUN]:
    'https://merge-api-production.s3.amazonaws.com/media/homerun.png',
  [Integration.HR_CLOUD]:
    'https://merge-api-production.s3.amazonaws.com/media/HR_Cloud_Square_Logo.jpg',
  [Integration.HR_PARTNER]:
    'https://merge-api-production.s3.amazonaws.com/media/HRPartner_Square_Logo.jpg',
  [Integration.HUBSPOT]:
    'https://merge-api-production.s3.amazonaws.com/media/Hubspot_square.jpg',
  [Integration.HUBSPOT_MARKETING_HUB]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformHubspot.png',
  [Integration.HUMAANS]:
    'https://merge-api-production.s3.amazonaws.com/media/Humaans_Square_Logo.jpg',
  [Integration.INSIGHTLY]:
    'https://merge-api-production.s3.amazonaws.com/media/Insightly_square.png',
  [Integration.INTELLIHR]:
    'https://merge-api-production.s3.amazonaws.com/media/IntelliHR_Square_Logo.jpg',
  [Integration.INTERCOM]:
    'https://merge-api-production.s3.amazonaws.com/media/Intercom_Square_Logo_V9D2LCb.png',
  [Integration.IRONCLAD]:
    'https://merge-api-production.s3.amazonaws.com/media/IronClad_Square.png',
  [Integration.JAZZHR]:
    'https://merge-api-production.s3.amazonaws.com/media/JazzHR_Square_Logo_WQcMDMd.jpg',
  [Integration.JIRA]:
    'https://merge-api-production.s3.amazonaws.com/media/Jira_Square_Logo.png',
  [Integration.JIRA_SERVICE_MANAGEMENT]:
    'https://merge-api-production.s3.amazonaws.com/media/Jira_Service_Management.jpg',
  [Integration.JOBSCORE]:
    'https://merge-api-production.s3.amazonaws.com/media/JobScore-square.png',
  [Integration.JOBSOID]:
    'https://merge-api-production.s3.amazonaws.com/media/Jobsoid_Square_Logo_L2UcdnQ.jpg',
  [Integration.JOBVITE]:
    'https://merge-api-production.s3.amazonaws.com/media/Jobvite_Square_Logo.png',
  [Integration.JUMPCLOUD]:
    'https://merge-api-production.s3.amazonaws.com/media/Jumpcloud_square.png',
  [Integration.JUSTWORKS]:
    'https://merge-api-production.s3.amazonaws.com/media/Justworks_Square_Logo.jpg',
  [Integration.KALLIDUS]:
    'https://merge-api-production.s3.amazonaws.com/media/Kallidus_square.png',
  [Integration.KEAP]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformkeap_square.png',
  [Integration.KLAVIYO]:
    'https://merge-api-production.s3.amazonaws.com/media/klaviyo_square.png',
  [Integration.KUSTOMER]:
    'https://merge-api-production.s3.amazonaws.com/media/Kustomer_Square_Logo.jpeg',
  [Integration.LANO]:
    'https://merge-api-production.s3.amazonaws.com/media/Lano_Square_Logo.png',
  [Integration.LEVER]:
    'https://merge-api-production.s3.amazonaws.com/media/Lever_Square_Logo_oYg8yBW.jpg',
  [Integration.LINEAR]:
    'https://merge-api-production.s3.amazonaws.com/media/Linear_Square_Logo.png',
  [Integration.LUCCA]:
    'https://merge-api-production.s3.amazonaws.com/media/Lucca.jpg',
  [Integration.MAILCHIMP]:
    'https://merge-api-production.s3.amazonaws.com/media/mailchimp_square_FoKtczJ.png',
  [Integration.MESSAGEBIRD]:
    'https://merge-api-production.s3.amazonaws.com/media/messagebird_square_y8wjgQQ.png',
  [Integration.MICROSOFT_DYNAMICS_365_SALES]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformDynamics365Sales_square.png',
  [Integration.MICROSOFT_ENTRA_ID]:
    'https://merge-api-production.s3.amazonaws.com/media/Entra_ID_square_9CzLgQT.png',
  [Integration.MONEYBIRD]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformMoneybird.png',
  [Integration.NAMELY]:
    'https://merge-api-production.s3.amazonaws.com/media/Namely_Square_Logo.jpg',
  [Integration.NETSUITE]:
    'https://merge-api-production.s3.amazonaws.com/media/NetSuite_Square_Logo.png',
  [Integration.NMBRS]:
    'https://merge-api-production.s3.amazonaws.com/media/Nmbrs_Square_Logo.png',
  [Integration.NUTSHELL]:
    'https://merge-api-production.s3.amazonaws.com/media/Nutshell.png',
  [Integration.OFFICIENT]:
    'https://merge-api-production.s3.amazonaws.com/media/Officient_square.png',
  [Integration.OKTA]:
    'https://merge-api-production.s3.amazonaws.com/media/Okta_square.png',
  [Integration.ONEDRIVE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformOnedrive_square.png',
  [Integration.ONELOGIN]:
    'https://merge-api-production.s3.amazonaws.com/media/OneLogin_square.png',
  [Integration.ORACLE_TALEO]:
    'https://merge-api-production.s3.amazonaws.com/media/Taleo_Square_Logo.jpg',
  [Integration.PAYCHEX]:
    'https://merge-api-production.s3.amazonaws.com/media/Paychex_Square_Logo.png',
  [Integration.PAYLOCITY]:
    'https://merge-api-production.s3.amazonaws.com/media/Paylocity_Square_Logo_LGm1Fdn.png',
  [Integration.PEOPLEHR]:
    'https://merge-api-production.s3.amazonaws.com/media/PeopleHR.png',
  [Integration.PERSONIO]:
    'https://merge-api-production.s3.amazonaws.com/media/Personio_Square_Logo.jpg',
  [Integration.PIPEDRIVE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformPipedrive.jpg',
  [Integration.PIPELINER]:
    'https://merge-api-production.s3.amazonaws.com/media/Pipeliner_square.png',
  [Integration.PIVOTAL_TRACKER]:
    'https://merge-api-production.s3.amazonaws.com/media/PivotalTracker_square.png',
  [Integration.PODIUM]:
    'https://merge-api-production.s3.amazonaws.com/media/podium_square.png',
  [Integration.POLYMER]:
    'https://merge-api-production.s3.amazonaws.com/media/Polymer_square.png',
  [Integration.PROLIANT]:
    'https://merge-api-production.s3.amazonaws.com/media/Proliant_Square_Logo.jpg',
  [Integration.QUICKBOOKS_ONLINE]:
    'https://merge-api-production.s3.amazonaws.com/media/QuickBooks-square_Uf26B71.png',
  [Integration.REAMAZE]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformre_amaze_square.png',
  [Integration.RECRUITERFLOW]:
    'https://merge-api-production.s3.amazonaws.com/media/RecruiterFlow_Square_Logo_0pzjFF2.jpg',
  [Integration.SAGE_BUSINESS_CLOUD_ACCOUNTING]:
    'https://merge-api-production.s3.amazonaws.com/media/Sage_BC_Square_Logo_1.png',
  [Integration.SAGE_HR]:
    'https://merge-api-production.s3.amazonaws.com/media/Sage_Square_Logo_FAQpKpP.jpg',
  [Integration.SALESFLARE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformSalesflare.jpg',
  [Integration.SALESFORCE]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformSalesforce.png',
  [Integration.SALESFORCE_SERVICE_CLOUD]:
    'https://merge-api-production.s3.amazonaws.com/media/Salesforce_service_cloud.png',
  [Integration.SAP_SUCCESSFACTORS]:
    'https://merge-api-production.s3.amazonaws.com/media/SuccessFactors_Square_Logo_BcXogF0.jpg',
  [Integration.SENDGRID]:
    'https://merge-api-production.s3.amazonaws.com/media/sendgrid_square.png',
  [Integration.SENDINBLUE]:
    'https://merge-api-production.s3.amazonaws.com/media/sendinblue_square.png',
  [Integration.SERVICENOW]:
    'https://merge-api-production.s3.amazonaws.com/media/ServiceNow_Square_Logo.png',
  [Integration.SESAME]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformSesame.png',
  [Integration.SHAREPOINT]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformSharepoint_square.png',
  [Integration.SHORTCUT]:
    'https://merge-api-production.s3.amazonaws.com/media/Shortcut_Square_Logo.jpeg',
  [Integration.SLACK]: 'https://kindo-assets.s3.amazonaws.com/slack.png',
  [Integration.SMARTRECRUITERS]:
    'https://merge-api-production.s3.amazonaws.com/media/SmartRecruiters_Square_Logo_j8vLfEW.png',
  [Integration.SPOTDRAFT]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformspotdraft_square.png',
  [Integration.SQUARE_PAYROLL]:
    'https://merge-api-production.s3.amazonaws.com/media/Square_Square_Logo.jpg',
  [Integration.SUGARCRM]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformsugarcrm_square.png',
  [Integration.TALENTLYFT]:
    'https://merge-api-production.s3.amazonaws.com/media/TalentLyft_Square_Logo.png',
  [Integration.TALENTREEF]:
    'https://merge-api-production.s3.amazonaws.com/media/TalentReef_Square_Logo.png',
  [Integration.TEAMLEADER]:
    'https://merge-api-production.s3.amazonaws.com/media/Teamleader.png',
  [Integration.TEAMTAILOR]:
    'https://merge-api-production.s3.amazonaws.com/media/Teamtailor_Square_Logo.jpg',
  [Integration.TEAMWORK]:
    'https://merge-api-production.s3.amazonaws.com/media/Teamwork_Square_Logo.png',
  [Integration.TEAMWORK_CRM]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformTeamwork_CRM_square.jpg',
  [Integration.TELLENT]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformGoogle_Drive_square.png',
  [Integration.TRELLO]:
    'https://merge-api-production.s3.amazonaws.com/media/Trello_Square_Logo_WsxDwGx.png',
  [Integration.TRINET]:
    'https://merge-api-production.s3.amazonaws.com/media/Trinet.png',
  [Integration.TRINET_HR_PLATFORM]:
    'https://merge-api-production.s3.amazonaws.com/media/Trinet_1.png',
  [Integration.UKG_PRO]:
    'https://merge-api-production.s3.amazonaws.com/media/UKG_Square_Logo_fl9LQnt.png',
  [Integration.UKG_PRO_RECRUITING]:
    'https://merge-api-production.s3.amazonaws.com/media/UKG_Square_Logo_zTMYILE.png',
  [Integration.UKG_PRO_WORKFORCE_MANAGEMENT]:
    'https://merge-api-production.s3.amazonaws.com/media/UKG_Square_Logo_fl9LQnt_jYRGZr5_aoP8O9H.png',
  [Integration.UKG_READY]:
    'https://merge-api-production.s3.amazonaws.com/media/UKG_Square_Logo_fl9LQnt_jYRGZr5.png',
  [Integration.VTIGER]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformvtiger_square.png',
  [Integration.WAVE_FINANCIAL]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformWave_Hfdx20t.png',
  [Integration.WORKABLE]:
    'https://merge-api-production.s3.amazonaws.com/media/Workable_Square_Logo_OvllNl9.jpg',
  [Integration.WORKDAY]:
    'https://merge-api-production.s3.amazonaws.com/media/Workday_Square_Logo.jpg',
  [Integration.WRIKE]:
    'https://merge-api-production.s3.amazonaws.com/media/Wrike_Square_Logo.png',
  [Integration.XERO]:
    'https://merge-api-production.s3.amazonaws.com/media/Xero-square.png',
  [Integration.ZENDESK]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformZendesk_9qMuXiF.png',
  [Integration.ZENDESK_SELL]:
    'https://merge-api-production.s3.amazonaws.com/media/PlatformZendesk_8wt2TTW.png',
  [Integration.ZOHO_BOOKS]:
    'https://merge-api-production.s3.amazonaws.com/media/ZohoBooks_Square_Logo.png',
  [Integration.ZOHO_BUGTRACKER]:
    'https://merge-api-production.s3.amazonaws.com/media/ZohoBugTracker_square.png',
  [Integration.ZOHO_CRM]:
    'https://merge-api-production.s3.amazonaws.com/media/Platformzohocrm_square.png',
  [Integration.ZOHO_DESK]:
    'https://merge-api-production.s3.amazonaws.com/media/Zoho_Desk_Square_Logo.png'
} as const satisfies Record<Integration, string>;

export const getIntegrationFromMergeIntegrationSlug = (
  mergeIntegrationSlug: string | null
): Integration | undefined =>
  Object.entries(INTEGRATION_TO_MERGE_INTEGRATION_SLUG).find(
    ([, value]) => value === mergeIntegrationSlug
  )?.[0] as Integration;

export const getMergeIntegrationSlugFromIntegration = (
  integration: MergeIntegration
): string => INTEGRATION_TO_MERGE_INTEGRATION_SLUG[integration];

export const getIntegrationDisplayName = (integration: Integration): string =>
  INTEGRATION_DISPLAY_NAMES[integration];

/**
 * Integrations listed here will allow any user to connect,
 * otherwise it will encourage users to contact sales to request
 * the enablement of a particular integration.
 *
 * The isSupportedIntegrationAndCategory function should always
 * be used to determine which integrations are supported.
 */
const BASE_DISPLAYED_INTEGRATIONS = [
  Integration.BOX,
  Integration.GOOGLE_DRIVE,
  Integration.ONEDRIVE,
  Integration.SLACK
] as const satisfies readonly Integration[];

const FEATURE_FLAG_DISPLAYED_INTEGRATIONS = [
  Integration.SERVICENOW,
  Integration.LINEAR
] as const satisfies readonly Integration[];

export type BaseDisplayedIntegration =
  (typeof BASE_DISPLAYED_INTEGRATIONS)[number];

export type FeatureFlagDisplayedIntegration =
  (typeof FEATURE_FLAG_DISPLAYED_INTEGRATIONS)[number];

const SUPPORTED_INTEGRATION_CATEGORIES = [
  IntegrationCategory.FILE_STORAGE,
  IntegrationCategory.TICKETING,
  IntegrationCategory.OTHER
] as const satisfies readonly IntegrationCategory[];

export function isBaseDisplayedSupportedIntegrationAndCategory(
  integration: Integration | undefined,
  category: IntegrationCategory
): integration is BaseDisplayedIntegration {
  return (
    BASE_DISPLAYED_INTEGRATIONS.includes(integration as any) &&
    SUPPORTED_INTEGRATION_CATEGORIES.includes(category as any)
  );
}

export function isFeatureFlagDisplayedIntegration(
  integration: Integration | undefined,
  category: IntegrationCategory
): integration is FeatureFlagDisplayedIntegration {
  return (
    FEATURE_FLAG_DISPLAYED_INTEGRATIONS.includes(integration as any) &&
    SUPPORTED_INTEGRATION_CATEGORIES.includes(category as any)
  );
}

export function isSupportedIntegration(
  integration: Integration | undefined
): integration is BaseDisplayedIntegration | FeatureFlagDisplayedIntegration {
  return (
    BASE_DISPLAYED_INTEGRATIONS.includes(integration as any) ||
    FEATURE_FLAG_DISPLAYED_INTEGRATIONS.includes(integration as any)
  );
}

// Supported integrations is a union of prod displayed integrations and
// feature flag displayed integrations
export function isSupportedIntegrationAndCategory(
  integration: Integration | undefined,
  category: IntegrationCategory
) {
  return (
    integration !== undefined &&
    (isBaseDisplayedSupportedIntegrationAndCategory(integration, category) ||
      isFeatureFlagDisplayedIntegration(integration, category))
  );
}

// TODO: [ENG-3778] Need to clean up/reorganize how we are relating
// merge categories to integrations to input types
export const WORKFLOW_SUPPORTED_INTEGRATIONS = [
  Integration.SERVICENOW,
  Integration.LINEAR
] as const satisfies readonly (
  | BaseDisplayedIntegration
  | FeatureFlagDisplayedIntegration
)[];

export type WorkflowSupportedIntegration =
  (typeof WORKFLOW_SUPPORTED_INTEGRATIONS)[number];

export function isWorkflowSupportedIntegration(
  integration: Integration | string
): integration is WorkflowSupportedIntegration {
  return (
    WORKFLOW_SUPPORTED_INTEGRATIONS.includes(integration as any) ||
    Object.values(INTEGRATION_DISPLAY_NAMES).includes(integration as any)
  );
}

export function isMergeIntegration(
  integration: Integration
): integration is MergeIntegration {
  return !NON_MERGE_INTEGRATIONS.includes(integration as any);
}

export function getSquareImageFromIntegrationName(integrationName: string) {
  const integration = Object.entries(
    INTEGRATION_TO_MERGE_INTEGRATION_SLUG
  ).find(([, value]) => value === integrationName)?.[0] as Integration;
  return integration ? INTEGRATION_TO_SQUARE_IMAGE[integration] : undefined;
}

export function getIntegrationFromIntegrationDisplayName(
  integrationName: string
) {
  return Object.entries(INTEGRATION_DISPLAY_NAMES).find(
    ([, value]) => value === integrationName
  )?.[0] as Integration;
}

export function getIntegrationCategoryFromMergeCategory(
  mergeIntegrationCategory: string
): IntegrationCategory | undefined {
  return Object.entries(INTEGRATION_CATEGORY_TO_MERGE_CATEGORY).find(
    ([, value]) => value === mergeIntegrationCategory
  )?.[0] as IntegrationCategory;
}

// The Merge API times out for files larger than roughly 0.5 GB. Until they've fixed
// this issue, we skip files larger than 0.5 GB.
export const MERGE_DOWNLOAD_FILE_SIZE_LIMIT = 5 * 100 * 1000 * 1000; // 0.5 GB

export const getIntegrationCategoriesFromIntegration = (
  integration: Integration
): IntegrationCategory[] => INTEGRATION_TO_CATEGORIES[integration];

export const integrationIsInCategory = (
  integration: Integration,
  category: IntegrationCategory
) => getIntegrationCategoriesFromIntegration(integration).includes(category);
