import _styled from "styled-components";
import { Popper } from '@mui/base/Popper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, ButtonType } from '../Button';
import SelectOptionComponent from './SelectOption';
import { Icon, Color, TypographySize } from '~/components/core';
export enum SelectPlacement {
  BOTTOM = 'bottom',
  TOP = 'top',
}
const SelectContainer = _styled.div.withConfig({
  displayName: "Select__SelectContainer",
  componentId: "sc-59xneq-0"
})({
  "position": "relative",
  "display": "flex",
  "maxWidth": "100%",
  "alignItems": "center"
});
const SelectListBox = _styled.ul.withConfig({
  displayName: "Select__SelectListBox",
  componentId: "sc-59xneq-1"
})(["", " aria-activedescendant=\"listbox-option-3\" aria-labelledby=\"listbox-label\" role=\"listbox\" tabIndex={-1}"], {
  "maxHeight": "14rem",
  "width": "fit-content",
  "minWidth": "8.5rem",
  "cursor": "pointer",
  "overflow": "auto",
  "borderRadius": "0.375rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))",
  "padding": "0.5rem",
  "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "&:focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  }
});
const SelectInput = _styled.input.withConfig({
  displayName: "Select__SelectInput",
  componentId: "sc-59xneq-2"
})(({
  $hasExistingOptions
}) => [{
  "marginBottom": "0.25rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, $hasExistingOptions && {
  "borderBottomWidth": "0.063rem",
  "borderColor": "#8CAEBA33"
}]);
export interface SelectOption<Value extends string | null = string> {
  label: string;
  value: Value;
  destructive?: boolean;
  disabled?: boolean;
  endIcon?: {
    icon: Icon;
    color?: Color;
  };
  onClick?: (value: Value) => void;
  prefix?: string;
  startIcon?: {
    icon: Icon;
    color?: Color;
  };
  suffix?: {
    text: string;
    color?: Color;
    size?: TypographySize;
  };
  tooltip?: string;
}
type SelectProps<Value extends string | null = string> = {
  onChange: (value: Value) => void;
  options: SelectOption<Value>[];
  placeholderLabel: string;
  value: Value;
  allowInput?: boolean;
  'data-id'?: string | undefined;
  disabled?: boolean;
  hideChevron?: boolean;
  inputPlaceholder?: string;
  placement?: SelectPlacement;
  prefix?: string;
  reservedValues?: string[];
  startIcon?: Icon | undefined;
  tooltip?: string | undefined;
  type?: ButtonType;
};
const Select = <Value extends string | null = string,>({
  onChange,
  options,
  value,
  placeholderLabel,
  allowInput = false,
  'data-id': dataId,
  disabled,
  inputPlaceholder,
  startIcon,
  placement = SelectPlacement.BOTTOM,
  prefix,
  reservedValues,
  type = ButtonType.OUTLINED,
  hideChevron = false,
  tooltip
}: SelectProps<Value>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const selectedOption = options.find(option => option.value === value);

  // If the value was typed as the input value and does not exist as an option,
  // use the typed value as the label
  const label = value && !selectedOption ? value : selectedOption?.label || placeholderLabel;
  const selectValue = (newValue: Value) => {
    setAnchorEl(null);
    onChange(newValue);
  };
  const handleClickOutside = useCallback((event: {
    target: any;
  }) => {
    if (containerRef.current && popperRef.current && !containerRef.current.contains(event.target) && !popperRef.current.contains(event.target)) {
      if (inputValue) {
        selectValue(inputValue as Value);
        return;
      }
      setAnchorEl(null);
    }
  }, [inputValue]);

  // Set click outside listeners
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
  const handleClickSelect = (newValue: Value) => {
    setInputValue('');
    selectValue(newValue);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // Capture the value synchronously
    // Without this, it will not open in the first subsequent click after closing
    const {
      currentTarget
    } = event;
    setAnchorEl(prevAnchorEl => prevAnchorEl ? null : currentTarget);
  };
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // If Enter is pressed and new values are allowed, select the input value
    if (event.key === 'Enter' && allowInput && !reservedValues?.includes(inputValue)) {
      selectValue(inputValue as Value);
    }
  };
  const open = Boolean(anchorEl);
  return <SelectContainer ref={containerRef}>
      <Button active={open} data-id={dataId} disabled={disabled} endIcon={!hideChevron ? Icon.CHEVRON_DOWN : undefined} label={(prefix ?? '') + label} onClick={handleClick} startIcon={startIcon} tooltip={tooltip ?? ''} type={type} />
      {!disabled && <>
          {/* https://mui.com/base-ui/react-popper/ */}
          <Popper ref={popperRef} anchorEl={anchorEl} open={open} placement={placement} slotProps={{
        root: {
          className: 'z-50 font-sans-3'
        }
      }}>
            <SelectListBox>
              {allowInput && <SelectInput $hasExistingOptions={!!options.length} autoFocus onChange={e => setInputValue(e.target.value)} onKeyDown={handleInputKeyDown} placeholder={inputPlaceholder} value={inputValue} />}
              {options.map(option => <SelectOptionComponent<Value> key={option.value} destructive={option.destructive ?? false} disabled={option.disabled ?? false} endIcon={option.endIcon} isSelected={option.value === value} label={option.label} onClick={option.onClick ?? handleClickSelect} prefix={option.prefix ?? ''} startIcon={option.startIcon} suffix={option.suffix ?? {
            text: ''
          }} tooltip={option.tooltip ?? ''} value={option.value} />)}
            </SelectListBox>
          </Popper>
        </>}
    </SelectContainer>;
};
export default Select;