import { CerbosWorkflowAction } from '@kindo/universal';

import { nextTrpc } from '~/trpc';

const useCanShareWorkflow = (workflowId: string | undefined) => {
  const { data } = nextTrpc.workflows.isAllowed.useQuery(
    {
      action: CerbosWorkflowAction.SHARE_WORKFLOW,
      id: workflowId ?? ''
    },
    {
      enabled: !!workflowId,
      refetchOnWindowFocus: false
    }
  );

  return { canShareWorkflow: data ?? false };
};

export default useCanShareWorkflow;
