import {
  Integration,
  INTEGRATION_CATEGORY_TO_MERGE_CATEGORY,
  INTEGRATION_TO_MERGE_INTEGRATION_SLUG,
  IntegrationCategory,
  isMergeIntegration
} from '@kindo/universal';
import { useState } from 'react';

import { AvailableIntegrationWithDisplayName } from '../components/Settings/Integrations/types';

import { useMergeIntegrationPopUp, useSlackIntegrationConnect } from '~/hooks';

const useIntegrationConnect = () => {
  const [refresh, setRefresh] = useState(false);

  const { openMergePopUp } = useMergeIntegrationPopUp(setRefresh);
  const { openSlack } = useSlackIntegrationConnect();

  const connect = (integration: AvailableIntegrationWithDisplayName) => {
    if (isMergeIntegration(integration.integrationName)) {
      if (integration.integrationCategory === IntegrationCategory.OTHER)
        return null;
      return openMergePopUp(
        INTEGRATION_CATEGORY_TO_MERGE_CATEGORY[integration.integrationCategory],
        INTEGRATION_TO_MERGE_INTEGRATION_SLUG[integration.integrationName]
      );
    }

    if (integration.integrationName === Integration.SLACK) {
      return openSlack();
    }

    console.error('Unknown category:', integration);
    return null;
  };

  return { connect, refresh };
};

export default useIntegrationConnect;
