import { getObjectKeysTyped } from '@kindo/universal';
import { useRouter } from 'next/router';

interface UseTabUrlNavigationArgs<
  Tab extends string,
  TabSubTabs extends Record<Tab, string>
> {
  baseRoute: string;
  defaultActiveTab: Tab;
  tabsWithRoutes: Record<Tab, string>;
  subTabs?: {
    defaultSubTabs: { [K in Tab]?: TabSubTabs[K] };
    subTabsWithRoutes: { [K in Tab]: Record<TabSubTabs[K], string> };
  };
}

interface UseTabUrlNavigationReturn<
  Tab extends string,
  TabSubTabs extends Record<Tab, string>,
  ActiveTab extends Tab = Tab
> {
  activeSubTab: TabSubTabs[ActiveTab] | undefined;
  activeTab: ActiveTab;
  setActiveSubTab: (subtab: TabSubTabs[Tab]) => void;
  setActiveTab: (tab: Tab) => void;
}

const useTabUrlNavigation = <
  Tab extends string,
  TabSubTabs extends Record<Tab, string> = Record<Tab, never>
>({
  defaultActiveTab,
  tabsWithRoutes,
  baseRoute,
  subTabs
}: UseTabUrlNavigationArgs<Tab, TabSubTabs>): UseTabUrlNavigationReturn<
  Tab,
  TabSubTabs
> => {
  const router = useRouter();

  const { defaultSubTabs, subTabsWithRoutes } = subTabs || {};

  const [activeTabRoute = tabsWithRoutes[defaultActiveTab], activeSubTabRoute] =
    router.query.tabs ?? [];

  const activeTab: Tab =
    getObjectKeysTyped(tabsWithRoutes).find(
      (tab) => tabsWithRoutes[tab as Tab] === activeTabRoute
    ) || defaultActiveTab;

  const activeSubTab: TabSubTabs[Tab] | undefined = (getObjectKeysTyped(
    subTabsWithRoutes?.[activeTab] ?? []
  ).find(
    (subTab) =>
      subTabsWithRoutes?.[activeTab][subTab as TabSubTabs[Tab]] ===
      activeSubTabRoute
  ) || defaultSubTabs?.[activeTab]) as TabSubTabs[Tab] | undefined;

  const setActiveTab = (tab: Tab) =>
    router.push(`${baseRoute}/${tabsWithRoutes[tab]}`);

  const setActiveSubTab = async (subtab: TabSubTabs[Tab]) => {
    if (!subTabsWithRoutes) {
      console.error(
        'Failed to set active subtab, subtabsWithRoutes is undefined'
      );
      return;
    }

    await router.push(
      `${baseRoute}/${tabsWithRoutes[activeTab]}/${subTabsWithRoutes[activeTab][subtab]}`
    );
  };

  return {
    activeTab,
    activeSubTab,
    setActiveTab,
    setActiveSubTab
  };
};

export default useTabUrlNavigation;
