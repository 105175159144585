import { OrgSetting, OrgSettingsConfig } from '@kindo/universal';

import useToast, { ToastType } from './useToast';

import { nextTrpc } from '~/trpc';

interface UseOrgSettingsReturnObject {
  isLoading: boolean;
  orgSettings: OrgSettingsConfig | undefined;
  updateOrgSetting: <K extends OrgSetting>(
    setting: K,
    value: OrgSettingsConfig[K]
  ) => void;
}

const useOrgSettings = (): UseOrgSettingsReturnObject => {
  // Custom hooks
  const { enqueueToast } = useToast();
  // Get the settings associated with the org
  const {
    data: orgSettings,
    isLoading,
    refetch: refetchOrgSettings
  } = nextTrpc.orgs.getOrgSettings.useQuery();

  // /Update OrgSetting mutation
  const updateOrgSettingsMutation = nextTrpc.orgs.updateOrgSettings.useMutation(
    {
      onSuccess: () => {
        enqueueToast({
          message: 'Sharing permissions updated.',
          type: ToastType.SUCCESS
        });
        refetchOrgSettings();
      },
      onError: (error) => {
        console.error(error);
        enqueueToast({
          message:
            error.data?.kindoErrorMessage ||
            'Failed to update sharing permissions.',
          type: ToastType.ERROR
        });
      }
    }
  );

  const updateOrgSetting = <K extends OrgSetting>(
    setting: K,
    value: OrgSettingsConfig[K]
  ) => {
    updateOrgSettingsMutation.mutate({
      [setting]: value
    });
  };

  return {
    orgSettings,
    updateOrgSetting,
    isLoading
  };
};

export default useOrgSettings;
