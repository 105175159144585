/** Integration Data Models, (often based on Merge but decoupled) */
/** Named as <category>Integration<model> */
/** Categories come from integrations.consts.ts */

import { z } from 'zod';

import { Integration } from '../../constants';

// Based on MergeTicketingIntegrationUser
export const zTicketingIntegrationUser = z.object({
  email: z.string().nullable(),
  id: z.string(),
  name: z.string().nullable()
});

export type TicketingIntegrationUser = z.infer<
  typeof zTicketingIntegrationUser
>;

// TODO: Finish adding all fields
// TODO: Create transformer from MergeTicketingIntegrationTicket to TicketingIntegrationTicket
// TODO: Make Zod schema so that we can safe parse from WorkflowInputValue's stored in DB

/**
 * This enum represents the keys of our ticket integration data schema (zTicketingIntegrationTicket)
 *
 * They exist as an enum since they're referenced
 * and stored as specified fields in WorkflowInputStep config.
 *
 * IMPORTANT: These values are saved in the DB as specified fields in WorkflowInputStep config,
 * and should not be changed.
 */
export enum TicketingIntegrationTicketField {
  ASSIGNEES = 'assignees',
  COMPLETED_AT = 'completed_at',
  CREATED_AT = 'created_at',
  CREATOR = 'creator',
  DESCRIPTION = 'description',
  DUE_DATE = 'due_date',
  INTEGRATION_NAME = 'integrationName',
  MERGE_ID = 'mergeId',
  MODIFIED_AT = 'modified_at',
  PRIORITY = 'priority',
  STATUS = 'status',
  TICKET_ID = 'ticketId',
  TITLE = 'title',
  URL = 'url'
}

export const TICKETING_INTEGRATION_TICKET_FIELDS = Object.values(
  TicketingIntegrationTicketField
);

// Similar MergeTicketingIntegrationTicket, with some fields removed/modified
// TODO: Add all required fields
export const zTicketingIntegrationTicket = z.object({
  [TicketingIntegrationTicketField.ASSIGNEES]: z
    .array(zTicketingIntegrationUser)
    .nullable(),
  [TicketingIntegrationTicketField.COMPLETED_AT]: z
    .string()
    .datetime()
    .nullable(),
  [TicketingIntegrationTicketField.CREATED_AT]: z
    .string()
    .datetime()
    .nullable(),
  [TicketingIntegrationTicketField.CREATOR]:
    zTicketingIntegrationUser.nullable(),
  [TicketingIntegrationTicketField.DESCRIPTION]: z.string().nullable(),
  [TicketingIntegrationTicketField.DUE_DATE]: z.string().datetime().nullable(),
  [TicketingIntegrationTicketField.TICKET_ID]: z.string().nullable(),
  [TicketingIntegrationTicketField.INTEGRATION_NAME]: z.nativeEnum(Integration),
  [TicketingIntegrationTicketField.MODIFIED_AT]: z
    .string()
    .datetime()
    .nullable(),
  // TODO: [ENG-4153] Properly normalize PRIORITY values
  [TicketingIntegrationTicketField.PRIORITY]: z.string().nullable(),
  // TODO: [ENG-4153] Properly normalize STATUS values
  [TicketingIntegrationTicketField.STATUS]: z.string().nullable(),
  [TicketingIntegrationTicketField.TITLE]: z.string().nullable(),
  [TicketingIntegrationTicketField.URL]: z.string().url().nullable()
});

export type TicketingIntegrationTicket = z.infer<
  typeof zTicketingIntegrationTicket
>;

export const zMergeTicketingIntegrationTicket =
  zTicketingIntegrationTicket.extend({
    [TicketingIntegrationTicketField.INTEGRATION_NAME]:
      z.nativeEnum(Integration), // TODO: Make this MergeIntegration
    [TicketingIntegrationTicketField.MERGE_ID]: z.string()
  });

export type MergeTicketingIntegrationTicket = z.infer<
  typeof zMergeTicketingIntegrationTicket
>;
