import { Icon } from '~/components/core';
import { DropdownItemProps } from '~/components/Dropdown/DropdownItem';

interface UseFileDropdownItemsReturn {
  getFileDropdownItems: (args: {
    contentId: string;
    onContentRemove: () => void;
  }) => DropdownItemProps[];
}

const useFileDropdownItems = (): UseFileDropdownItemsReturn => {
  const getFileDropdownItems = ({
    onContentRemove
  }: {
    contentId: string;
    onContentRemove: () => void;
  }): DropdownItemProps[] => [
    {
      title: 'Remove file',
      icon: Icon.TRASH,
      onClick: () => onContentRemove(),
      destructive: true
    }
  ];

  return { getFileDropdownItems };
};

export default useFileDropdownItems;
