export enum FeatureFlags {
  CODE_SANDBOX = 'CODE_SANDBOX',
  CONNECTABLE_INTEGRATIONS = 'CONNECTABLE_INTEGRATIONS',
  FEATURE_FLAGGED_LLMS = 'FEATURE_FLAGGED_LLMS',
  KNOWLEDGE_STORE = 'KNOWLEDGE_STORE',
  MARKDOWN_SYSTEM_PROMPT = 'MARKDOWN_SYSTEM_PROMPT',
  ORG_SHARING_PERMISSIONS = 'ORG_SHARING_PERMISSIONS',
  SLACK_INTEGRATION = 'SLACK_INTEGRATION',
  TICKETING_INTEGRATION_AGENTS = 'TICKETING_INTEGRATION_AGENTS',
  UNLIMITED_CREDIT_USAGE = 'UNLIMITED_CREDIT_USAGE'
}
