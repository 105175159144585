import { useEffect, useState } from 'react';

const useMarkdownFile = (fileSrc: string) => {
  const [markdown, setMarkdown] = useState<string>('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      const res = await fetch(fileSrc);
      const text = await res.text();
      setMarkdown(text);
    };

    void fetchMarkdown();
  }, [fileSrc]);

  return { markdown };
};

export default useMarkdownFile;
