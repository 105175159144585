import { useEffect } from 'react';

// Updates the height of a <textarea> when the value changes.
const useAutoGrow = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  minHeight: number,
  maxHeight: number = 9999
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.setProperty('height', '0px');
      const { scrollHeight } = textAreaRef;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      const height = Math.max(Math.min(scrollHeight, maxHeight), minHeight);
      textAreaRef.style.setProperty('height', height + 'px');
    }
  }, [textAreaRef, value, maxHeight, minHeight]);
};

export default useAutoGrow;
