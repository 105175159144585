import { useEffect, useRef } from 'react';

/**
 * This function runs when the parent component is unmounted.
 * Most importantly, it will run with the latest values of the dependencies.
 */
const useOnComponentUnmount = (
  callback: () => void,
  dependencies: React.DependencyList
) => {
  const isUnmounting = useRef<boolean>(false);

  useEffect(
    () => () => {
      isUnmounting.current = true;
    },
    []
  );

  useEffect(
    () => () => {
      if (isUnmounting.current) {
        callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );
};

export default useOnComponentUnmount;
