import { DlpFiltersConfig } from '@prisma/client';

import { ExternalLlm, Integration, Llm, Provider } from '../constants';

export type AdminSecurityConfigResource = Integration | Llm | Provider;

export enum AdminSecurityConfigResourceType {
  INTEGRATION = 'INTEGRATION',
  MODEL = 'MODEL',
  // MODEL_AND_INTEGRATION = 'MODEL_AND_INTEGRATION',
  PROVIDER = 'PROVIDER'
  // PROVIDER_AND_INTEGRATION = 'PROVIDER_AND_INTEGRATION'
}

export interface AdminSecurityConfigResourceTypeToResource {
  [AdminSecurityConfigResourceType.INTEGRATION]: Integration;
  [AdminSecurityConfigResourceType.MODEL]: Llm;
  [AdminSecurityConfigResourceType.PROVIDER]: Provider;
}

export interface AdminSecurityConfigResourceTypeToConfig {
  [AdminSecurityConfigResourceType.INTEGRATION]: IntegrationSecurityConfig;
  [AdminSecurityConfigResourceType.MODEL]: ModelSecurityConfig;
  [AdminSecurityConfigResourceType.PROVIDER]: ProviderSecurityConfig;
}
export interface ProviderSecurityConfig {
  accessEnabled: boolean;
  dlpEnabled: boolean;
  dlpFiltersConfig: DlpFiltersConfig | null;
  hasCustomDlpFiltersConfig: boolean;
  provider: Provider;
}

export interface ModelSecurityConfig {
  accessEnabled: boolean;
  dlpEnabled: boolean;
  dlpFiltersConfig: DlpFiltersConfig | null;
  hasCustomDlpFiltersConfig: boolean;
  model: ExternalLlm;
}

export const isProviderSecurityConfig = (
  config: ProviderSecurityConfig | ModelSecurityConfig
): config is ProviderSecurityConfig => 'provider' in config;

export interface IntegrationSecurityConfig {
  accessEnabled: boolean;
  dlpEnabled: boolean;
  dlpFiltersConfig: DlpFiltersConfig | null;
  hasCustomDlpFiltersConfig: boolean;
  integration: Integration;
}

export const INTEGRATION_ACCESS_ENABLED_DEFAULTS: Record<Integration, boolean> =
  {
    [Integration.ACCELO]: true,
    [Integration.ACTIVECAMPAIGN]: true,
    [Integration.ADP_WORKFORCE_NOW]: true,
    [Integration.AFFINITY]: true,
    [Integration.ALEXISHR]: true,
    [Integration.ALTERA_PAYROLL]: true,
    [Integration.APPLICANTSTACK]: true,
    [Integration.ASANA]: true,
    [Integration.ASHBY]: true,
    [Integration.AZURE_DEVOPS]: true,
    [Integration.BAMBOOHR]: true,
    [Integration.BASECAMP]: true,
    [Integration.BITBUCKET]: true,
    [Integration.BOX]: true,
    [Integration.BREATHE]: true,
    [Integration.BREEZY]: true,
    [Integration.CAPSULE]: true,
    [Integration.CATS]: true,
    [Integration.CERIDIAN_DAYFORCE]: true,
    [Integration.CHARLIE]: true,
    [Integration.CHARTHOP]: true,
    [Integration.CLAYHR]: true,
    [Integration.CLEAR_BOOKS]: true,
    [Integration.CLICKUP]: true,
    [Integration.CLOCKWORK]: true,
    [Integration.CLOSE]: true,
    [Integration.COMEET]: true,
    [Integration.COPPER]: true,
    [Integration.CORNERSTONE_TALENTLINK]: true,
    [Integration.CUSTOMERIO]: true,
    [Integration.DAYFORCE]: true,
    [Integration.DIXA]: true,
    [Integration.DROPBOX]: true,
    [Integration.EPLOY]: true,
    [Integration.ENGAGEATS]: true,
    [Integration.FACTORIAL]: true,
    [Integration.FOUNTAIN]: true,
    [Integration.FREEAGENT]: true,
    [Integration.FRESHBOOKS]: true,
    [Integration.FRESHDESK]: true,
    [Integration.FRESHSERVICE]: true,
    [Integration.FRESHTEAM]: true,
    [Integration.FRONT]: true,
    [Integration.GETRESPONSE]: true,
    [Integration.GITHUB_ISSUES]: true,
    [Integration.GITLAB]: true,
    [Integration.GLADLY]: true,
    [Integration.GOOGLE_DRIVE]: true,
    [Integration.GOOGLE_WORKSPACE]: true,
    [Integration.GORGIAS]: true,
    [Integration.GREENHOUSE]: true,
    [Integration.GREENHOUSE_JOB_BOARDS_API]: true,
    [Integration.GUSTO]: true,
    [Integration.HEIGHT]: true,
    [Integration.HELP_SCOUT]: true,
    [Integration.HIBOB]: true,
    [Integration.HIVE]: true,
    [Integration.HOMERUN]: true,
    [Integration.HR_CLOUD]: true,
    [Integration.HR_PARTNER]: true,
    [Integration.HUBSPOT]: true,
    [Integration.HUBSPOT_MARKETING_HUB]: true,
    [Integration.HUMAANS]: true,
    [Integration.INSIGHTLY]: true,
    [Integration.INTELLIHR]: true,
    [Integration.INTERCOM]: true,
    [Integration.IRONCLAD]: true,
    [Integration.JAZZHR]: true,
    [Integration.JIRA]: true,
    [Integration.JIRA_SERVICE_MANAGEMENT]: true,
    [Integration.JOBSCORE]: true,
    [Integration.JOBSOID]: true,
    [Integration.JOBVITE]: true,
    [Integration.JUMPCLOUD]: true,
    [Integration.JUSTWORKS]: true,
    [Integration.KALLIDUS]: true,
    [Integration.KEAP]: true,
    [Integration.KLAVIYO]: true,
    [Integration.KUSTOMER]: true,
    [Integration.LANO]: true,
    [Integration.LEVER]: true,
    [Integration.LINEAR]: true,
    [Integration.LUCCA]: true,
    [Integration.MAILCHIMP]: true,
    [Integration.MESSAGEBIRD]: true,
    [Integration.MICROSOFT_DYNAMICS_365_SALES]: true,
    [Integration.MICROSOFT_ENTRA_ID]: true,
    [Integration.MONEYBIRD]: true,
    [Integration.NAMELY]: true,
    [Integration.NETSUITE]: true,
    [Integration.NMBRS]: true,
    [Integration.NUTSHELL]: true,
    [Integration.OFFICIENT]: true,
    [Integration.OKTA]: true,
    [Integration.ONEDRIVE]: true,
    [Integration.ONELOGIN]: true,
    [Integration.ORACLE_TALEO]: true,
    [Integration.PAYCHEX]: true,
    [Integration.PAYLOCITY]: true,
    [Integration.PEOPLEHR]: true,
    [Integration.PERSONIO]: true,
    [Integration.PIPEDRIVE]: true,
    [Integration.PIPELINER]: true,
    [Integration.PIVOTAL_TRACKER]: true,
    [Integration.PODIUM]: true,
    [Integration.POLYMER]: true,
    [Integration.PROLIANT]: true,
    [Integration.QUICKBOOKS_ONLINE]: true,
    [Integration.REAMAZE]: true,
    [Integration.RECRUITERFLOW]: true,
    [Integration.SAGE_BUSINESS_CLOUD_ACCOUNTING]: true,
    [Integration.SAGE_HR]: true,
    [Integration.SALESFLARE]: true,
    [Integration.SALESFORCE]: true,
    [Integration.SALESFORCE_SERVICE_CLOUD]: true,
    [Integration.SAP_SUCCESSFACTORS]: true,
    [Integration.SENDGRID]: true,
    [Integration.SENDINBLUE]: true,
    [Integration.SERVICENOW]: true,
    [Integration.SESAME]: true,
    [Integration.SHAREPOINT]: true,
    [Integration.SHORTCUT]: true,
    [Integration.SLACK]: true,
    [Integration.SMARTRECRUITERS]: true,
    [Integration.SPOTDRAFT]: true,
    [Integration.SQUARE_PAYROLL]: true,
    [Integration.SUGARCRM]: true,
    [Integration.TALENTLYFT]: true,
    [Integration.TALENTREEF]: true,
    [Integration.TEAMLEADER]: true,
    [Integration.TEAMTAILOR]: true,
    [Integration.TEAMWORK]: true,
    [Integration.TEAMWORK_CRM]: true,
    [Integration.TELLENT]: true,
    [Integration.TRELLO]: true,
    [Integration.TRINET]: true,
    [Integration.TRINET_HR_PLATFORM]: true,
    [Integration.UKG_PRO]: true,
    [Integration.UKG_PRO_RECRUITING]: true,
    [Integration.UKG_READY]: true,
    [Integration.UKG_PRO_WORKFORCE_MANAGEMENT]: true,
    [Integration.VTIGER]: true,
    [Integration.WAVE_FINANCIAL]: true,
    [Integration.WORKABLE]: true,
    [Integration.WORKDAY]: true,
    [Integration.WRIKE]: true,
    [Integration.XERO]: true,
    [Integration.ZENDESK]: true,
    [Integration.ZENDESK_SELL]: true,
    [Integration.ZOHO_BOOKS]: true,
    [Integration.ZOHO_BUGTRACKER]: true,
    [Integration.ZOHO_CRM]: true,
    [Integration.ZOHO_DESK]: true
  };

export const INTEGRATION_DLP_ENABLED_DEFAULTS: Record<Integration, boolean> = {
  [Integration.ACCELO]: false,
  [Integration.ACTIVECAMPAIGN]: false,
  [Integration.ADP_WORKFORCE_NOW]: false,
  [Integration.AFFINITY]: false,
  [Integration.ALEXISHR]: false,
  [Integration.ALTERA_PAYROLL]: false,
  [Integration.APPLICANTSTACK]: false,
  [Integration.ASANA]: false,
  [Integration.ASHBY]: false,
  [Integration.AZURE_DEVOPS]: false,
  [Integration.BAMBOOHR]: false,
  [Integration.BASECAMP]: false,
  [Integration.BITBUCKET]: false,
  [Integration.BOX]: false,
  [Integration.BREATHE]: false,
  [Integration.BREEZY]: false,
  [Integration.CAPSULE]: false,
  [Integration.CATS]: false,
  [Integration.CERIDIAN_DAYFORCE]: false,
  [Integration.CHARLIE]: false,
  [Integration.CHARTHOP]: false,
  [Integration.CLAYHR]: false,
  [Integration.CLEAR_BOOKS]: false,
  [Integration.CLICKUP]: false,
  [Integration.CLOCKWORK]: false,
  [Integration.CLOSE]: false,
  [Integration.COMEET]: false,
  [Integration.COPPER]: false,
  [Integration.CORNERSTONE_TALENTLINK]: false,
  [Integration.CUSTOMERIO]: false,
  [Integration.DAYFORCE]: false,
  [Integration.DIXA]: false,
  [Integration.DROPBOX]: false,
  [Integration.EPLOY]: false,
  [Integration.ENGAGEATS]: false,
  [Integration.FACTORIAL]: false,
  [Integration.FOUNTAIN]: false,
  [Integration.FREEAGENT]: false,
  [Integration.FRESHBOOKS]: false,
  [Integration.FRESHDESK]: false,
  [Integration.FRESHSERVICE]: false,
  [Integration.FRESHTEAM]: false,
  [Integration.FRONT]: false,
  [Integration.GETRESPONSE]: false,
  [Integration.GITHUB_ISSUES]: false,
  [Integration.GITLAB]: false,
  [Integration.GLADLY]: false,
  [Integration.GOOGLE_DRIVE]: false,
  [Integration.GOOGLE_WORKSPACE]: false,
  [Integration.GORGIAS]: false,
  [Integration.GREENHOUSE]: false,
  [Integration.GREENHOUSE_JOB_BOARDS_API]: false,
  [Integration.GUSTO]: false,
  [Integration.HEIGHT]: false,
  [Integration.HELP_SCOUT]: false,
  [Integration.HIBOB]: false,
  [Integration.HIVE]: false,
  [Integration.HOMERUN]: false,
  [Integration.HR_CLOUD]: false,
  [Integration.HR_PARTNER]: false,
  [Integration.HUBSPOT]: false,
  [Integration.HUBSPOT_MARKETING_HUB]: false,
  [Integration.HUMAANS]: false,
  [Integration.INSIGHTLY]: false,
  [Integration.INTELLIHR]: false,
  [Integration.INTERCOM]: false,
  [Integration.IRONCLAD]: false,
  [Integration.JAZZHR]: false,
  [Integration.JIRA]: false,
  [Integration.JIRA_SERVICE_MANAGEMENT]: false,
  [Integration.JOBSCORE]: false,
  [Integration.JOBSOID]: false,
  [Integration.JOBVITE]: false,
  [Integration.JUMPCLOUD]: false,
  [Integration.JUSTWORKS]: false,
  [Integration.KALLIDUS]: false,
  [Integration.KEAP]: false,
  [Integration.KLAVIYO]: false,
  [Integration.KUSTOMER]: false,
  [Integration.LANO]: false,
  [Integration.LEVER]: false,
  [Integration.LINEAR]: false,
  [Integration.LUCCA]: false,
  [Integration.MAILCHIMP]: false,
  [Integration.MESSAGEBIRD]: false,
  [Integration.MICROSOFT_DYNAMICS_365_SALES]: false,
  [Integration.MICROSOFT_ENTRA_ID]: false,
  [Integration.MONEYBIRD]: false,
  [Integration.NAMELY]: false,
  [Integration.NETSUITE]: false,
  [Integration.NMBRS]: false,
  [Integration.NUTSHELL]: false,
  [Integration.OFFICIENT]: false,
  [Integration.OKTA]: false,
  [Integration.ONEDRIVE]: false,
  [Integration.ONELOGIN]: false,
  [Integration.ORACLE_TALEO]: false,
  [Integration.PAYCHEX]: false,
  [Integration.PAYLOCITY]: false,
  [Integration.PEOPLEHR]: false,
  [Integration.PERSONIO]: false,
  [Integration.PIPEDRIVE]: false,
  [Integration.PIPELINER]: false,
  [Integration.PIVOTAL_TRACKER]: false,
  [Integration.PODIUM]: false,
  [Integration.POLYMER]: false,
  [Integration.PROLIANT]: false,
  [Integration.QUICKBOOKS_ONLINE]: false,
  [Integration.REAMAZE]: false,
  [Integration.RECRUITERFLOW]: false,
  [Integration.SAGE_BUSINESS_CLOUD_ACCOUNTING]: false,
  [Integration.SAGE_HR]: false,
  [Integration.SALESFLARE]: false,
  [Integration.SALESFORCE]: false,
  [Integration.SALESFORCE_SERVICE_CLOUD]: false,
  [Integration.SAP_SUCCESSFACTORS]: false,
  [Integration.SENDGRID]: false,
  [Integration.SENDINBLUE]: false,
  [Integration.SERVICENOW]: false,
  [Integration.SESAME]: false,
  [Integration.SHAREPOINT]: false,
  [Integration.SHORTCUT]: false,
  [Integration.SLACK]: false,
  [Integration.SMARTRECRUITERS]: false,
  [Integration.SPOTDRAFT]: false,
  [Integration.SQUARE_PAYROLL]: false,
  [Integration.SUGARCRM]: false,
  [Integration.TALENTLYFT]: false,
  [Integration.TALENTREEF]: false,
  [Integration.TEAMLEADER]: false,
  [Integration.TEAMTAILOR]: false,
  [Integration.TEAMWORK]: false,
  [Integration.TEAMWORK_CRM]: false,
  [Integration.TELLENT]: false,
  [Integration.TRELLO]: false,
  [Integration.TRINET]: false,
  [Integration.TRINET_HR_PLATFORM]: false,
  [Integration.UKG_PRO]: false,
  [Integration.UKG_PRO_RECRUITING]: false,
  [Integration.UKG_READY]: false,
  [Integration.UKG_PRO_WORKFORCE_MANAGEMENT]: false,
  [Integration.VTIGER]: false,
  [Integration.WAVE_FINANCIAL]: false,
  [Integration.WORKABLE]: false,
  [Integration.WORKDAY]: false,
  [Integration.WRIKE]: false,
  [Integration.XERO]: false,
  [Integration.ZENDESK]: false,
  [Integration.ZENDESK_SELL]: false,
  [Integration.ZOHO_BOOKS]: false,
  [Integration.ZOHO_BUGTRACKER]: false,
  [Integration.ZOHO_CRM]: false,
  [Integration.ZOHO_DESK]: false
};
