import { FiltersValues } from './Filters';
export enum ContentFilter {
  NAME = 'name',
  // search value
  SOURCE = 'source',
  TYPE = 'type',
}
export type ContentFiltersValues = FiltersValues<ContentFilter>;
export enum IntegrationFilter {
  CATEGORY = 'CATEGORY',
}
export type IntegrationFiltersValues = FiltersValues<IntegrationFilter>;
export enum WorkflowFilter {
  CATEGORY = 'CATEGORY',
  // WorkflowCategory
  SOURCE = 'SOURCE', // WorkflowListFilter
}
export type WorkflowFiltersValues = FiltersValues<WorkflowFilter>;
export enum AuditLogFilter {
  EVENT_TYPE = 'EVENT_TYPE',
  USER_ID = 'USER_ID',
}
export type AuditLogFiltersValues = FiltersValues<AuditLogFilter>;