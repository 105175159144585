import { FileResource } from '~/components/Chat/AddFileModal';

export enum HeapEvent {
  CHAT_ASSISTANT_PROMPT = 'CHAT_ASSISTANT_PROMPT',
  CHAT_PROMPT = 'CHAT_PROMPT',
  WORKFLOW_PROMPT = 'WORKFLOW_PROMPT'
}

interface BaseHeapEventPayload {
  event: HeapEvent;
}

interface PromptPayload extends BaseHeapEventPayload {
  attachedContentTypes: string; // E.g. 'mp4, txt'. Be sure to sort alphabetically.
  promptText: string;
}

/** Prompt sent in workstation chat */
interface ChatPromptPayload extends PromptPayload {
  event: HeapEvent.CHAT_PROMPT;
}

/** Follow up prompt after a workflow run */
interface WorkflowPrompt extends PromptPayload {
  event: HeapEvent.WORKFLOW_PROMPT;
  workflowId: string;
}

export type HeapEventPayload = ChatPromptPayload | WorkflowPrompt;

export const heapIsLoaded = (): boolean =>
  // @ts-expect-error
  typeof window !== 'undefined' && !!window.heap?.loaded;

// https://developers.heap.io/reference/track
export const trackHeapEvent = (payload: HeapEventPayload) => {
  const canTrack = heapIsLoaded();

  if (!canTrack) {
    console.error('Failed to track event: Heap is not loaded.');
    return;
  }

  if (canTrack) {
    // @ts-expect-error
    window.heap?.track(payload.event, payload);
  }
};

export const getAttachedContentTypesString = (files: FileResource[]) =>
  [...files.map((file) => file.fileType)].sort().join(', ');
