import React from 'react';
import { tv, type VariantProps } from 'tailwind-variants';
const style = tv({
  base: 'inline-flex items-center rounded-full font-medium font-sans-3',
  variants: {
    bg: {
      none: '',
      base: 'bg-blue-ish-very-light',
      neutral: 'bg-neutral-light-2',
      lavender: 'bg-lavender-palest',
      cyan: 'bg-cyan-200'
    },
    p: {
      none: '',
      base: 'px-3 py-0.5'
    },
    color: {
      none: '',
      base: 'text-blue-ish',
      purple: 'text-purple-deep'
    },
    text: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-md'
    },
    border: {
      none: '',
      blueish: 'border border-blue-ish',
      gray: 'border border-gray-very-light'
    }
  }
});
type BadgeProps = {
  children: React.ReactNode;
  className?: string;
} & Prettify<VariantProps<typeof style>>;
export const Badge = ({
  children,
  className,
  bg = 'base',
  p = 'base',
  color = 'base',
  text = 'xs',
  border = 'none'
}: BadgeProps) => <span className={style({
  bg,
  p,
  color,
  text,
  border,
  className
})}>
    {children}
  </span>;