import _styled from "styled-components";
import Link from 'next/link';
const StyledLink = _styled(Link).withConfig({
  displayName: "withLink__StyledLink",
  componentId: "sc-7i6o7a-0"
})(({
  $fullSize
}) => [$fullSize && {
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "alignItems": "center"
}, !$fullSize && {
  "width": "fit-content"
}]);
interface WithLinkConfig {
  href: string | undefined;
  disabled?: boolean;
  fullSize?: boolean;
  key?: string;
  newTab?: boolean;
}
const withLink = (Component: React.ReactElement, config: WithLinkConfig): React.ReactElement => {
  const {
    href,
    key,
    disabled = false,
    newTab = false,
    fullSize = false
  } = config;
  return !!href && !disabled ? <StyledLink key={key} $fullSize={fullSize} href={href} target={newTab ? '_blank' : '_self'}>
      {Component}
    </StyledLink> : Component;
};
export default withLink;