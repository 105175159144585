import { DlpFiltersConfig } from '@prisma/client';

import { OmitStrict } from '../utils';

export enum DlpFilterPolicy {
  PASSTHROUGH = 'PASSTHROUGH',
  REDACT = 'REDACT'
}

export enum CustomDlpFilterType {
  LITERAL = 'LITERAL',
  REGEX = 'REGEX'
}

export type DlpFilter = keyof OmitStrict<DlpFiltersConfig, 'id'>;

export const DLP_FILTER_DISPLAY_NAMES: Record<DlpFilter, string> = {
  creditCard: 'Credit Card',
  crypto: 'Crypto',
  dateTime: 'Date or Time',
  emailAddress: 'Email Address',
  ibanCode: 'International Banking Account Number',
  ipAddress: 'IP Address',
  nrp: 'NRP',
  location: 'Location',
  person: 'Person',
  phoneNumber: 'Phone Number',
  medicalLicense: 'Medical License',
  url: 'URL',
  usBankNumber: 'US Bank Number',
  usDriverLicense: 'US Driver License',
  usITIN: 'US ITIN',
  usPassport: 'US Passport',
  usSSN: 'US Social Security Number'
};
