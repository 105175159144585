import { z } from 'zod';

import { WorkflowInputType } from '../../constants';
import {
  TICKETING_INTEGRATION_TICKET_FIELDS,
  TicketingIntegrationTicketField
} from '../integrations';

/** Types and Zod Schemas */

// Right now, we only support ticketing integrations
export const zTicketingIntegrationInputConfig = z.object({
  specifiedFields: z.array(z.nativeEnum(TicketingIntegrationTicketField))
});

export type TicketingIntegrationInputConfig = z.infer<
  typeof zTicketingIntegrationInputConfig
>;

/** Mapping of WorkflowInputType to its corresponding config schema */

export const Z_WORKFLOW_STEP_INPUT_TYPE_CONFIG = {
  [WorkflowInputType.TICKETING_INTEGRATION_TICKET]:
    zTicketingIntegrationInputConfig.nullable(),
  [WorkflowInputType.TEXT_OR_CONTENT]: z.null()
} as const satisfies Record<WorkflowInputType, z.ZodType>;

export type WorkflowStepInputConfig<
  T extends WorkflowInputType = WorkflowInputType
> = z.infer<(typeof Z_WORKFLOW_STEP_INPUT_TYPE_CONFIG)[T]>;

/** Type Guards */

export function isTicketingIntegrationInputConfig(
  config: WorkflowStepInputConfig<WorkflowInputType>
): config is TicketingIntegrationInputConfig {
  return (
    config !== null &&
    'specifiedFields' in config &&
    Array.isArray(config.specifiedFields) &&
    config.specifiedFields.every((field) =>
      TICKETING_INTEGRATION_TICKET_FIELDS.includes(field)
    )
  );
}
