import { useCallback, useEffect, useRef, useState } from 'react';

import { DisplayedChatMessage } from '../components/Chat';

const useScrollToEndOnChat = (chatMessages: DisplayedChatMessage[]) => {
  const [scrollEnded, setScrollEnded] = useState(true);
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrolledToEnd =
      e.currentTarget.scrollHeight -
        e.currentTarget.scrollTop -
        e.currentTarget.clientHeight <
      10;
    setScrollEnded(scrolledToEnd);
  };
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const scrollToEnd = useCallback(() => {
    setTimeout(() => {
      if (scrollEnded && scrollDivRef?.current) {
        scrollDivRef.current.scrollTop = scrollDivRef.current.scrollHeight;
      }
    }, 10);
  }, [scrollEnded]);
  useEffect(() => {
    scrollToEnd();
  }, [chatMessages, scrollToEnd]);
  return { handleScroll, scrollDivRef, scrollToEnd };
};

export default useScrollToEndOnChat;
