export enum OrgPaymentTier {
  ENTERPRISE = 'ENTERPRISE',
  FREE = 'FREE',
  PRO = 'PRO'
}

export function isOrgPaymentTier(value: string): value is OrgPaymentTier {
  return Object.values(OrgPaymentTier).includes(value as OrgPaymentTier);
}

export const ORG_PAYMENT_TIER_DISPLAY_NAMES: Record<OrgPaymentTier, string> = {
  [OrgPaymentTier.ENTERPRISE]: 'Enterprise',
  [OrgPaymentTier.FREE]: 'Free',
  [OrgPaymentTier.PRO]: 'Pro'
};

// Payment tiers supported by Stripe self checkout
export const SELF_SERVICE_ORG_PAYMENT_TIERS = [
  OrgPaymentTier.PRO
] as const satisfies OrgPaymentTier[];

export type SelfServiceOrgPaymentTier =
  (typeof SELF_SERVICE_ORG_PAYMENT_TIERS)[number];
// Stripe product ID for our self-service payment tiers
// https://dashboard.stripe.com/test/products?active=true

// Price ids used to test the upgrade path, do not charge when used
const TEST_SELF_SERVICE_ORG_PAYMENT_TIER_PRICE_ID: Record<
  SelfServiceOrgPaymentTier,
  string
> = {
  [OrgPaymentTier.PRO]: 'price_1PWMPVKXgtCnbMGpRpXytzNM'
};

const PROD_SELF_SERVICE_ORG_PAYMENT_TIER_PRICE_ID: Record<
  SelfServiceOrgPaymentTier,
  string
> = {
  [OrgPaymentTier.PRO]: 'price_1PbTJUKXgtCnbMGp2v6o8Nj5'
};

export const getPaymentTierStripePriceId = (
  paymentTier: SelfServiceOrgPaymentTier,
  isProd: boolean
) =>
  isProd
    ? PROD_SELF_SERVICE_ORG_PAYMENT_TIER_PRICE_ID[paymentTier]
    : TEST_SELF_SERVICE_ORG_PAYMENT_TIER_PRICE_ID[paymentTier];

/**
 * Configuration for the organization's payment tier.
 *
 * accessSecuritySettings - The organization has access to the full suite of security settings.
 * initialCreditAllotmentMultiplier - The multiplier used to calculate the initial credit allotment
 *    for an organization.
 * maxWorkflows - The maximum number of workflows the organization can have. null = no limit.
 * defaultMonthlyCreditAllotmentPerOrgMember - The default amount of credits allotted to an
 *    organization per member per month. This can be overridden by the
 *    monthlyCreditAllotmentOverride value set for an organization.
 */
export interface OrgPaymentTierConfig {
  accessSecuritySettings: boolean;
  defaultMonthlyCreditAllotmentPerOrgMember: number;
  initialCreditAllotmentMultiplier: number;
  maxIntegrationDataItems: number | null;
  maxMembers: number | null;
  maxWorkflows: number | null;
}

export const ORG_PAYMENT_TIER_CONFIGS: Record<
  OrgPaymentTier,
  OrgPaymentTierConfig
> = {
  [OrgPaymentTier.FREE]: {
    accessSecuritySettings: false,
    defaultMonthlyCreditAllotmentPerOrgMember: 10 * 1000, // 10k
    initialCreditAllotmentMultiplier: 2,
    maxIntegrationDataItems: 50,
    maxMembers: 1,
    maxWorkflows: null
  },
  [OrgPaymentTier.PRO]: {
    accessSecuritySettings: false,
    defaultMonthlyCreditAllotmentPerOrgMember: 100 * 1000, // 100k
    initialCreditAllotmentMultiplier: 2,
    maxIntegrationDataItems: null,
    maxMembers: 1,
    maxWorkflows: null
  },
  [OrgPaymentTier.ENTERPRISE]: {
    accessSecuritySettings: true,
    defaultMonthlyCreditAllotmentPerOrgMember: 1 * 1000 * 1000, // 1M
    initialCreditAllotmentMultiplier: 2,
    maxIntegrationDataItems: null,
    maxMembers: null,
    maxWorkflows: null
  }
};
/**
 * Gets payment tier config, with a check to ensure that the payment tier is valid.
 * Accepts a string since paymentTier can be a string in the database.
 */
export const getPaymentTierConfigOrThrow = (
  paymentTier: OrgPaymentTier | string
): OrgPaymentTierConfig => {
  if (!isOrgPaymentTier(paymentTier)) {
    throw new Error(`Invalid org payment tier: '${paymentTier}'`);
  }

  return ORG_PAYMENT_TIER_CONFIGS[paymentTier];
};
