import { Integration } from '@kindo/universal';
import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback
} from 'react';

import useToast, { ToastType } from '~/hooks/useToast';
import { trpc } from '~/trpc';

interface SlackMessageSharingContextProps {
  handleShareToSlack: (channelId: string, message: string) => void;
  isSlackConnected: boolean;
  slackChannels: { channelName: string; id: string }[];
}

export const SlackMessageSharingContext = createContext<
  SlackMessageSharingContextProps | undefined
>(undefined);

const SlackMessageSharingProvider = ({ children }: { children: ReactNode }) => {
  const { enqueueToast } = useToast();

  const [slackChannels, setSlackChannels] = useState<
    { channelName: string; id: string }[]
  >([]);
  const [isSlackConnected, setIsSlackConnected] = useState(false);

  useEffect(() => {
    const checkSlackIntegration = async () => {
      try {
        const connectedIntegrations =
          await trpc.integrations.listConnectedIntegrations.query();
        const slackIntegration = connectedIntegrations.find(
          (integration) => integration.integrationName === Integration.SLACK
        );
        const isConnected = !!slackIntegration;
        setIsSlackConnected(isConnected);

        if (isConnected) {
          const slackChannelList =
            await trpc.integrations.listSlackChannels.query();
          setSlackChannels(slackChannelList);
        }
      } catch (error) {
        console.error('Error checking Slack integration information:', error);
        enqueueToast({
          message: 'Error fetching Slack integration information',
          type: ToastType.ERROR
        });
      }
    };

    void checkSlackIntegration();
  }, []);

  const handleShareToSlack = useCallback(
    async (channelId: string, message: string) => {
      if (!isSlackConnected) {
        console.error('Slack is not connected');
        enqueueToast({
          message: 'Slack is not connected',
          type: ToastType.ERROR
        });
        return;
      }
      try {
        await trpc.integrations.sendSlackMessage.query({
          channel: channelId,
          text: message,
          isEphemeral: false
        });
        enqueueToast({
          message: 'Message shared to Slack successfully',
          type: ToastType.SUCCESS
        });
      } catch (error) {
        console.error('Error sharing message to Slack:', error);
        enqueueToast({
          message: 'Error sharing message to Slack',
          type: ToastType.ERROR
        });
      }
    },
    [isSlackConnected]
  );

  const contextValue = useMemo(
    () => ({ slackChannels, handleShareToSlack, isSlackConnected }),
    [slackChannels, handleShareToSlack, isSlackConnected]
  );

  return (
    <SlackMessageSharingContext.Provider value={contextValue}>
      {children}
    </SlackMessageSharingContext.Provider>
  );
};

export default SlackMessageSharingProvider;
