import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
const Background = () => <_StyledDiv>
    <_StyledDiv2>
      {' '}
    </_StyledDiv2>
    <_StyledDiv3 style={{
    maskImage: 'linear-gradient(to bottom, transparent, transparent, black)',
    WebkitMaskImage: 'linear-gradient(to bottom,transparent, transparent, black)'
  }}>
      {' '}
    </_StyledDiv3>
    <_StyledDiv4>
      {' '}
    </_StyledDiv4>
  </_StyledDiv>;
export default Background;
var _StyledDiv = _styled("div").withConfig({
  displayName: "Background___StyledDiv",
  componentId: "sc-wfarcx-0"
})({
  "position": "absolute",
  "left": "0px",
  "top": "0px",
  "zIndex": "-40",
  "height": "100vh",
  "width": "100vw",
  "overflow": "hidden"
});
var _StyledDiv2 = _styled("div").withConfig({
  displayName: "Background___StyledDiv2",
  componentId: "sc-wfarcx-1"
})({
  "position": "absolute",
  "height": "100%",
  "width": "100%",
  "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#f8f3ff var(--tw-gradient-from-position)",
  "--tw-gradient-to": "#d8ffe3 var(--tw-gradient-to-position)",
  "--tw-gradient-stops": "var(--tw-gradient-from), #e7fcf9 var(--tw-gradient-via-position), var(--tw-gradient-to)"
});
var _StyledDiv3 = _styled("div").withConfig({
  displayName: "Background___StyledDiv3",
  componentId: "sc-wfarcx-2"
})({
  "position": "absolute",
  "height": "100%",
  "width": "100%",
  "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#c5c0ff var(--tw-gradient-from-position)",
  "--tw-gradient-to": "#a5febb var(--tw-gradient-to-position)",
  "--tw-gradient-stops": "var(--tw-gradient-from), #bfeef4 var(--tw-gradient-via-position), var(--tw-gradient-to)"
});
var _StyledDiv4 = _styled("div").withConfig({
  displayName: "Background___StyledDiv4",
  componentId: "sc-wfarcx-3"
})({
  "position": "absolute",
  "left": "72vw",
  "top": "33.333333%",
  "height": "75%",
  "width": "33.333333%",
  "--tw-rotate": "142deg",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "borderRadius": "9999px",
  "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#fbffd166 var(--tw-gradient-from-position)",
  "--tw-gradient-to": "#f6ff8e66 var(--tw-gradient-to-position)",
  "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
  "--tw-blur": "blur(125px)",
  "filter": "var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)"
});