export enum PromptTemplateTagType {
  STATIC_CONTENT = 'STATIC_CONTENT',
  USER_INPUT = 'USER_INPUT'
}

export const PROMPT_TEMPLATE_TAG_PREFIXES: Record<
  PromptTemplateTagType,
  string
> = {
  [PromptTemplateTagType.USER_INPUT]: 'userInput',
  [PromptTemplateTagType.STATIC_CONTENT]: 'staticContent'
};
