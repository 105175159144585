import _styled from "styled-components";
import { Color, Icon, Icons, Typography, TypographyCasing, TypographySize, TypographyWeight } from '../core';
export type TableSort<SortKey extends string = string> = {
  direction: 'asc' | 'desc';
  key: SortKey;
};
interface SortHeaderProps<SortKey extends string> {
  activeSort: TableSort<SortKey>;
  setSortKey: (value: TableSort<SortKey>) => void;
  sortKey: SortKey;
  title: string;
  disabled?: boolean;
}
const SortHeaderContainer = _styled.div.withConfig({
  displayName: "SortHeader__SortHeaderContainer",
  componentId: "sc-1ih43qs-0"
})({
  "display": "flex",
  "width": "fit-content",
  "cursor": "pointer",
  "userSelect": "none",
  "alignItems": "center",
  "gap": "0.25rem"
});
const SortIconContainer = _styled.span.withConfig({
  displayName: "SortHeader__SortIconContainer",
  componentId: "sc-1ih43qs-1"
})(({
  $isActive
}) => [$isActive ? {
  "visibility": "visible"
} : {
  "visibility": "hidden"
}]);
export const Header: React.FC<{
  title: string;
}> = ({
  title
}) => <Typography casing={TypographyCasing.UPPERCASE} color={Color.NAVY} size={TypographySize.X_SMALL} weight={TypographyWeight.SEMI_BOLD}>
    {title}
  </Typography>;
function SortHeader<SortKey extends string>({
  activeSort,
  sortKey,
  setSortKey,
  title,
  disabled
}: SortHeaderProps<SortKey>) {
  const isActive = activeSort.key === sortKey;
  const isDesc = activeSort.direction === 'desc';
  const onClick = () => {
    if (isActive) {
      setSortKey({
        key: sortKey,
        direction: isDesc ? 'asc' : 'desc'
      });
    } else {
      setSortKey({
        key: sortKey,
        direction: 'asc'
      });
    }
  };
  return <SortHeaderContainer onClick={!disabled ? onClick : undefined}>
      <Header title={title} />
      <SortIconContainer $isActive={isActive}>
        <Icons color={Color.NAVY} icon={isDesc ? Icon.CHEVRON_DOWN : Icon.CHEVRON_UP} />
      </SortIconContainer>
    </SortHeaderContainer>;
}
export default SortHeader;