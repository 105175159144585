import _styled from "styled-components";
import { Input as MuiInput } from '@mui/base/Input';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { useRef } from 'react';
import { BORDER_COLORS, Color } from '../constants';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { TextFieldType } from './TextField.consts';
const TextFieldAndErrorContainer = _styled.div.withConfig({
  displayName: "TextField__TextFieldAndErrorContainer",
  componentId: "sc-1e5dqnb-0"
})(({
  $fullWidth,
  $width
}) => [{
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem"
}, $fullWidth && {
  "width": "100%"
}, $width]);
const TEXT_FIELD_CONTAINER_TYPE_STYLES: Record<TextFieldType, TwStyle> = {
  [TextFieldType.SIMPLE]: {
    "borderBottomWidth": "0.063rem",
    "borderColor": "transparent",
    "&:focus-within": {
      "borderColor": "#095A7C58"
    },
    "&:hover": {
      "borderColor": "#095A7C58"
    }
  },
  [TextFieldType.OUTLINED]: {
    "borderRadius": "0.75rem",
    "borderWidth": "1px",
    "padding": "0.375rem",
    "paddingLeft": "0.75rem"
  },
  [TextFieldType.ROUND]: {
    "borderRadius": "9999px",
    "borderWidth": "1px",
    "padding": "0.5rem",
    "paddingLeft": "0.75rem"
  }
};
const getTextFieldStyles = ({
  $error,
  $type
}: {
  $error: boolean;
  $type: TextFieldType;
  disabled: boolean;
}) => [{
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "textOverflow": "ellipsis",
  "borderColor": "#095A7C58",
  "backgroundColor": "transparent",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
}, !$error && {
  "&:focus-within": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
  },
  "&:enabled:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
  }
},
// No error
TEXT_FIELD_CONTAINER_TYPE_STYLES[$type], $error && BORDER_COLORS[Color.RED],
// Error
{
  "&:disabled": {
    "cursor": "not-allowed",
    "opacity": "0.7"
  }
} // Disabled
];
const StyledTextArea = _styled(TextareaAutosize).withConfig({
  displayName: "TextField__StyledTextArea",
  componentId: "sc-1e5dqnb-1"
})(({
  disabled,
  $error,
  $type
}) => getTextFieldStyles({
  disabled,
  $error,
  $type
}));
const StyledInput = _styled.input.withConfig({
  displayName: "TextField__StyledInput",
  componentId: "sc-1e5dqnb-2"
})(({
  disabled,
  $error,
  $type
}) => getTextFieldStyles({
  disabled,
  $error,
  $type
}));
interface TextFieldProps {
  onChange: (value: string) => void;
  type: TextFieldType;
  value: string;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  fullWidth?: boolean;
  maxRows?: number | undefined;
  minRows?: number | undefined;
  multiline?: boolean;
  onBlur?: () => void;
  onEnter?: () => void;
  placeholder?: string;
  size?: TypographySize;
  // Note: May need to size to its own size enum once we have more types
  textColor?: Color;
  textWeight?: TypographyWeight;
  width?: TwStyle;
}
const TextField: React.FC<TextFieldProps> = ({
  onChange,
  type,
  value,
  autoFocus = false,
  disabled = false,
  error = false,
  errorMessage,
  maxRows,
  minRows,
  multiline = false,
  onEnter,
  fullWidth = false,
  onBlur,
  placeholder = '',
  size = TypographySize.MEDIUM,
  textColor = Color.BLACK,
  textWeight = TypographyWeight.NORMAL,
  width
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // If the user is holding shift for multiline,
    // we let them newline and not trigger onEnter
    const isMultiLineAndHoldingShift = multiline && e.shiftKey;
    if (e.key === 'Enter' && onEnter && !isMultiLineAndHoldingShift) {
      onEnter();
      ref.current?.blur();
    }
  };
  return <TextFieldAndErrorContainer $fullWidth={fullWidth} $width={width}>
      <Typography color={textColor} fullWidth size={size} weight={textWeight}>
        <MuiInput autoFocus={autoFocus} disabled={disabled} maxRows={maxRows} minRows={minRows} multiline={multiline} onBlur={onBlur} onChange={e => onChange?.(e.target.value)} placeholder={placeholder} slotProps={{
        input: {
          // @ts-expect-error
          $error: error,
          $type: type,
          onKeyDown: handleKeyDown,
          ref
        }
      }} slots={{
        textarea: StyledTextArea,
        input: StyledInput
      }} value={value} />
      </Typography>
      {errorMessage && <Typography color={Color.RED} size={TypographySize.X_SMALL}>
          {errorMessage}
        </Typography>}
    </TextFieldAndErrorContainer>;
};
export default TextField;