import { useEffect } from 'react';

import useUserInfo from '../useUserInfo';

import { heapIsLoaded } from '~/utils';

/**
 * Sets up heap, identifies User.
 * Note: Script in _app.tsx or layout.tsx (post Next app router migration) is still required.
 */
const useHeap = () => {
  const { user } = useUserInfo();

  const canUseHeap = heapIsLoaded();

  // Identify user on heap
  useEffect(() => {
    if (!user?.id) return;

    /**
     * https://developers.heap.io/docs/using-identify
     *
     * Important: We need to the loaded check or else heap will throw in error
     * This may be fixed if we move away from SSR, but not sure,
     * so test thoroughly if any changes are made
     */
    if (canUseHeap) {
      // @ts-expect-error
      window.heap?.identify(user.id);

      // @ts-expect-error
      window.heap?.addUserProperties({
        name: user.name,
        email: user.email,
        paymentTier: user.org.paymentTier
      });
    }
  }, [user?.email, user?.id, user?.name, user?.org?.paymentTier, canUseHeap]);
};

export default useHeap;
