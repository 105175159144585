import {
  OmitStrict,
  WorkflowStepType,
  WorkflowIntegrationActionStepType,
  PromptTemplateWorkflowInputType,
  IntegrationWorkflowInputType,
  PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES,
  INTEGRATION_WORKFLOW_INPUT_TYPES,
  WorkflowSupportedIntegration,
  WorkflowTriggerConditionType,
  TicketingIntegrationTicketTriggerConditionField,
  TicketingIntegrationTicketTriggerConditionValue
} from '@kindo/universal';

import {
  WorkflowStep,
  isLlmWorkflowStep,
  WorkflowStepInput,
  IntegrationWorkflowStepInput,
  PromptTemplateWorkflowStepInput,
  WorkflowLlmStep,
  WorkflowTrigger
} from '~/types';

/**
 * Workflow Builder types are similar to those in ./workflow.types.ts,
 * but are slightly modified due to the builder supporting things like
 * unsaved inputs, unsaved steps etc, that do not have all the
 * same properties as their saved counterparts.
 */

export enum BuilderWorkflowStatus {
  MODIFIED = 'MODIFIED',
  NEW = 'NEW',
  SAVED = 'SAVED'
}

/** WORKFLOW TRIGGERS
 *
 * The following types are used to support new triggers and conditions that
 * have not yet been saved to the workflow.
 */

type ExistingWorkflowTrigger = OmitStrict<
  WorkflowTrigger,
  'conditions' | 'integrationName'
> & {
  conditions: WorkflowTriggerConditionBuilder[];
  integrationName: WorkflowSupportedIntegration;
  status: BuilderWorkflowStatus;
};

// New triggers have not yet been saved to the workflow
export type NewWorkflowTrigger = OmitStrict<
  WorkflowTrigger,
  'id' | 'conditions' | 'integrationName'
> & {
  conditions: WorkflowTriggerConditionBuilder[];
  integrationName: WorkflowSupportedIntegration | null;
  status: BuilderWorkflowStatus;
};

export type WorkflowTriggerBuilder =
  | NewWorkflowTrigger
  | ExistingWorkflowTrigger;

export function transformWorkflowTriggerToWorkflowTriggerBuilder(
  trigger: WorkflowTrigger,
  status: BuilderWorkflowStatus
): WorkflowTriggerBuilder {
  return {
    ...trigger,
    status,
    conditions: trigger.conditions.map((condition, index) => ({
      ...condition,
      index
    }))
  };
}

export type WorkflowTriggerConditionBuilder = {
  field: TicketingIntegrationTicketTriggerConditionField;
  index: number | null;
  type: WorkflowTriggerConditionType;
  value: TicketingIntegrationTicketTriggerConditionValue<TicketingIntegrationTicketTriggerConditionField>;
};

/** WORKFLOW STEP INPUTS */

// New workflow inputs have not yet been saved to the workflow
export type NewWorkflowStepInput = OmitStrict<
  WorkflowStepInput,
  'id' | 'workflowId'
>;

export type NewPromptTemplateWorkflowStepInput = Extract<
  NewWorkflowStepInput,
  { type: PromptTemplateWorkflowInputType }
>;

// TODO: Remove this redundancy
// Keeping for now for now for clarity-sake during implementation
export type IntegrationWorkflowBuilderStepInput = IntegrationWorkflowStepInput;

export type WorkflowBuilderStepInput = WorkflowStepInput | NewWorkflowStepInput;

export type PromptTemplateWorkflowBuilderStepInput =
  | NewPromptTemplateWorkflowStepInput
  | PromptTemplateWorkflowStepInput;

/** WORKFLOW STEPS */

type BaseBuilderWorkflowStep = OmitStrict<
  WorkflowStep,
  'workflowId' | 'inputs'
> & {
  inputs: WorkflowBuilderStepInput[];
  status: BuilderWorkflowStatus;
};

export type BuilderWorkflowStepStaticContent = Pick<
  WorkflowLlmStep['staticContent'][number],
  'id' | 'fileName'
>;

export type BuilderWorkflowLlmStep = OmitStrict<
  Extract<
    BaseBuilderWorkflowStep,
    {
      type: WorkflowStepType.LLM;
    }
  >,
  'staticContent'
> & {
  integrationInputActiveInEditor: IntegrationWorkflowBuilderStepInput | null;
  staticContent: BuilderWorkflowStepStaticContent[];
};

export type BuilderWorkflowIntegrationActionStep = OmitStrict<
  Extract<
    BaseBuilderWorkflowStep,
    {
      type: WorkflowStepType.INTEGRATION_ACTION;
    }
  >,
  'action' | 'integration'
> & {
  action: WorkflowIntegrationActionStepType | null;
  integration: WorkflowSupportedIntegration | null;
};

export type BuilderWorkflowStep =
  | BuilderWorkflowLlmStep
  | BuilderWorkflowIntegrationActionStep;

export function transformWorkflowStepToBuilderWorkflowStep(
  step: WorkflowStep,
  status: BuilderWorkflowStatus
): BuilderWorkflowStep {
  if (isLlmWorkflowStep(step)) {
    return {
      ...step,
      staticContent: step.staticContent.map((content) => ({
        fileName: content.fileName || 'Unknown File Name',
        id: content.id
      })),
      integrationInputActiveInEditor: null,
      status
    };
  }

  return {
    ...step,
    status
  };
}

/** Type Guards */
export function isLlmWorkflowBuilderStep(
  step: BuilderWorkflowStep | null
): step is BuilderWorkflowLlmStep {
  return step?.type === WorkflowStepType.LLM;
}

export function isIntegrationActionWorkflowBuilderStep(
  step: BuilderWorkflowStep | null
): step is BuilderWorkflowIntegrationActionStep {
  return step?.type === WorkflowStepType.INTEGRATION_ACTION;
}

export function isPromptTemplateWorkflowBuilderStepInput(
  input: WorkflowBuilderStepInput | null
): input is PromptTemplateWorkflowBuilderStepInput {
  return PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES.includes(
    input?.type as PromptTemplateWorkflowInputType
  );
}

export function isIntegrationWorkflowBuilderStepInput(
  input: WorkflowBuilderStepInput | null
): input is IntegrationWorkflowBuilderStepInput {
  return INTEGRATION_WORKFLOW_INPUT_TYPES.includes(
    input?.type as IntegrationWorkflowInputType
  );
}

export function isExistingWorkflowTrigger(
  trigger: WorkflowTriggerBuilder | null
): trigger is ExistingWorkflowTrigger {
  return trigger?.status !== BuilderWorkflowStatus.NEW;
}
