import { TicketingIntegrationTicketField } from '../integrations';

import { TicketingIntegrationInputConfig } from './workflowStepInputConfig.types';

export const MOCK_TICKETING_INTEGRATION_STEP_INPUT_CONFIG = {
  specifiedFields: [
    TicketingIntegrationTicketField.DESCRIPTION,
    TicketingIntegrationTicketField.TITLE,
    TicketingIntegrationTicketField.CREATOR
  ]
} as const satisfies TicketingIntegrationInputConfig;
