export const INVITE_ID_QUERY_PARAM = 'inviteId';

// Express routes
export const EXPRESS_ROUTE = '/express';
export const EXPRESS_PROXY_URL = '/api/um/proxy' + EXPRESS_ROUTE;

export const EXPRESS_CONTENT_BASE_ROUTE = '/content';
export const EXPRESS_CONTENT_BASE_URL =
  EXPRESS_PROXY_URL + EXPRESS_CONTENT_BASE_ROUTE;
// Direct route that bypasses the proxy
export const EXPRESS_CONTENT_DIRECT_ROUTE =
  EXPRESS_ROUTE + EXPRESS_CONTENT_BASE_ROUTE;

export const EXPRESS_FILE_UPLOAD_ROUTE = '/file/upload';

// Slack API routes
const SLACK_API_BASE_URL = 'https://slack.com/api/';

// To send a message that is only visible to the user
export const SLACK_API_CHAT_POST_EPHEMERAL =
  SLACK_API_BASE_URL + 'chat.postEphemeral';

export const SLACK_API_CHAT_POST_MESSAGE =
  SLACK_API_BASE_URL + 'chat.postMessage';

export const SLACK_API_CONVERSATIONS_HISTORY =
  SLACK_API_BASE_URL + 'conversations.history';

export const SLACK_API_CONVERSATIONS_LIST =
  SLACK_API_BASE_URL + 'conversations.list';

export const SLACK_API_CONVERSATIONS_REPLIES =
  SLACK_API_BASE_URL + 'conversations.replies';

export const SLACK_API_USERS_AUTH_REVOKE = SLACK_API_BASE_URL + 'auth.revoke';

export const SLACK_API_USERS_INFO = SLACK_API_BASE_URL + 'users.list';

/** Frontend routes */
export const SETTINGS_ROUTE = '/settings';
export const MANAGE_SUBSCRIPTION_ROUTE = 'manage-subscription';
export const ORGANIZATION_ROUTE = 'organization';

export const MANAGE_SUBSCRIPTION_FULL_ROUTE = [
  SETTINGS_ROUTE,
  MANAGE_SUBSCRIPTION_ROUTE
].join('/');

// Kindo API routes
export const KINDO_API_BASE_ROUTE = '/v1';
export const KINDO_API_AGENTS_RUN_ROUTE = '/agents/run';
