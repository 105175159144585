import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useState } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';
import { Color, Icon, Icons } from '../core';
const SearchInputContainer = _styled.div.withConfig({
  displayName: "SearchInput__SearchInputContainer",
  componentId: "sc-am3fle-0"
})({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem",
  "borderRadius": "9999px",
  "borderWidth": "1px",
  "borderColor": "#095A7C58",
  "padding": "0.5rem",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
  },
  "&:focus": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
  }
});
const style = tv({
  base: '',
  variants: {}
});
type SearchInputProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
} & Prettify<VariantProps<typeof style>>;
export const SearchInput = ({
  value,
  onChange,
  placeholder = 'Search'
}: SearchInputProps) => {
  const [innerValue, setInnerValue] = useState<string>(value || '');
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.target.value);
    onChange(e);
  };
  return <SearchInputContainer>
      <Icons color={Color.NAVY} icon={Icon.SEARCH} />
      <_StyledInput onChange={handleOnChange} placeholder={placeholder}
    // eslint-disable-next-line react/no-unknown-property
    type="search" value={innerValue} />
    </SearchInputContainer>;
};
export default SearchInput;
var _StyledInput = _styled("input").withConfig({
  displayName: "SearchInput___StyledInput",
  componentId: "sc-am3fle-1"
})({
  "backgroundColor": "transparent",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "fontWeight": "468",
  "--tw-text-opacity": "1",
  "color": "rgb(9 90 124 / var(--tw-text-opacity))",
  "&::placeholder": {
    "--tw-placeholder-opacity": "1",
    "color": "rgb(9 90 124 / var(--tw-placeholder-opacity))"
  },
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
});