import { Workflow } from '~/types';

const useIsSharedWorkflow = (
  workflow: Workflow
): { isSharedWorkflow: boolean } => {
  const isSharedWorkflow =
    workflow?.orgRole !== null ||
    workflow?.publicRole !== null ||
    workflow?.userRoles.length > 0;

  return { isSharedWorkflow };
};

export default useIsSharedWorkflow;
