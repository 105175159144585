import { useURLParams } from './useUrlParams';

import { WORKFLOW_ID_QUERY_PARAM } from '~/constants';
import { nextTrpc } from '~/trpc';

/**
 * Fetch workflow for the active workflowId in the URL param
 */
const useGetWorkflowFromUrlParam = () => {
  const { workflowId } = useURLParams<{
    [WORKFLOW_ID_QUERY_PARAM]?: string;
  }>();

  const res = nextTrpc.workflows.get.useQuery(
    {
      workflowId: workflowId || ''
    },
    {
      enabled: !!workflowId
    }
  );

  return res;
};

export default useGetWorkflowFromUrlParam;
