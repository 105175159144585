/* eslint-disable no-param-reassign */
import {
  TicketingIntegrationTicketTriggerConditionField,
  WorkflowInputType,
  WorkflowSupportedIntegration,
  WorkflowTriggerConditionType,
  WorkflowTriggerOperator
} from '@kindo/universal';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  BuilderWorkflowStatus,
  NewWorkflowTrigger,
  transformWorkflowTriggerToWorkflowTriggerBuilder,
  WorkflowTrigger,
  WorkflowTriggerBuilder,
  WorkflowTriggerConditionBuilder
} from '~/types';

const NEW_EMPTY_TRIGGER: NewWorkflowTrigger = {
  integrationId: '',
  integrationInputType: WorkflowInputType.TICKETING_INTEGRATION_TICKET,
  integrationName: null,
  operator: WorkflowTriggerOperator.AND,
  conditions: [],
  workflowId: '',
  enabled: true,
  deletedAt: null,
  status: BuilderWorkflowStatus.NEW
};

const NEW_EMPTY_TRIGGER_CONDITION: WorkflowTriggerConditionBuilder = {
  field: TicketingIntegrationTicketTriggerConditionField.DESCRIPTION,
  type: WorkflowTriggerConditionType.EQUALS,
  value: '',
  index: null
};

interface TriggerState {
  activeTrigger: WorkflowTriggerBuilder | null;
  activeTriggerCondition: WorkflowTriggerConditionBuilder | null;
}

const initialState: TriggerState = {
  activeTrigger: null,
  activeTriggerCondition: null
};

export const workflowBuilderTriggerSlice = createSlice({
  name: 'workflowBuilderTrigger',
  initialState,
  reducers: {
    // Trigger
    createNewTrigger: (
      state,
      action: PayloadAction<{ workflowId: string }>
    ) => {
      state.activeTrigger = {
        ...NEW_EMPTY_TRIGGER,
        workflowId: action.payload.workflowId
      };
    },

    setExistingTriggerActive: (
      state,
      action: PayloadAction<WorkflowTrigger>
    ) => {
      state.activeTrigger = transformWorkflowTriggerToWorkflowTriggerBuilder(
        action.payload,
        BuilderWorkflowStatus.SAVED
      );
    },

    updateTriggerOperator: (
      state,
      action: PayloadAction<WorkflowTriggerOperator>
    ) => {
      if (!state.activeTrigger) return;
      state.activeTrigger.operator = action.payload;

      if (state.activeTrigger.status === BuilderWorkflowStatus.SAVED) {
        state.activeTrigger.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    updateTriggerIntegration: (
      state,
      action: PayloadAction<{
        integrationId: string;
        integrationName: WorkflowSupportedIntegration;
      }>
    ) => {
      if (!state.activeTrigger) return;

      state.activeTrigger.integrationName = action.payload.integrationName;
      state.activeTrigger.integrationId = action.payload.integrationId;

      if (state.activeTrigger.status === BuilderWorkflowStatus.SAVED) {
        state.activeTrigger.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    clearTrigger: (state) => {
      state.activeTrigger = null;
      state.activeTriggerCondition = null;
    },

    updateTriggerEnabled: (state, action: PayloadAction<boolean>) => {
      if (!state.activeTrigger) return;

      state.activeTrigger.enabled = action.payload;

      if (state.activeTrigger.status === BuilderWorkflowStatus.SAVED) {
        state.activeTrigger.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    markTriggerSaved: (state) => {
      if (!state.activeTrigger) return;
      state.activeTrigger.status = BuilderWorkflowStatus.SAVED;
    },

    // Trigger Condition

    createNewTriggerCondition: (state) => {
      state.activeTriggerCondition = NEW_EMPTY_TRIGGER_CONDITION;
    },

    setTriggerConditionActive: (
      state,
      action: PayloadAction<WorkflowTriggerConditionBuilder>
    ) => {
      state.activeTriggerCondition = action.payload;
    },

    clearTriggerCondition: (state) => {
      state.activeTriggerCondition = null;
    },

    removeTriggerCondition: (
      state,
      action: PayloadAction<WorkflowTriggerConditionBuilder>
    ) => {
      if (!state.activeTrigger) return;
      state.activeTrigger = {
        ...state.activeTrigger,
        conditions: state.activeTrigger.conditions.filter(
          (condition) => condition.index !== action.payload.index
        )
      };

      if (state.activeTrigger.status === BuilderWorkflowStatus.SAVED) {
        state.activeTrigger.status = BuilderWorkflowStatus.MODIFIED;
      }
    },

    saveOrUpdateTriggerCondition: (
      state,
      action: PayloadAction<Partial<WorkflowTriggerConditionBuilder>>
    ) => {
      if (!state.activeTrigger || !state.activeTriggerCondition) return;

      state.activeTriggerCondition = {
        ...state.activeTriggerCondition,
        ...action.payload
      };

      const conditionIndex = state.activeTriggerCondition.index;

      if (conditionIndex === null) {
        state.activeTrigger.conditions.push({
          ...state.activeTriggerCondition,
          index: state.activeTrigger.conditions.length
        });
      } else {
        const conditionToUpdate = state.activeTrigger.conditions.find(
          (existingCondition: WorkflowTriggerConditionBuilder) =>
            existingCondition.index === conditionIndex
        );

        if (conditionToUpdate) {
          state.activeTrigger.conditions[conditionIndex] =
            state.activeTriggerCondition;
        }
      }

      if (state.activeTrigger.status === BuilderWorkflowStatus.SAVED) {
        state.activeTrigger.status = BuilderWorkflowStatus.MODIFIED;
      }

      state.activeTriggerCondition = null;
    }
  }
});

export const triggerActions = workflowBuilderTriggerSlice.actions;
