import { useSession } from 'next-auth/react';

import { nextTrpc } from '~/trpc';

const useUserInfo = () => {
  const { data: session } = useSession();

  const { data, refetch } = nextTrpc.users.get.useQuery(undefined, {
    enabled: !!session?.user?.userId
  });
  const { item: user } = data || {};

  return { user, refetchUser: refetch };
};

export default useUserInfo;
