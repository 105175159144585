export enum Size {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  X_LARGE = 'X_LARGE',
  X_SMALL = 'X_SMALL',
}

// Styles for button based on size
// Note: Currently only X-Small, Small and Medium are used, the rest copy medium styling
// TODO: Finish remaining types as they're needed based on mocks
export const BUTTON_SIZE_STYLES: Record<Size, TwStyle> = {
  [Size.X_SMALL]: {
    "height": "15px"
  },
  [Size.SMALL]: {
    "height": "30px"
  },
  [Size.MEDIUM]: {
    "height": "2.25rem"
  },
  [Size.LARGE]: {
    "height": "2.25rem"
  },
  [Size.X_LARGE]: {
    "height": "2.25rem"
  }
};

// Styles for icon button based on size
// Should match button size styles
export const ICON_BUTTON_SIZE_STYLES: Record<Size, TwStyle> = {
  [Size.X_SMALL]: {
    "height": "15",
    "width": "15"
  },
  [Size.SMALL]: {
    "height": "30px",
    "width": "30px"
  },
  [Size.MEDIUM]: {
    "height": "2.25rem",
    "width": "2.25rem"
  },
  [Size.LARGE]: {
    "height": "2.25rem",
    "width": "2.25rem"
  },
  [Size.X_LARGE]: {
    "height": "2.25rem",
    "width": "2.25rem"
  }
};