// eslint-disable-next-line import/no-extraneous-dependencies
import { type Config } from 'tailwindcss';

const config: Config = {
  content: [
    './styles/*.{js,ts,jsx,tsx,mdx}',
    './pages/*.{js,ts,jsx,tsx,mdx}',
    './pages/**/*.{js,ts,jsx,tsx,mdx}',
    './components/**/*.{js,ts,jsx,tsx,mdx}'
  ],
  theme: {
    extend: {
      // Height
      height: {
        inherit: 'inherit'
      },
      margin: {
        7.5: '29px'
      },
      minWidth: {
        sm: '8.5rem',
        lg: '32rem'
      },
      fontSize: {
        xxs: '0.688rem',
        md: '1.125rem'
      },
      borderWidth: {
        1: '0.063rem'
      },
      padding: {
        15: '3.75rem'
      },
      borderRadius: {
        '4xl': '2.5rem'
      },
      boxShadow: {
        card: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
        'inner-top': 'inset 0px 6px 5px -5px rgba(62, 61, 65, 0.40);',
        'inner-bottom': 'inset 0px -6px 5px -5px rgba(62, 61, 65, 0.40);'
      },
      colors: {
        slate: '#8CAEBA33',
        paper: '#FDFCFB',
        'blue-ish': {
          DEFAULT: '#4D7280',
          '1/10': '#4D728019',
          '1/5': '#4d728038',
          2: {
            DEFAULT: '#8CAEBA',
            '1/5': '#8CAEBA33',
            '2/5': '#8CAEBA48'
          },
          'very-light': '#E6ECEE'
        },
        neutral: {
          light: {
            DEFAULT: '#FDEFE2',
            1: '#F3F4F7',
            2: '#E6E8EF'
          },
          mid: {
            DEFAULT: '#9BA3BF'
          }
        },
        'not-quite-black': '#3E3D41',
        gray: {
          'very-light': '#E0E8E9',
          anon: '#E6E8EF',
          light: '#095A7C37'
        },
        lavender: {
          faint: '#f8f3ff',
          palest: '#E1DFFF',
          pale: '#FFA797',
          saturated: '#c5c0ff'
        },
        orange: {
          pale: '#C3C1F8'
        },
        purple: {
          pale: '#9BA3BF', // sometimes referenced as mid-neutral-1 in figma
          deep: '#3C3083'
        },
        green: {
          faint: '#d8ffe3',
          light: '#80ED99',
          pale: '#CEFFC2',
          palest: '#E5FEDF',
          radio: '#1ED3B0',
          anon: '#D0FFFF', // no name associated in figma
          'anon-bright': '#C0FFFF', // no name associated in figma
          1: '#1DD3B0',
          saturated: '#a5febb'
        },
        cyan: {
          saturated: '#bfeef4'
        },
        yellow: {
          palest: '#fbffd166',
          pale: '#f6ff8e66'
        },
        black: {
          raisin: '#161925'
        },
        blue: {
          faint: '#e7fcf9',
          navy: {
            DEFAULT: '#095A7C',
            '1/5': '#095A7C58',
            '1/10': '#0863751A',
            '1/20': '#095A7C0D'
          },
          'extra-light': '#F3F4F7',
          palest: '#EBFDFD'
        },
        salmon: {
          bright: '#FC6471',
          medium: '#FF908A'
        },
        builderCanvasGradient: {
          light: '#086375',
          dark: '#58A0A3'
        }
      },
      fontFamily: {
        sans: ['var(--font-source-sans-3)'],
        'sans-3': ['var(--font-source-sans-3)']
      },
      fontWeight: {
        semibold: '600',
        medium: '468',
        semilight: '350',
        seminormal: '375'
      },
      screens: {
        // Based on a card width of 440px + 8px gap
        twoCard: '1008px',
        threeCard: '1450px'
      }
    }
  },
  plugins: []
};

export default config;
