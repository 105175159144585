import { FlagProvider, useUnleashClient } from '@unleash/nextjs/client';
import { useEffect } from 'react';
import useUserInfo from '~/hooks/useUserInfo';

/**
 * This component modifies the Unleash client context to include the user's ID and organization ID.
 * This is necessary for the Unleash client to function properly, as it is not known when the
 * FlagProvider is first rendered (and will not update automatically if those values change).
 *
 * It must be a child of the FlagProvider in order to access the client.
 */
const UnleashInnerSetupWrapper = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    user
  } = useUserInfo();

  // Unleash client
  const client = useUnleashClient();

  // Start and update the client when the user info updates
  useEffect(() => {
    const startClient = async () => {
      if (!user?.id || !user?.org.id) {
        return;
      }
      try {
        await client.updateContext({
          userId: user?.id,
          properties: {
            orgId: user?.org.id
          }
        });
        await client.start();
      } catch (error) {
        console.error('Error starting Unleash client', error);
      }
    };
    void startClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.org.id]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
const FeatureFlagWrapper = ({
  children
}: {
  children: React.ReactNode;
}) => (/* https://docs.getunleash.io/reference/sdks/next-js#c-client-side-only---simple-use-case-and-for-development-purposes-csr */
<FlagProvider config={{
  clientKey: process.env.NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN ?? '',
  url: process.env.NEXT_PUBLIC_UNLEASH_FRONTEND_API_URL ?? ''
}} startClient={false}>
    <UnleashInnerSetupWrapper>{children}</UnleashInnerSetupWrapper>
  </FlagProvider>);
export default FeatureFlagWrapper;