/* eslint-disable no-param-reassign */
import { ExternalLlm, Provider, RequireOnlyOne } from '@kindo/universal';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// TODO: Migrate other modals into this enum
export enum Modal {
  CONFIGURE_DLP_FILTERS = 'CONFIGURE_DLP_FILTERS',
  DELETE_INTEGRATION = 'DELETE_INTEGRATION',
  DELETE_WORKFLOW = 'DELETE_WORKFLOW',
  PAYWALL = 'PAYWALL',
  SHARE_CHAT = 'SHARE_CHAT',
  SHARE_WORKFLOW = 'SHARE_WORKFLOW'
}

interface BaseModalPayload {
  type: Modal;
}

/** Deletion Modals */
interface DeleteIntegrationModalPayload extends BaseModalPayload {
  integrationId: string;
  integrationName: string;
  // TODO: Don't store nonserializable function in Redux
  onSuccess: () => void;
  type: Modal.DELETE_INTEGRATION;
}

interface DeleteWorkflowModalPayload extends BaseModalPayload {
  type: Modal.DELETE_WORKFLOW;
  workflowId: string;
  workflowTitle: string;
  redirectRoute?: string;
}

/** Share Modals */
interface ShareWorkflowModalPayload extends BaseModalPayload {
  type: Modal.SHARE_WORKFLOW;
  workflowId: string;
}

interface ShareChatModalPayload extends BaseModalPayload {
  chatId: string;
  type: Modal.SHARE_CHAT;
}

/** Paywall Modals */
export enum PaywallModalType {
  WORKFLOWS = 'WORKFLOWS'
}

interface BasePaywallModalPayload extends BaseModalPayload {
  paywallModalType: PaywallModalType;
  type: Modal.PAYWALL;
}

interface WorkflowsPaywallModalPayload extends BasePaywallModalPayload {
  existingWorkflowCount: number | undefined;
  paywallModalType: PaywallModalType.WORKFLOWS;
}

export type PaywallModalPayload = WorkflowsPaywallModalPayload;

export type ModelOrProvider = RequireOnlyOne<
  { model: ExternalLlm; provider: Provider },
  'model' | 'provider'
>;

interface ConfigureDlpFiltersModalPayload extends BaseModalPayload {
  canAccessSecuritySettings: boolean;
  modelOrProvider: ModelOrProvider;
  type: Modal.CONFIGURE_DLP_FILTERS;
}

type ModalPayload =
  | DeleteWorkflowModalPayload
  | ShareWorkflowModalPayload
  | DeleteIntegrationModalPayload
  | ShareChatModalPayload
  | ConfigureDlpFiltersModalPayload
  | PaywallModalPayload;

interface ModalState {
  activeModal: ModalPayload | null;
}

const initialState: ModalState = {
  activeModal: null
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalPayload>) => {
      state.activeModal = action.payload;
    },
    closeModal: (state) => {
      state.activeModal = null;
    }
  }
});

export const modalActions = modalSlice.actions;
