import { SurveyStepData, SurveyStepType } from './components/SurveyStep';
export const ONBOARDING_STEPS: SurveyStepData[] = [{
  allowSkip: false,
  prompt: (value: string) => value ? 'Confirm your name' : 'Enter your name',
  stepName: 'name',
  type: SurveyStepType.TEXT,
  initialValue: user => user?.name || '',
  placeholder: 'Type your name here'
}, {
  allowSkip: true,
  options: ['Handling multiple customers and external meetings', 'Managing multiple people', 'Analyzing multiple documents'],
  prompt: 'What do you need help with?',
  otherOptionLabel: 'Other (type in your answer)',
  stepName: 'What do you need help with?',
  type: SurveyStepType.RADIO,
  placeholder: 'Type your answer here'
}, {
  allowSkip: true,
  options: ['Finance', 'Customer Success / Support', 'Human Resources', 'Marketing', 'Sales', 'Operations', 'Product / Engineering'],
  prompt: 'Which of these best describes your role?',
  otherOptionLabel: 'Other (type in your answer)',
  stepName: 'role',
  type: SurveyStepType.RADIO,
  placeholder: 'Type your answer here'
}, {
  allowSkip: true,
  options: ['Friend or Colleague', 'Online advertisement', 'Social Media', 'Publication', 'Tradeshow or Event'],
  prompt: 'How did you hear about us?',
  otherOptionLabel: 'Other (type in your answer)',
  stepName: 'howDidYouHearAboutUs',
  type: SurveyStepType.RADIO,
  placeholder: 'Type your answer here'
}];
export default ONBOARDING_STEPS;