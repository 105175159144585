import _styled from "styled-components";
import { isValidElement } from 'react';
import { Filters, FiltersProps } from '../../shared/Filters';
import SortHeader, { Header, TableSort } from '../../SortHeader/SortHeader';
import { Button, ButtonType } from '../Button';
import { Color, Size } from '../constants';
import { DateTimeRangePicker, DateTimeRangePickerProps } from '../DateTimeRangePicker';
import { withLink } from '../hocs';
import { Icon, Icons } from '../Icon';
import { LoadingSpinner, LoadingSpinnerSize } from '../LoadingSpinner';
import { ToolTip } from '../ToolTip';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { Typography, TypographySize, TypographyWrap } from '../Typography';
import { StyledTableRow, TableCell, TableCellTypographyWrapper, TableCheckboxInnerContainer, TableCheckboxOuterContainer, TableCheckboxTd, TableContainer, TableHead, TableHeader, TableHeaderRow, TableNoRowsTd, TableRoot } from './Table.styles';
const DEFAULT_ROW_HEIGHT = {
  "height": "4rem"
};
export interface TableColumn<SortKey extends string = string> {
  title: string;
  sortKey?: SortKey;
  tooltipEnabled?: boolean;
  width?: TwStyle | number;
  wrap?: TypographyWrap;
}
export interface TableRow<TRowKey extends string = string> {
  cells: React.ReactNode[];
  key: TRowKey;
  disabled?: boolean;
  disabledTextColor?: Color | undefined;
  height?: TwStyle;
  href?: string;
  onClick?: () => void;
  textColor?: Color;
  toolTip?: string | undefined;
}
export interface TableSelectionProps<TRowKey> {
  selectedItems: TRowKey[];
  setSelectedItems: (keys: TRowKey[]) => void;
  singleSelection?: boolean;
}
interface ClearFilterProps {
  hasFilters: boolean;
  label: string;
  onClick: () => void;
}
export type TableProps<TRowKey extends string, FilterKey extends string, SortKey extends string> = {
  columns: TableColumn<SortKey>[];
  noRowsText: string;
  rows: (TableRow<TRowKey> | React.ReactElement)[];
  borderColor?: Color;
  clearFilterProps?: ClearFilterProps;
  dateRangePickerProps?: DateTimeRangePickerProps;
  filterProps?: FiltersProps<FilterKey>;
  loading?: boolean;
  selectionProps?: TableSelectionProps<TRowKey>;
  showColumnHeaderBorder?: boolean;
  sortProps?: {
    activeSort: TableSort<SortKey>;
    setActiveSort: (value: TableSort<SortKey>) => void;
  };
  title?: string;
};
const FilterContainer = _styled.div.withConfig({
  displayName: "Table__FilterContainer",
  componentId: "sc-1fpicri-0"
})({
  "display": "flex",
  "gap": "0.5rem"
});
const ClearFilterButtonContainer = _styled.div.withConfig({
  displayName: "Table__ClearFilterButtonContainer",
  componentId: "sc-1fpicri-1"
})({
  "display": "flex",
  "flex": "1 1 0%",
  "justifyContent": "flex-end"
});
function Table<TKey extends string, FilterKey extends string = string, SortKey extends string = string>({
  columns,
  rows,
  selectionProps,
  noRowsText,
  borderColor = Color.NAVY_LIGHT,
  clearFilterProps,
  dateRangePickerProps,
  filterProps,
  loading = false,
  showColumnHeaderBorder = false,
  sortProps,
  title
}: TableProps<TKey, FilterKey, SortKey>) {
  const {
    selectedItems,
    setSelectedItems,
    singleSelection
  } = selectionProps ?? {};
  const handleToggleSelection = (key: TKey) => {
    if (!setSelectedItems || !selectedItems) return;
    if (selectedItems?.includes(key)) {
      setSelectedItems(selectedItems.filter(item => item !== key));
    } else {
      setSelectedItems(singleSelection ? [key] : [...selectedItems, key]);
    }
  };

  // Leave space in the header for checkboxes if selection is enabled
  const columnsWithSelect: TableColumn<SortKey>[] = selectionProps ? [{
    title: '',
    sortKey: '' as SortKey,
    width: {
      "width": "8.333333%"
    }
  }, ...columns] : columns;
  return <TableContainer>
      {/* Title */}
      {title && <Typography color={Color.NAVY} size={TypographySize.H3}>
          {title}
        </Typography>}
      {/* Filters */}
      <FilterContainer>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {filterProps && <Filters {...filterProps} />}
        {dateRangePickerProps &&
      // eslint-disable-next-line react/jsx-props-no-spreading
      <DateTimeRangePicker {...dateRangePickerProps} />}
        {clearFilterProps?.hasFilters && <ClearFilterButtonContainer>
            <Button label={clearFilterProps.label} onClick={clearFilterProps.onClick} type={ButtonType.OUTLINED} />
          </ClearFilterButtonContainer>}
      </FilterContainer>
      {/* Table */}
      <TableRoot>
        <TableHead>
          <TableHeaderRow $borderColor={borderColor} $showBorder={showColumnHeaderBorder}>
            {columnsWithSelect.map(col => {
            const canSort = !!sortProps && !!col.sortKey;
            return <TableHeader key={col.title} $width={col.width}>
                  {canSort ? <SortHeader<SortKey> activeSort={sortProps.activeSort} setSortKey={newKey => sortProps.setActiveSort(newKey)} sortKey={col.sortKey ?? '' as SortKey} title={col.title} /> : <Header title={col.title} />}
                </TableHeader>;
          })}
          </TableHeaderRow>
        </TableHead>
        <tbody>
          {rows.map(row => {
          const rowIsElement = isValidElement(row);
          if (rowIsElement) return row;
          const {
            key,
            disabled,
            disabledTextColor,
            toolTip,
            height = DEFAULT_ROW_HEIGHT,
            onClick: customOnClick,
            href,
            textColor = Color.BLACK
          } = row as TableRow;
          const isSelected = selectedItems?.includes(key as TKey) ?? false;
          const isDisabled = disabled ?? false;
          const toggleRowSelectionIfEnabled = () => {
            if (disabled) return;
            handleToggleSelection(key as TKey);
          };

          // If a custom onClick is provided, prioritize that over selection
          // when entire row is clicked
          const handleRowClick = () => {
            if (isDisabled) return;
            if (customOnClick) {
              customOnClick();
            }
            toggleRowSelectionIfEnabled();
          };
          const rowIsClickable = !!selectionProps || !!customOnClick || !!href;
          const rowTextColor = disabled ? disabledTextColor || Color.DISABLED : textColor;
          return <ToolTip key={row.key} content={toolTip}>
                <StyledTableRow $borderColor={borderColor} $clickable={rowIsClickable} $disabled={isDisabled} $height={height} $selected={isSelected} className="group" onClick={handleRowClick}>
                  {/* Selection checkmark */}
                  {selectionProps && <TableCheckboxTd onClick={toggleRowSelectionIfEnabled}>
                      <TableCheckboxOuterContainer $isSelected={isSelected}>
                        {isSelected && <TableCheckboxInnerContainer>
                            <Icons color={Color.WHITE} icon={Icon.CHECK} size={Size.LARGE} />
                          </TableCheckboxInnerContainer>}
                      </TableCheckboxOuterContainer>
                    </TableCheckboxTd>}
                  {(row as TableRow).cells.map((cell, index) => {
                const column = columns[index];
                const {
                  width,
                  wrap,
                  tooltipEnabled
                } = column ?? {};
                const typographyMaxWidth = typeof width === 'number' ? width : undefined;
                return <TableCell
                // TODO: Fix
                // eslint-disable-next-line react/no-array-index-key
                key={`row-${row.key}-cell-${index}`} $width={width}>
                        {withLink(<>
                            {isValidElement(cell) && cell}
                            {!isValidElement(cell) &&
                    // Wrap in flex so that table cell width is respected
                    // by text and not overflowed
                    <ToolTip content={cell?.toString()} disabled={!tooltipEnabled} placement={TooltipPlacement.TOP_START}>
                                <TableCellTypographyWrapper>
                                  {/* If max width is set, show tooltip
                                   so overflowed text is visible */}

                                  <Typography color={rowTextColor} maxWidth={typographyMaxWidth} wrap={wrap}>
                                    {cell}
                                  </Typography>
                                </TableCellTypographyWrapper>
                              </ToolTip>}
                          </>, {
                    href,
                    fullSize: true
                  })}
                      </TableCell>;
              })}
                </StyledTableRow>
              </ToolTip>;
        })}
          {!rows.length && !loading && <tr>
              <TableNoRowsTd colSpan={7}>
                <Typography>{noRowsText}</Typography>
              </TableNoRowsTd>
            </tr>}
        </tbody>
      </TableRoot>
      {loading && <LoadingSpinner size={LoadingSpinnerSize.LARGE} />}
    </TableContainer>;
}
export default Table;