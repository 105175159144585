import {
  PROMPT_TEMPLATE_TAG_PREFIXES,
  PromptTemplateTagType
} from '../constants/promptTemplate.consts';

/**
 * Given prompt "test {{userInput.name}} test {{staticContent.name}} test"
 * and tagType USER_INPUT, returns ["{{userInput.name}}"].
 */
export const findPromptTemplateTags = (
  prompt: string,
  tagType: PromptTemplateTagType
): string[] =>
  prompt.match(
    new RegExp(`{{\\s*?${PROMPT_TEMPLATE_TAG_PREFIXES[tagType]}.+?\\s*?}}`, 'g')
  ) || [];

/**
 * Given tag "{{userInput.name}}" and tagType USER_INPUT, returns "name".
 */
export const extractValueFromPromptTemplateTag = (
  tag: string,
  tagType: PromptTemplateTagType
): string =>
  tag
    .replace(/[{}]/g, '')
    .replace(`${PROMPT_TEMPLATE_TAG_PREFIXES[tagType]}:`, '');

/**
 * Given tag "{{userInput.name}}", return { tagType: USER_INPUT, value: 'name'}.
 */
export const getPromptTemplateTagTypeAndValue = (
  tag: string
): { tagType: PromptTemplateTagType; value: string } | null => {
  const tagType = Object.values(PromptTemplateTagType).find((type) =>
    tag.includes(PROMPT_TEMPLATE_TAG_PREFIXES[type])
  );

  if (!tagType) return null;

  const value = extractValueFromPromptTemplateTag(tag, tagType);
  return { tagType, value };
};

export const getUserInputPromptTemplateTag = (
  templateResolutionName: string
) => {
  const prefix = PROMPT_TEMPLATE_TAG_PREFIXES[PromptTemplateTagType.USER_INPUT];
  return '{{' + prefix + ':' + templateResolutionName + '}}';
};

export const getStaticContentPromptTemplateTag = (contentId: string) => {
  const prefix =
    PROMPT_TEMPLATE_TAG_PREFIXES[PromptTemplateTagType.STATIC_CONTENT];
  return '{{' + prefix + ':' + contentId + '}}';
};

export const getPromptTemplateTag = (
  templateResolutionName: string,
  tagType: PromptTemplateTagType
) => {
  if (tagType === PromptTemplateTagType.USER_INPUT) {
    return getUserInputPromptTemplateTag(templateResolutionName);
  }

  return getStaticContentPromptTemplateTag(templateResolutionName);
};

/**
 * Given a display name, returns a sanitized identifying name for a prompt template variable.
 * Replaces white space with "-"
 * Removes colons
 * Removes curly braces
 */
export function getPromptTemplateVariableTemplateResolutionNameFromDisplayName(
  displayName: string
): string {
  return displayName.toLowerCase().replace(/\s/g, '-').replace(/:|{|}/g, '');
}
