/**
 * TODO: Use color enum with Primary, Secondary, etc for component props
 * once we establish a true design system
 */

export enum Color {
  BLACK = 'BLACK',
  BLUE = 'BLUE',
  DISABLED = 'DISABLED',
  GRAY = 'GRAY',
  GRAY_LIGHT = 'GRAY_LIGHT',
  GREEN = 'GREEN',
  LAVENDER = 'LAVENDER',
  MID_NEUTRAL = 'MID_NEUTRAL',
  NAVY = 'NAVY',
  NAVY_LIGHT = 'NAVY_DISABLED',
  NEUTRAL_LIGHT = 'NEUTRAL_LIGHT',
  ORANGE = 'ORANGE',
  PALE_GREEN = 'PALE_GREEN',
  RED = 'RED',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
}
export const TEXT_COLORS: Record<Color, TwStyle> = {
  [Color.BLACK]: {
    "--tw-text-opacity": "1",
    "color": "rgb(22 25 37 / var(--tw-text-opacity))"
  },
  [Color.DISABLED]: {
    "--tw-text-opacity": "1",
    "color": "rgb(155 163 191 / var(--tw-text-opacity))"
  },
  [Color.GRAY]: {
    "--tw-text-opacity": "1",
    "color": "rgb(107 114 128 / var(--tw-text-opacity))"
  },
  [Color.GRAY_LIGHT]: {
    "--tw-text-opacity": "1",
    "color": "rgb(229 231 235 / var(--tw-text-opacity))"
  },
  [Color.NEUTRAL_LIGHT]: {
    "--tw-text-opacity": "1",
    "color": "rgb(229 229 229 / var(--tw-text-opacity))"
  },
  [Color.MID_NEUTRAL]: {
    "--tw-text-opacity": "1",
    "color": "rgb(155 163 191 / var(--tw-text-opacity))"
  },
  [Color.NAVY]: {
    "--tw-text-opacity": "1",
    "color": "rgb(9 90 124 / var(--tw-text-opacity))"
  },
  [Color.NAVY_LIGHT]: {
    "color": "#095A7C58"
  },
  [Color.ORANGE]: {
    "--tw-text-opacity": "1",
    "color": "rgb(251 146 60 / var(--tw-text-opacity))"
  },
  [Color.PALE_GREEN]: {
    "--tw-text-opacity": "1",
    "color": "rgb(206 255 194 / var(--tw-text-opacity))"
  },
  [Color.RED]: {
    "--tw-text-opacity": "1",
    "color": "rgb(239 68 68 / var(--tw-text-opacity))"
  },
  [Color.WHITE]: {
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))"
  },
  [Color.BLUE]: {
    "--tw-text-opacity": "1",
    "color": "rgb(77 114 128 / var(--tw-text-opacity))"
  },
  [Color.GREEN]: {
    "--tw-text-opacity": "1",
    "color": "rgb(29 211 176 / var(--tw-text-opacity))"
  },
  [Color.YELLOW]: {
    "--tw-text-opacity": "1",
    "color": "rgb(252 211 77 / var(--tw-text-opacity))"
  },
  [Color.LAVENDER]: {
    "--tw-text-opacity": "1",
    "color": "rgb(197 192 255 / var(--tw-text-opacity))"
  }
};
export const BORDER_COLORS: Record<Color, TwStyle> = {
  [Color.BLACK]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(22 25 37 / var(--tw-border-opacity))"
  },
  [Color.DISABLED]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(155 163 191 / var(--tw-border-opacity))"
  },
  [Color.GRAY]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(107 114 128 / var(--tw-border-opacity))"
  },
  [Color.GRAY_LIGHT]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(229 231 235 / var(--tw-border-opacity))"
  },
  [Color.LAVENDER]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(197 192 255 / var(--tw-border-opacity))"
  },
  [Color.MID_NEUTRAL]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(155 163 191 / var(--tw-border-opacity))"
  },
  [Color.NAVY]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(9 90 124 / var(--tw-border-opacity))"
  },
  [Color.NAVY_LIGHT]: {
    "borderColor": "#095A7C58"
  },
  [Color.NEUTRAL_LIGHT]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(229 229 229 / var(--tw-border-opacity))"
  },
  [Color.ORANGE]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(251 146 60 / var(--tw-border-opacity))"
  },
  [Color.PALE_GREEN]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(206 255 194 / var(--tw-border-opacity))"
  },
  [Color.RED]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(239 68 68 / var(--tw-border-opacity))"
  },
  [Color.WHITE]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(255 255 255 / var(--tw-border-opacity))"
  },
  [Color.BLUE]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
  },
  [Color.GREEN]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(29 211 176 / var(--tw-border-opacity))"
  },
  [Color.YELLOW]: {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(252 211 77 / var(--tw-border-opacity))"
  }
};