import { OrgSettings } from '@prisma/client';

import { OmitStrict } from '../utils';

// This is a duplicate of Role from main.prisma.
export enum Role {
  Admin = 'Admin',
  Member = 'Member'
}

// Type guard function to check if a value is a valid Role
export function isValidOrgRole(value: any): value is Role {
  return Object.values(Role).includes(value);
}

export type OrgSettingsConfig = OmitStrict<OrgSettings, 'id'>;

export type OrgSetting = keyof OrgSettingsConfig;

export const ORG_SETTING_DISPLAY_NAMES: Record<
  // Although the codeSandboxEnabled setting is not used in UI,
  // it's intentionally excluded from the type to prevent it from being used.
  // TODO: @yeunha Remove this once the UI is updated to use the codeSandboxEnabled setting
  Exclude<OrgSetting, 'codeSandboxEnabled'>,
  string
> = {
  minimumRoleForSharing: 'Minimum Role for Sharing',
  externalSharingEnabled: 'External Sharing Enabled',
  defaultModelAccessEnabled: 'Default Model Access Enabled',
  defaultProviderAccessEnabled: 'Default Provider Access Enabled',
  defaultModelDlpEnabled: 'Default Model DLP Enabled',
  defaultProviderDlpEnabled: 'Default Provider DLP Enabled'
};
