export enum TypographySize {
  H1 = 'H1',
  // 36px
  H2 = 'H2',
  // 30px
  H3 = 'H3',
  // 24px
  H4 = 'H4',
  // 20px
  LARGE = 'LARGE',
  // 18px
  MEDIUM = 'MEDIUM',
  // 16px
  SMALL = 'SMALL',
  // 14px
  X_SMALL = 'X_SMALL', // 12px
}
export enum TypographyWeight {
  BOLD = 'BOLD',
  // 700
  LIGHT = 'LIGHT',
  // 300
  MEDIUM = 'MEDIUM',
  // 468
  NORMAL = 'NORMAL',
  // 400
  SEMI_BOLD = 'SEMI_BOLD',
  // 600
  SEMI_LIGHT = 'SEMI_LIGHT',
  // 350
  SEMI_NORMAL = 'SEMI_NORMAL', // 375
}
export enum TypographyLetterSpacing {
  NORMAL = 'NORMAL',
  // 0px
  WIDE = 'WIDE', // 0.42px
}
export enum TypographyCasing {
  CAPITALIZE = 'CAPITALIZE',
  LOWERCASE = 'LOWERCASE',
  NONE = 'NONE',
  UPPERCASE = 'UPPERCASE',
}
export enum TypographyAlignment {
  CENTER = 'CENTER',
  JUSTIFY = 'JUSTIFY',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
export enum TypographyWrap {
  NORMAL = 'NORMAL',
  NO_WRAP = 'NO_WRAP',
  PRE_WRAP = 'PRE_WRAP',
}