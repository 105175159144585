import ARROW_DOWN_SVG from './assets/arrow-down.icon.svg';
import ARROW_RIGHT_SVG from './assets/arrow-right.icon.svg';
import ASTERISK_SVG from './assets/asterisk.icon.svg';
import BOLT_SVG from './assets/bolt.icon.svg';
import CHAT_SVG from './assets/chat.icon.svg';
import CHECK_SVG from './assets/check.icon.svg';
import CHEVRON_DOWN_SVG from './assets/chevron-down.icon.svg';
import CHEVRON_RIGHT_SVG from './assets/chevron-right.icon.svg';
import CHEVRON_UP_SVG from './assets/chevron-up.icon.svg';
import CLOSE_SVG from './assets/close.icon.svg';
import COPY_SVG from './assets/copy.icon.svg';
import DOUBLE_CHEVRON_RIGHT_SVG from './assets/double-chevron-right.icon.svg';
import DRAG_HANDLE_SVG from './assets/drag-handle.icon.svg';
import EDIT_FILE_SVG from './assets/edit-file.icon.svg';
import EDIT_SVG from './assets/edit.icon.svg';
import EYE_SVG from './assets/eye.icon.svg';
import FILE_SVG from './assets/file.icon.svg';
import FILTER_SVG from './assets/filter.icon.svg';
import GRID_SVG from './assets/grid.icon.svg';
import HOME_SVG from './assets/home.icon.svg';
import DROPBOX_SVG from './assets/integration-assets/dropbox.icon.svg';
import GOOGLE_DRIVE_SVG from './assets/integration-assets/google-drive.icon.svg';
import GOOGLE_SVG from './assets/integration-assets/google.icon.svg';
import LINEAR_SVG from './assets/integration-assets/linear.icon.svg';
import MICROSOFT_SVG from './assets/integration-assets/microsoft.icon.svg';
import OKTA_SVG from './assets/integration-assets/okta.icon.svg';
import SERVICENOW_SVG from './assets/integration-assets/service-now.icon.svg';
import SLACK_SVG from './assets/integration-assets/slack.icon.svg';
import ZENDESK_SVG from './assets/integration-assets/zendesk.icon.svg';
import KINDO_LOGO_SVG from './assets/kindoLogos/k-logo.icon.svg';
import LOADING_SVG from './assets/loading.svg';
import LOCK_SVG from './assets/lock.icon.svg';
import LOGOUT_SVG from './assets/logout.icon.svg';
import MENU_SVG from './assets/menu.icon.svg';
import MORE_SVG from './assets/more.icon.svg';
import PLAY_SVG from './assets/play.icon.svg';
import PLUS_CIRCLE_SVG from './assets/plus-circle.icon.svg';
import PLUS_SVG from './assets/plus.icon.svg';
import PROFILE_SVG from './assets/profile.icon.svg';
import QUICK_NAV_BLOCK_SVG from './assets/quick-nav-block.icon.svg';
import QUICK_NAV_LINE_SVG from './assets/quick-nav-line.icon.svg';
import QUICK_NAV_SVG from './assets/quick-nav.icon.svg';
import RELOAD_SVG from './assets/reload.icon.svg';
import SAVE_SVG from './assets/save.icon.svg';
import SEARCH_SVG from './assets/search.icon.svg';
import SEND_SVG from './assets/send.icon.svg';
import SETTINGS_SVG from './assets/settings.icon.svg';
import SHARE_SVG from './assets/share.icon.svg';
import STORAGE_SVG from './assets/storage.icon.svg';
import SUPPORT_SVG from './assets/support.icon.svg';
import THUMBS_DOWN_SVG from './assets/thumbs-down.icon.svg';
import THUMBS_UP_SVG from './assets/thumbs-up.icon.svg';
import TRASH_SVG from './assets/trash.icon.svg';
import UPLOAD_SVG from './assets/upload.icon.svg';
export enum Icon {
  ARROW_DOWN = 'ARROW_DOWN',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ASTERISK = 'ASTERISK',
  BOLT = 'BOLT',
  CHAT = 'CHAT',
  CHECK = 'CHECK',
  CHEVRON_DOWN = 'CHEVRON_DOWN',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_UP = 'CHEVRON_UP',
  CLOSE = 'CLOSE',
  COPY = 'COPY',
  DOUBLE_CHEVRON_RIGHT = 'DOUBLE_CHEVRON_RIGHT',
  DRAG_HANDLE = 'DRAG_HANDLE',
  DROPBOX = 'DROPBOX',
  EDIT = 'EDIT',
  EDIT_FILE = 'EDIT_FILE',
  EYE = 'EYE',
  FILE = 'FILE',
  FILTER = 'FILTER',
  GOOGLE = 'GOOGLE',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  GRID = 'GRID',
  HOME = 'HOME',
  KINDO_LOGO = 'KINDO_LOGO',
  LINEAR = 'LINEAR',
  LOADING = 'LOADING',
  LOCK = 'LOCK',
  LOGOUT = 'LOGOUT',
  MENU = 'MENU',
  MICROSOFT = 'MICROSOFT',
  MORE = 'MORE',
  OKTA = 'OKTA',
  PLAY = 'PLAY',
  PLUS = 'PLUS',
  PLUS_CIRCLE = 'PLUS_CIRCLE',
  PROFILE = 'PROFILE',
  QUICK_NAV = 'QUICK_NAV',
  QUICK_NAV_BLOCK = 'QUICK_NAV_BLOCK',
  QUICK_NAV_LINE = 'QUICK_NAV_LINE',
  RELOAD = 'RELOAD',
  SAVE = 'SAVE',
  SEARCH = 'SEARCH',
  SEND = 'SEND',
  SERVICENOW = 'SERVICENOW',
  SETTINGS = 'SETTINGS',
  SHARE = 'SHARE',
  SLACK = 'SLACK',
  STORAGE = 'STORAGE',
  SUPPORT = 'SUPPORT',
  THUMBS_DOWN = 'THUMBS_DOWN',
  THUMBS_UP = 'THUMBS_UP',
  TRASH = 'TRASH',
  UPLOAD = 'UPLOAD',
  ZENDESK = 'ZENDESK',
}

// TODO: Make this Primary, Secondary, etc
// Once we establish a true design system
export enum IconColor {
  BLACK = 'BLACK',
  MID_NEUTRAL = 'MID_NEUTRAL',
  NAVY = 'NAVY',
  NAVY_LIGHT = 'NAVY_DISABLED',
  ORIGINAL = 'ORIGINAL',
  PALE_GREEN = 'PALE_GREEN',
  WHITE = 'WHITE',
}
export const ICON_SVGS: Record<Icon, string> = {
  [Icon.ARROW_DOWN]: ARROW_DOWN_SVG,
  [Icon.ARROW_RIGHT]: ARROW_RIGHT_SVG,
  [Icon.ASTERISK]: ASTERISK_SVG,
  [Icon.BOLT]: BOLT_SVG,
  [Icon.CHAT]: CHAT_SVG,
  [Icon.CHECK]: CHECK_SVG,
  [Icon.CHEVRON_DOWN]: CHEVRON_DOWN_SVG,
  [Icon.CHEVRON_RIGHT]: CHEVRON_RIGHT_SVG,
  [Icon.CHEVRON_UP]: CHEVRON_UP_SVG,
  [Icon.CLOSE]: CLOSE_SVG,
  [Icon.COPY]: COPY_SVG,
  [Icon.DOUBLE_CHEVRON_RIGHT]: DOUBLE_CHEVRON_RIGHT_SVG,
  [Icon.DRAG_HANDLE]: DRAG_HANDLE_SVG,
  [Icon.DROPBOX]: DROPBOX_SVG,
  [Icon.EDIT]: EDIT_SVG,
  [Icon.EDIT_FILE]: EDIT_FILE_SVG,
  [Icon.EYE]: EYE_SVG,
  [Icon.FILE]: FILE_SVG,
  [Icon.FILTER]: FILTER_SVG,
  [Icon.GOOGLE]: GOOGLE_SVG,
  [Icon.GOOGLE_DRIVE]: GOOGLE_DRIVE_SVG,
  [Icon.GRID]: GRID_SVG,
  [Icon.HOME]: HOME_SVG,
  [Icon.KINDO_LOGO]: KINDO_LOGO_SVG,
  [Icon.LINEAR]: LINEAR_SVG,
  [Icon.LOADING]: LOADING_SVG,
  [Icon.LOCK]: LOCK_SVG,
  [Icon.LOGOUT]: LOGOUT_SVG,
  [Icon.MENU]: MENU_SVG,
  [Icon.MICROSOFT]: MICROSOFT_SVG,
  [Icon.MORE]: MORE_SVG,
  [Icon.PLAY]: PLAY_SVG,
  [Icon.PLUS]: PLUS_SVG,
  [Icon.PLUS_CIRCLE]: PLUS_CIRCLE_SVG,
  [Icon.OKTA]: OKTA_SVG,
  [Icon.PROFILE]: PROFILE_SVG,
  [Icon.QUICK_NAV]: QUICK_NAV_SVG,
  [Icon.QUICK_NAV_BLOCK]: QUICK_NAV_BLOCK_SVG,
  [Icon.QUICK_NAV_LINE]: QUICK_NAV_LINE_SVG,
  [Icon.RELOAD]: RELOAD_SVG,
  [Icon.SAVE]: SAVE_SVG,
  [Icon.SEARCH]: SEARCH_SVG,
  [Icon.SEND]: SEND_SVG,
  [Icon.SERVICENOW]: SERVICENOW_SVG,
  [Icon.SETTINGS]: SETTINGS_SVG,
  [Icon.SHARE]: SHARE_SVG,
  [Icon.SLACK]: SLACK_SVG,
  [Icon.STORAGE]: STORAGE_SVG,
  [Icon.SUPPORT]: SUPPORT_SVG,
  [Icon.THUMBS_UP]: THUMBS_UP_SVG,
  [Icon.THUMBS_DOWN]: THUMBS_DOWN_SVG,
  [Icon.TRASH]: TRASH_SVG,
  [Icon.UPLOAD]: UPLOAD_SVG,
  [Icon.ZENDESK]: ZENDESK_SVG
};