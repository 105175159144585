import { useRouter } from 'next/router';

const useURLParams = <TQuery extends Record<string, string | string[]>>() => {
  const router = useRouter();
  const decodeString = (value: unknown) =>
    typeof value === 'string' ? decodeURIComponent(value) : value;
  return Object.entries(router.query).reduce((acc, [key, value]) => {
    const arrayValue = Array.isArray(value) ? value.map(decodeString) : null;
    const stringValue = decodeString(value);
    return {
      ...acc,
      [key]: arrayValue || stringValue || value
    };
  }, {} as TQuery);
};

export default useURLParams;
