import { z } from 'zod';

import {
  IntegrationWorkflowInputType,
  WorkflowInputType
} from './workflow.consts';

/** Field Condition Enums */
export enum TicketingIntegrationTicketTriggerConditionField {
  ASSIGNEE_EMAIL = 'ASSIGNEE_EMAIL',
  ASSIGNEE_NAME = 'ASSIGNEE_NAME',
  CREATOR_EMAIL = 'CREATOR_EMAIL',
  CREATOR_NAME = 'CREATOR_NAME',
  DESCRIPTION = 'DESCRIPTION',
  PRIORITY = 'PRIORITY',
  STATUS = 'STATUS',
  TITLE = 'TITLE'
}

export enum TicketingPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  URGENT = 'URGENT'
}

export enum TicketingStatus {
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  OPEN = 'OPEN'
}

export const Z_TICKETING_TRIGGER_CONDITION_FIELD_VALUES = {
  [TicketingIntegrationTicketTriggerConditionField.ASSIGNEE_EMAIL]: z.string(),
  [TicketingIntegrationTicketTriggerConditionField.ASSIGNEE_NAME]: z.string(),
  [TicketingIntegrationTicketTriggerConditionField.CREATOR_EMAIL]: z.string(),
  [TicketingIntegrationTicketTriggerConditionField.CREATOR_NAME]: z.string(),
  [TicketingIntegrationTicketTriggerConditionField.DESCRIPTION]: z.string(),
  [TicketingIntegrationTicketTriggerConditionField.PRIORITY]:
    z.nativeEnum(TicketingPriority),
  [TicketingIntegrationTicketTriggerConditionField.STATUS]:
    z.nativeEnum(TicketingStatus),
  [TicketingIntegrationTicketTriggerConditionField.TITLE]: z.string()
} as const satisfies Record<
  TicketingIntegrationTicketTriggerConditionField,
  z.ZodType
>;

export type TicketingIntegrationTicketTriggerConditionValue<
  T extends TicketingIntegrationTicketTriggerConditionField
> = z.infer<(typeof Z_TICKETING_TRIGGER_CONDITION_FIELD_VALUES)[T]>;

export const TICKETING_INTEGRATION_TICKET_FIELD_DISPLAY_NAMES = {
  [TicketingIntegrationTicketTriggerConditionField.TITLE]: 'Title',
  [TicketingIntegrationTicketTriggerConditionField.DESCRIPTION]: 'Description',
  [TicketingIntegrationTicketTriggerConditionField.PRIORITY]: 'Priority',
  [TicketingIntegrationTicketTriggerConditionField.STATUS]: 'Status',
  [TicketingIntegrationTicketTriggerConditionField.ASSIGNEE_EMAIL]:
    'Assignee Email',
  [TicketingIntegrationTicketTriggerConditionField.ASSIGNEE_NAME]:
    'Assignee Name',
  [TicketingIntegrationTicketTriggerConditionField.CREATOR_EMAIL]:
    'Creator Email',
  [TicketingIntegrationTicketTriggerConditionField.CREATOR_NAME]: 'Creator Name'
} as const satisfies Readonly<
  Record<TicketingIntegrationTicketTriggerConditionField, string>
>;

/** Mapping of Integration Input Type to Trigger Condition Fields */
export const INTEGRATION_INPUT_TYPE_TO_TRIGGER_CONDITION_FIELDS = {
  [WorkflowInputType.TICKETING_INTEGRATION_TICKET]: Object.values(
    TicketingIntegrationTicketTriggerConditionField
  )
} as const satisfies Readonly<
  Record<
    IntegrationWorkflowInputType,
    TicketingIntegrationTicketTriggerConditionField[]
  >
>;

/** Type Guards */
export function isTicketingIntegrationTicketTriggerConditionField(
  field: string
): field is TicketingIntegrationTicketTriggerConditionField {
  return Object.values(
    TicketingIntegrationTicketTriggerConditionField
  ).includes(field as TicketingIntegrationTicketTriggerConditionField);
}

export function isTicketingPriority(
  priority: string | undefined
): priority is TicketingPriority {
  return Object.values(TicketingPriority).includes(
    priority as TicketingPriority
  );
}

export function isTicketingStatus(
  status: string | undefined
): status is TicketingStatus {
  return Object.values(TicketingStatus).includes(status as TicketingStatus);
}
