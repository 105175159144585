import { toast as reactToast } from 'react-toastify';

import { Icons, Color, Icon } from '~/components/core';

export enum ToastType {
  ERROR = 'ERROR',
  REFRESH = 'REFRESH',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING'
}

export interface Toast {
  message: string;
  type: ToastType;
  autoClose?: boolean;
  delay?: boolean;
  onClick?: () => void;
  preventClose?: boolean;
}

const getAutoClose = (type: ToastType, autoClose: boolean): number | false => {
  if (!autoClose) {
    return false;
  }

  switch (type) {
    case ToastType.ERROR:
      return 5000;
    case ToastType.REFRESH:
      return 7000;
    case ToastType.SUCCESS:
    case ToastType.WARNING:
    default:
      return 3000;
  }
};

const WhiteCloseButton = () => <Icons color={Color.WHITE} icon={Icon.CLOSE} />;

const useToast = () => {
  const enqueueToast = ({
    type,
    message,
    delay,
    autoClose = true,
    preventClose = false,
    onClick = () => {}
  }: Toast) => {
    const showCloseButton = !autoClose && !preventClose;

    switch (type) {
      case ToastType.SUCCESS:
        reactToast.success(message, {
          icon: false,
          draggablePercent: 60,
          autoClose: getAutoClose(ToastType.SUCCESS, autoClose),
          hideProgressBar: true,
          closeButton: showCloseButton && WhiteCloseButton,
          closeOnClick: showCloseButton,
          style: {
            backgroundColor: 'rgba(29, 211, 176)',
            color: 'white'
          },
          position: 'top-right',
          delay: delay ? 2000 : 0
        });
        break;
      case ToastType.ERROR:
        reactToast.error(message, {
          icon: false,
          autoClose: getAutoClose(ToastType.ERROR, autoClose),
          draggablePercent: 60,
          hideProgressBar: true,
          closeButton: showCloseButton && WhiteCloseButton,
          closeOnClick: showCloseButton,
          onClick,
          position: 'top-right',
          style: {
            backgroundColor: 'rgba(255, 0, 0)',
            color: 'white'
          },
          delay: delay ? 2000 : 0
        });
        break;
      case ToastType.WARNING:
        reactToast.warn(message, {
          icon: false,
          autoClose: getAutoClose(ToastType.WARNING, autoClose),
          draggablePercent: 60,
          hideProgressBar: true,
          closeButton: showCloseButton && WhiteCloseButton,
          closeOnClick: showCloseButton,
          onClick,
          position: 'top-right',
          style: {
            backgroundColor: 'rgba(255, 0, 0)',
            color: 'white'
          },
          delay: delay ? 2000 : 0
        });
        break;
      case ToastType.REFRESH:
      default:
        reactToast.info(message, {
          icon: <Icons color={Color.WHITE} icon={Icon.RELOAD} />,
          autoClose: getAutoClose(ToastType.REFRESH, autoClose),
          draggablePercent: 60,
          hideProgressBar: true,
          closeButton: showCloseButton && WhiteCloseButton,
          closeOnClick: showCloseButton,
          onClick,
          position: 'top-right',
          style: {
            backgroundColor: 'rgba(9, 90, 124)',
            color: 'white',
            width: 'max-content', // fit text to toast
            marginLeft: 'auto', // to show it on the right side of ToastContainer
            minHeight: 'unset',
            padding: '4px 6px'
          },
          delay: delay ? 2000 : 0
        });
        break;
    }
  };
  return { enqueueToast };
};

export default useToast;
