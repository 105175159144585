import { AppProps } from 'next/app';
import { Source_Sans_3 } from 'next/font/google';
import Head from 'next/head';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import 'raf/polyfill';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import AuthWrapper from '../components/AppProviders/AuthWrapper';
import ProviderWrapper from '../components/AppProviders/ProviderWrapper';
import '../styles/index.css';
import { ErrorScreen } from '~/components/ErrorScreen';
import { reduxWrapper } from '~/redux/store';
import GlobalStyles from '~/styles/GlobalStyles';
import { nextTrpc } from '~/trpc';
const GTM_ID = 'GTM-5K24CGF';
const HEAP_ID = process.env.HEAP_ID || '';
const sourceSans3 = Source_Sans_3({
  subsets: ['latin', 'latin-ext'],
  variable: '--font-source-sans-3'
});
function MyApp({
  Component,
  pageProps
}: AppProps) {
  // Workaround to add the global font styles to the body, which lives
  // outside of the Next app main BUT contains portals for things like
  // modals and poppers
  useEffect(() => {
    document.body.classList.add(sourceSans3.variable, 'font-sans');
  }, []);
  return <main className={`${sourceSans3.variable} font-sans`}>
      <style global jsx>{`
        :root {
          --font-base: ${sourceSans3.style.fontFamily};
        }
      `}</style>
      <Head>
        <title>
          {process.env.NODE_ENV === 'production' ? 'Kindo' : 'Kindo (Dev)'}
        </title>
        <meta content="Usable Machines: Accessible Artificial Intelligence" name="description" />
        <link href="/favicon.svg" rel="icon" />
      </Head>
      {/* Google Tag Manager (GTM) */}
      <Script id="googletagmanager">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
      </Script>
      {/* Heap */}
      {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && <Script id="heap">
          {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("${HEAP_ID}");`}
        </Script>}
      <SessionProvider>
        <ErrorBoundary FallbackComponent={ErrorScreen} onError={console.error}>
          <ProviderWrapper>
            <GlobalStyles />
            <AuthWrapper>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Component {...pageProps} />
            </AuthWrapper>
          </ProviderWrapper>
        </ErrorBoundary>
      </SessionProvider>
    </main>;
}
export default reduxWrapper.withRedux(nextTrpc.withTRPC(MyApp));