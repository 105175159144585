export enum PusherEvent {
  CHAT_LOADING_INDICATOR_UPDATE = 'CHAT_LOADING_INDICATOR_UPDATE',
  COMPLETE = 'complete',
  DELTA = 'delta',
  ERROR = 'error',
  PROCESSING_NEW_MESSAGE = 'PROCESSING_NEW_MESSAGE',
  SET_MESSAGE = 'set_message'
}

export function getPusherChatChannel(chatId: string) {
  return `private-chat-${chatId}`;
}

export function getPusherWorkflowJobChannel(jobId: string) {
  return `private-job-${jobId}`;
}
