import _styled from "styled-components";
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { Color, Icon, Icons, Size, ToolTip, ToolTipColor, Typography } from '~/components/core';
import { TypographySize, TypographyWeight, TypographyWrap } from '~/components/core/Typography';
const SelectOptionContainer = _styled.li.withConfig({
  displayName: "SelectOption__SelectOptionContainer",
  componentId: "sc-j9do9x-0"
})(({
  $destructive,
  $disabled
}) => [{
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "userSelect": "none",
  "gap": "0.25rem",
  "borderRadius": "0.375rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "&:hover": {
    "backgroundColor": "#8CAEBA33"
  }
}, $destructive && {
  "&:hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(254 226 226 / var(--tw-bg-opacity))"
  }
}, $disabled && {
  "cursor": "not-allowed",
  "opacity": "0.5"
}]);
const TextWrapper = _styled.div.withConfig({
  displayName: "SelectOption__TextWrapper",
  componentId: "sc-j9do9x-1"
})(["", ""], ({
  $startIcon
}) => $startIcon ? {
  "marginLeft": "0.5rem"
} : {});
const ContentWrapper = _styled.div.withConfig({
  displayName: "SelectOption__ContentWrapper",
  componentId: "sc-j9do9x-2"
})({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between"
});
const TextAndStartIconWrapper = _styled.div.withConfig({
  displayName: "SelectOption__TextAndStartIconWrapper",
  componentId: "sc-j9do9x-3"
})({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "flex-start"
});
const SuffixAndEndIconWrapper = _styled.div.withConfig({
  displayName: "SelectOption__SuffixAndEndIconWrapper",
  componentId: "sc-j9do9x-4"
})({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "flex-start",
  "gap": "0.25rem",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(0.25rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))"
  },
  "paddingLeft": "1.25rem"
});
export interface SelectOptionProps<Value extends string | null = string> {
  destructive: boolean;
  disabled: boolean;
  isSelected: boolean;
  label: string;
  onClick: (value: Value) => void;
  value: Value;
  endIcon?: {
    icon: Icon;
    color?: Color;
    size?: Size;
  } | undefined;
  prefix?: string;
  startIcon?: {
    icon: Icon;
    color?: Color;
    size?: Size;
  } | undefined;
  suffix?: {
    text: string;
    color?: Color;
    size?: TypographySize;
  };
  tooltip?: string;
}
export const SelectOption = <Value extends string | null = string,>({
  label,
  onClick,
  value,
  isSelected,
  disabled,
  startIcon,
  endIcon,
  prefix,
  suffix,
  destructive,
  tooltip
}: SelectOptionProps<Value>) => {
  const iconAndTextColor = destructive ? Color.RED : Color.NAVY;
  return <ToolTip color={ToolTipColor.BLUE} content={tooltip ?? ''} disabled={disabled} offset={[0, 8]} placement={TooltipPlacement.TOP_START}>
      <SelectOptionContainer $destructive={destructive} $disabled={disabled} data-value={value} onClick={() => !disabled && onClick(value)}>
        <ContentWrapper>
          <TextAndStartIconWrapper>
            {!!prefix && prefix}
            {!!startIcon && <Icons color={startIcon.color} icon={startIcon.icon} size={startIcon.size} />}
            <TextWrapper $startIcon={!!startIcon}>
              <Typography color={iconAndTextColor} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                {label}
              </Typography>
            </TextWrapper>
          </TextAndStartIconWrapper>
          {isSelected && !!value && <Icons color={Color.NAVY} icon={Icon.CHECK} />}
        </ContentWrapper>
        <SuffixAndEndIconWrapper>
          {!!suffix && <Typography color={suffix.color ? suffix.color : Color.NAVY} size={suffix.size ? suffix.size : TypographySize.SMALL} wrap={TypographyWrap.NO_WRAP}>
              {suffix.text}
            </Typography>}
          {!!endIcon && <Icons color={endIcon.color} icon={endIcon.icon} size={endIcon.size} />}
        </SuffixAndEndIconWrapper>
      </SelectOptionContainer>
    </ToolTip>;
};
export default SelectOption;