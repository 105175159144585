import _styled from "styled-components";
import React, { useEffect, useState } from 'react';
import { DefaultTheme } from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';
import { Typography, TypographySize } from '../Typography';
import { Color } from '../constants';
import { Badge } from '~/components/Badge';
// eslint-disable-next-line import/extensions
import tailwindConfig from '~/tailwind.config';
const {
  theme
} = resolveConfig(tailwindConfig);
const NAVY_BLUE = (theme as DefaultTheme)?.colors?.blue?.navy?.DEFAULT;
const GRAY_ANON = (theme as DefaultTheme)?.colors?.gray?.anon;
const Wrapper = _styled.div.withConfig({
  displayName: "Slider__Wrapper",
  componentId: "sc-1a6wxvf-0"
})({
  "width": "100%"
});
const LabelContainer = _styled.label.withConfig({
  displayName: "Slider__LabelContainer",
  componentId: "sc-1a6wxvf-1"
})({
  "display": "flex",
  "flexDirection": "row",
  "justifyContent": "space-between"
});
const InputBadge = _styled.input.withConfig({
  displayName: "Slider__InputBadge",
  componentId: "sc-1a6wxvf-2"
})({
  "width": "3rem",
  "textAlign": "center",
  "&:focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)"
  }
});
const InputContainer = _styled.div.withConfig({
  displayName: "Slider__InputContainer",
  componentId: "sc-1a6wxvf-3"
})({
  "position": "relative",
  "width": "100%"
});
const StyledSlider = _styled.input.withConfig({
  displayName: "Slider__StyledSlider",
  componentId: "sc-1a6wxvf-4"
})(({
  $sliderPercentage
}) => [
// Base styles
{
  "position": "relative",
  "zIndex": "10",
  "height": "0.25rem",
  "width": "100%",
  "appearance": "none",
  "borderRadius": "0.125rem",
  "backgroundColor": "transparent",
  "outline": "2px solid transparent",
  "outlineOffset": "2px"
},
// Slider track styles
{
  "&::-webkit-slider-runnable-track": {
    "width": "100%",
    "borderRadius": "9999px"
  }
},
// Slider thumb styles for chrome
{
  "&::-webkit-slider-thumb": {
    "height": "10px",
    "width": "10px",
    "cursor": "grab",
    "appearance": "none",
    "borderRadius": "9999px",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgb(9 90 124 / var(--tw-ring-opacity))"
  }
},
// Slider thumb styles for firefox
{
  "&::-moz-range-thumb": {
    "height": "10px",
    "width": "10px",
    "cursor": "grab",
    "appearance": "none",
    "borderRadius": "9999px",
    "borderStyle": "none",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgb(9 90 124 / var(--tw-ring-opacity))"
  }
}, {
  backgroundImage: `linear-gradient(90deg, ${NAVY_BLUE} 0%, ${NAVY_BLUE} ${$sliderPercentage}, ${GRAY_ANON} ${$sliderPercentage}, ${GRAY_ANON} 100%)`
}]);
const StyledTriangle = _styled.div.withConfig({
  displayName: "Slider__StyledTriangle",
  componentId: "sc-1a6wxvf-5"
})(({
  $percentage
}) => [{
  "position": "absolute",
  "top": "2px",
  "zIndex": "0",
  "height": "0px",
  "width": "0px",
  "borderLeftWidth": "5px",
  "borderRightWidth": "5px",
  "borderTopWidth": "8px",
  "borderColor": "transparent",
  "--tw-border-opacity": "1",
  "borderTopColor": "rgb(230 232 239 / var(--tw-border-opacity))"
}, `left: calc(${$percentage}% - 5px)` // centering the triangle
]);
export interface SliderProps {
  defaultValue: number;
  label: string;
  onChange: (value: number) => void;
  value: number;
  labelSize?: TypographySize;
  max?: number;
  min?: number;
  showTriangle?: boolean;
  step?: number;
}
export const Slider: React.FC<SliderProps> = ({
  defaultValue,
  label,
  value,
  labelSize = TypographySize.MEDIUM,
  max = 100,
  min = 0,
  step = 1,
  showTriangle = true,
  onChange
}) => {
  const [sliderPercentage, setSliderPercentage] = useState<string>('0%');
  const defaultPercentage = (defaultValue - min) / (max - min) * 100;
  useEffect(() => {
    const percent = (value - min) / (max - min) * 100;
    setSliderPercentage(`${percent}%`);
  }, [value, min, max]);
  const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = Number(e.target.value);
    onChange(numericValue);
    const percent = (numericValue - min) / (max - min) * 100;
    setSliderPercentage(`${percent}%`);
  };
  const onInputBadgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let numericValue = Number(e.target.value);
    if (Number.isNaN(numericValue)) return;
    if (numericValue > max) numericValue = max;
    if (numericValue < min) numericValue = min;
    onChange(numericValue);
    const percent = (numericValue - min) / (max - min) * 100;
    setSliderPercentage(`${percent}%`);
  };
  return <Wrapper>
      <LabelContainer htmlFor="minmax-range">
        <Typography color={Color.NAVY} size={labelSize}>
          {label}
        </Typography>
        <Badge bg="none" border="blueish">
          <InputBadge inputMode="numeric" max={max} min={min} onChange={onInputBadgeChange} pattern="\d*" type="text" value={value} />
        </Badge>
      </LabelContainer>
      <InputContainer>
        {showTriangle && <StyledTriangle $percentage={defaultPercentage} />}
        <StyledSlider $sliderPercentage={sliderPercentage} id="minmax-range" max={max} min={min} onChange={onSliderChange} step={step} type="range" value={value} />
      </InputContainer>
    </Wrapper>;
};
export default Slider;