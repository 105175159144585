import type { JwtApiAppRouter } from '@kindo/api';
import { createTRPCProxyClient, httpLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import type { inferRouterOutputs } from '@trpc/server';

// Old TRPC client
export const trpc = createTRPCProxyClient<JwtApiAppRouter>({
  links: [httpLink({ url: '/api/um/proxy/trpc' })]
});

// TRPC client integrated with Next, use this one moving forward
// https://trpc.io/docs/client/nextjs/setup
// https://trpc.io/docs/client/react
export const nextTrpc = createTRPCNext<JwtApiAppRouter>({
  config() {
    return {
      links: [
        httpLink({
          /**
           * If we want to use SSR, we'll need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           * */
          url: '/api/um/proxy/trpc',
          // You can pass any HTTP headers you wish here
          headers() {
            return {
              // authorization: getAuthCookie(),
            };
          }
        })
      ]
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   * */
  ssr: false
});

export type RouterOutput = inferRouterOutputs<JwtApiAppRouter>;

export type TRPC = typeof trpc;
