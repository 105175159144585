import { css as _css } from "styled-components";
import _styled from "styled-components";
import { BORDER_COLORS, Color } from '../constants';
export const TableContainer = _styled.div.withConfig({
  displayName: "Tablestyles__TableContainer",
  componentId: "sc-1md5wpg-0"
})({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "1.5rem"
});
export const TableRoot = _styled.table.withConfig({
  displayName: "Tablestyles__TableRoot",
  componentId: "sc-1md5wpg-1"
})({
  "width": "100%",
  "tableLayout": "auto"
});
export const TableHead = _styled.thead.withConfig({
  displayName: "Tablestyles__TableHead",
  componentId: "sc-1md5wpg-2"
})({
  "borderStyle": "none"
});
export const TableHeaderRow = _styled.tr.withConfig({
  displayName: "Tablestyles__TableHeaderRow",
  componentId: "sc-1md5wpg-3"
})(({
  $borderColor,
  $showBorder
}) => [BORDER_COLORS[$borderColor], $showBorder ? {
  "borderBottomWidth": "0.063rem"
} : {
  "borderStyle": "none"
}]);
export const TableHeader = _styled.th.withConfig({
  displayName: "Tablestyles__TableHeader",
  componentId: "sc-1md5wpg-4"
})(({
  $width
}) => [{
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "textAlign": "left"
}, typeof $width === 'number' ? _css({
  width: `${$width}px`
}) : $width]);
export const TableCell = _styled.td.withConfig({
  displayName: "Tablestyles__TableCell",
  componentId: "sc-1md5wpg-5"
})(({
  $width
}) => [{
  "height": "inherit",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
}, typeof $width === 'number' ? _css({
  width: `${$width}px`
}) : $width]);
export const TableCellTypographyWrapper = _styled.div.withConfig({
  displayName: "Tablestyles__TableCellTypographyWrapper",
  componentId: "sc-1md5wpg-6"
})({
  "display": "flex"
});
export const StyledTableRow = _styled.tr.withConfig({
  displayName: "Tablestyles__StyledTableRow",
  componentId: "sc-1md5wpg-7"
})(({
  $borderColor,
  $clickable,
  $disabled,
  $height,
  $selected
}) => [{
  "borderBottomWidth": "0.063rem"
}, BORDER_COLORS[$borderColor], $clickable && !$disabled && {
  "cursor": "pointer",
  "&:hover": {
    "backgroundColor": "#8CAEBA33"
  }
},
// Selectable
$disabled && {
  "cursor": "not-allowed"
}, $height, $selected && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(243 244 247 / var(--tw-bg-opacity))"
} // Selected
]);
export const TableCheckboxTd = _styled.td.withConfig({
  displayName: "Tablestyles__TableCheckboxTd",
  componentId: "sc-1md5wpg-8"
})({
  "paddingLeft": "0.5rem",
  "textAlign": "center"
});
export const TableCheckboxOuterContainer = _styled.div.withConfig({
  displayName: "Tablestyles__TableCheckboxOuterContainer",
  componentId: "sc-1md5wpg-9"
})(({
  $isSelected
}) => [{
  "display": "inline-block",
  "height": "1.5rem",
  "width": "1.5rem",
  "borderRadius": "0.25rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(140 174 186 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))",
  "textAlign": "center"
}, $isSelected && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(30 211 176 / var(--tw-bg-opacity))"
}]);
export const TableCheckboxInnerContainer = _styled.div.withConfig({
  displayName: "Tablestyles__TableCheckboxInnerContainer",
  componentId: "sc-1md5wpg-10"
})({
  "display": "block",
  "height": "1.5rem",
  "width": "1.5rem",
  "paddingLeft": "1px",
  "paddingTop": "1px",
  "textAlign": "center",
  "verticalAlign": "middle"
});
export const TableNoRowsTd = _styled.td.withConfig({
  displayName: "Tablestyles__TableNoRowsTd",
  componentId: "sc-1md5wpg-11"
})({
  "paddingTop": "2.25rem",
  "paddingBottom": "2.25rem",
  "paddingLeft": "1rem"
});