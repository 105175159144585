import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

const useDatadog = () => {
  const startDatadog = () => {
    datadogRum.init({
      applicationId: '8d5d13af-1e92-4472-9e46-87889a83758e',
      clientToken: 'pub3ecd31801de65d90aacee4c3c4d253dc',
      site: 'datadoghq.com',
      service: 'um-web',
      version:
        // the git commit sha from the Vercel build context or GitHub Actions CI context
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || process.env.GITHUB_SHA,
      env: process.env.NEXT_PUBLIC_VERCEL_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    });

    datadogRum.startSessionReplayRecording();
  };

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
      startDatadog();
    }
  }, []);
};

export default useDatadog;
