import { useContext } from 'react';

import { SlackMessageSharingContext } from './SlackMessageSharingProvider';

const useSlackMessageSharing = () => {
  const context = useContext(SlackMessageSharingContext);
  if (!context) {
    throw new Error(
      'useSlackMessageSharing must be used within a SlackMessageSharingProvider'
    );
  }
  return context;
};

export default useSlackMessageSharing;
